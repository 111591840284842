import { useContext, useEffect } from "react";
import { formatDateTime } from "../../../utils/date";
import statuses, { baseStatuses } from "../../../utils/statuses";
import Status from "../../Status";
import Table from "../Table";
import AppContext from "../../../contexts/AppContext";
import useInventory from "../../../hooks/api/useInventory";
import usePagination from "../../../hooks/usePagination";
import ID from "../ID";
import WithLink from "../WithLink";
import WithImage from "../WithImage";
import { path } from "../../../utils/path";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "ID",
    target: "_id",
    render: (id) => (
      <ID
        link={path.inventory.requests.view.url + `?id=${id}`}
        id={id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="View Details"
      />
    ),
  },
  {
    title: "From",
    target: "origin",
    render: (v) => <WithLink text={v.name} />,
  },
  {
    title: "Requested By",
    target: "requestedBy",
    render: (values) => (
      <WithImage
        text={`${values.firstName} ${values.lastName}`}
        imageUrl={values.imageUrl}
      />
    ),
  },
  {
    title: "Supply Status",
    target: "status",
    render: (v) => {
      return <Status text={v} light className={baseStatuses[v]} />;
    },
  },
  {
    title: "Time Sent",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  {
    title: "Last updated",
    target: "updatedAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function MaterialRequests({ title }) {
  const { outlet } = useContext(AppContext);
  const { getMaterialRequests, products, loading, pagination } = useInventory();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });

  useEffect(() => {
    if (outlet) getMaterialRequests("", pageNumber, limit, outlet);
  }, [pageNumber, limit, outlet]);

  return (
    <Table
      loading={loading}
      totalCount={pagination.total}
      limit={limit}
      onPaginate={setPageNumber}
      data={products}
      head={tableHead}
      title={title}
    />
  );
}

export default MaterialRequests;
