import { Route, Routes } from "react-router-dom";
import Card from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";
import Tab from "../../../components/Tab";
import { path } from "../../../utils/path";
import Roles from "./Roles";
import Departments from "./Departments";
import Staffs from "./Staffs";
import Users from "./Users";

const tableTabs = [
  // {
  //   title: "Users",
  //   to: path.accounts.users.url,
  // },
  {
    title: "Subscribers",
    to: path.accounts.subscribers.url,
  },
  {
    title: "Creators",
    to: path.accounts.creators.url,
  },
  {
    title: "Riders",
    to: path.accounts.riders.url,
  },
  {
    title: "Staffs",
    to: path.accounts.staffs.url,
  },
  {
    title: "Departments",
    to: path.accounts.departments.url,
  },
  {
    title: "Roles & Permissions",
    to: path.accounts.roles.url,
  },
];

function Dashboard(props) {
  return (
    <div>
      <div className="cards d-flex">
        <Card title={30} className="success-light" subTitle="Active Users" />
        <Card title={25} className="warn-light" subTitle="Creators" />
        <Card title={48} className="info-light" subTitle="Staffs" />
        <Card title={45} subTitle="Departments" />
      </div>
      <br />
      <div className="d-flex tab-column gap-10 justify-between">
        <div className="full-flex">
          <Revenue random={false} title="Revenue" />
        </div>
        <div
          className="d-flex gap-10 flex-column justify-between"
          style={{
            flex: 0.4,
          }}
        >
          <Card
            title={254}
            style={{ height: "50%" }}
            subTitle="Monthly Sign Up"
          />
          <Card
            title={124246}
            style={{ height: "50%" }}
            subTitle="Total Users"
          />
        </div>
      </div>
      <br />
      <div>
        <Tab autoSelect canNavigate tabs={tableTabs} />
        <Routes>
          <Route path={path.accounts.users.root} element={<Users />} />
          <Route
            path={path.accounts.creators.root}
            element={<Users role="creator" />}
          />
          <Route
            path={path.accounts.riders.root}
            element={<Users role="rider" />}
          />
          <Route
            path={path.accounts.subscribers.root}
            element={<Users role="customer" />}
          />
          <Route path={path.accounts.staffs.root} element={<Staffs />} />
          <Route
            path={path.accounts.departments.root}
            element={<Departments />}
          />
          <Route path={path.accounts.roles.root} element={<Roles />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
