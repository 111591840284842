import { useEffect, useState } from "react";
import Status from "../../../components/Status";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import useStaffs from "../../../hooks/api/useStaffs";
import statuses from "../../../utils/statuses";
import usePagination from "../../../hooks/usePagination";
import useModal from "../../../hooks/useModal";
import StaffProfile from "../../../components/account/StaffProfile";
import PlusIcon from "../../../assets/icons/PlusIcon";

const accountStatuses = {
  active: statuses.active,
  suspended: statuses.danger,
  restricted: statuses.warn,
  fired: statuses.placed,
};

const tableHead = [
  {
    target: "#",
    title: "#",
  },
  {
    title: "Name",
    target: ["account.firstName", "account.lastName", "account.profileImage"],
    render: (values) => (
      <WithImage text={`${values[0]} ${values[1]}`} imageUrl={values[2]} />
    ),
  },
  {
    title: "Position",
    target: "position",
  },
  {
    title: "Department",
    target: "department.name",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Outlet",
    target: "outlet.name",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Phone Number",
    target: "account.phoneNumber",
    render: (v) => <WithLink lin text={v} />,
  },
  {
    title: "Email",
    target: "account.email",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Active",
    target: "status",
    render: (v) => {
      return <Status light text={v} className={accountStatuses[v]} />;
    },
  },
];

function Staffs(props) {
  const { getAllStaffs, allStaffs, createStaff, updateStaff, loading } =
    useStaffs();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const { isOpened, openModal, closeModal } = useModal();
  const refresh = () => getAllStaffs(pageNumber, limit);

  const handleSave = (data) => {
    const cb = () => {
      refresh();
      closeModal();
    };
    if (selectedStaff) {
      updateStaff(selectedStaff._id, data, cb);
    } else createStaff(data, cb);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [pageNumber, limit]);
  return (
    <div>
      <StaffProfile
        selectedStaff={selectedStaff}
        loading={loading}
        handleSubmit={handleSave}
        isOpened={isOpened}
        closeModal={closeModal}
      />
      <Table
        btn1={{
          icon: <PlusIcon />,
          title: "Add New Staff",
          onClick: () => {
            setSelectedStaff(null);
            openModal();
          },
        }}
        data={allStaffs}
        loading={loading}
        onPaginate={setPageNumber}
        head={[
          ...tableHead,
          {
            title: "Actions",
            target: "*",
            render: (v) => (
              <View
                onClick={() => {
                  setSelectedStaff(v);
                  openModal();
                }}
              />
            ),
          },
        ]}
        limit={limit}
        totalCount={34}
        title="iCart Staffs"
      />
    </div>
  );
}

export default Staffs;
