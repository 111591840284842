import { formatDateTime } from "../../../utils/date";
import statuses from "../../../utils/statuses";
import Status from "../../Status";
import Table from "../Table";
import View from "../View";

const tableHead = [
  {
    title: "ID",
    target: "id",
  },
  {
    title: "Requested By",
    target: "by",
  },
  {
    title: "Requested From",
    target: "from",
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        className={`${v ? statuses.warn : statuses.success}-text ${
          v ? statuses.warn : statuses.success
        }-light`}
        text={!v ? "delivered" : "pending"}
      />
    ),
  },
  {
    title: "Time Requested",
    target: "date",
    render: (v) =>
      formatDateTime(
        (() => {
          const d = new Date();
          d.setMinutes(d.getMinutes() - 40);
          return d;
        })()
      ).dateTime,
  },
  {
    title: "Time Sent",
    target: "date",
    render: (v) =>
      formatDateTime(
        (() => {
          const d = new Date();
          d.setMinutes(d.getMinutes() - 30);
          return d;
        })()
      ).dateTime,
  },
  {
    title: "Last updated",
    target: "date",
    render: (v) => formatDateTime(new Date()).dateTime,
  },
  {
    title: "Action",
    target: "id",
    render: (v) => <View />,
  },
];

function PrepRequests({ title }) {
  return (
    <Table
      data={[
        {
          id: "345678",
          by: "Elvis Chijindu Eteama",
          from: "iC10001ABJNG",
          status: false,
          date: new Date(),
        },
        {
          id: "345679",
          by: "Future Itoro Akpan",
          from: "iC10001ABJNG",
          status: true,
          date: new Date(),
        },
      ]}
      head={tableHead}
      title={title}
    />
  );
}

export default PrepRequests;
