import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import { formatDateTime } from "../../../utils/date";
import { getRandomImage } from "../../../utils/string";
import Table from "../Table";
import View from "../View";
import WithImage from "../WithImage";
import WithLink from "../WithLink";
import { path } from "../../../utils/path";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";

const tableHead = [
  {
    titile: "#",
    target: "#",
  },
  {
    title: "Customer",
    target: "customer",
    render: (name) => <WithImage text={name} imageUrl={getRandomImage()} />,
  },
  {
    title: "Operator",
    target: "operator",
    render: (name) => <WithImage text={name} imageUrl={getRandomImage()} />,
  },
  {
    title: "iCart",
    target: "icart",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Menu",
    target: "menu",
    render: (v) => (
      <WithImage>
        <WithLink text={v} />
      </WithImage>
    ),
  },
  {
    title: "Quantity",
    target: "quantity",
  },
];

function RecentSalesOnICart({ width, hideFooter }) {
  const navigate = useNavigate();
  const navigateNewSale = () => navigate(path.iCartOperating.recordSale.url);
  const { formatCurrency } = useContext(AppContext);
  return (
    <Table
      head={[
        ...tableHead,
        {
          title: "Total",
          target: "total",
          render: formatCurrency,
        },
        {
          title: "Date",
          target: "date",
          render: (v) => formatDateTime(new Date()).dateTime,
        },
        {
          title: "Action",
          target: "id",
          render: (v) => <View />,
        },
      ]}
      data={[
        {
          customer: "Ismail Dalhatu",
          operator: "Usman Dalhatu",
          icart: "iC10001ABJNG",
          menu: "Plain Masa",
          quantity: 1,
          total: "2450",
        },
        {
          customer: "Ismail Dalhatu",
          menu: "Plain Masa",
          operator: "Usman Dalhatu",
          icart: "iC10001ABJNG",
          quantity: 2,
          total: "2450",
        },
      ]}
      btn1={{
        width: 200,
        rSvg: <PlusIcon />,
        onClick: navigateNewSale,
      }}
      btn2={{
        outline: true,
        width: 200,
        rSvg: <ViewIcon color="var(--main-yellow)" />,
      }}
      title="Recent Sales"
      hideFooter={hideFooter}
      maxWidth={width}
    />
  );
}

export default RecentSalesOnICart;
