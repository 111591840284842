import { useContext, useState } from "react";
import Status from "../../../components/Status";
import WithLink from "../../../components/table/WithLink";
import InfoText from "../../../components/TextInfo";
import { formatDateTime } from "../../../utils/date";
import AppContext from "../../../contexts/AppContext";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import Button from "../../../components/Button";
import { useLocation } from "react-router-dom";
import statuses from "../../../utils/statuses";
import useOperations from "../../../hooks/api/useOperations";

const tablehead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Menu",
    target: "item.name",
    render: (v) => <WithImage text={v} />,
  },
  {
    title: "Variety",
    target: "variety",
  },
  {
    title: "Drink",
    target: "drink",
  },
  {
    title: "Quantiy",
    target: "quantity",
  },
];

function ViewOrder(props) {
  const { acceptOrder, completeOrder, loading } = useOperations();
  const { formatCurrency } = useContext(AppContext);
  const { state } = useLocation();
  const [status, setStatus] = useState(state.status);

  return (
    <div>
      {/* <p>{JSON.stringify(state)}</p> */}
      <div className="d-flex justify-between gap-10 flex-wrap material-info">
        <InfoText
          className="full-flex"
          title="Customer Name:"
          text={state.customerInformation.name}
        />
        <InfoText className="full-flex" title="Customer Phone:">
          <WithLink text={state.customerInformation.phoneNumber} />
        </InfoText>
        <InfoText
          className="full-flex"
          title="Date:"
          text={formatDateTime(state.createdAt).dateTime}
        />
        <InfoText
          className="full-flex"
          title="Total:"
          text={formatCurrency(state.totalPrice)}
        />
        <InfoText className="full-flex" title="Balance:">
          <Status className="danger" light text={formatCurrency(0)} />
        </InfoText>
        <InfoText className="full-flex" title="Status:">
          <Status light className={statuses[status]} text={status} />
        </InfoText>
      </div>
      <div className="d-flex align-end gap-10 justify-between">
        <InfoText className="full-flex" title="Delivery:">
          <WithLink text={state.deliveryTo.address} />
        </InfoText>
        <InfoText className="full-flex" title="Rider:">
          <WithLink
            link={
              state.rider && typeof state.rider !== "string"
                ? `tel:${state.rider?.phoneNumber}`
                : "#"
            }
            text={
              state.rider && typeof state.rider !== "string"
                ? `${state.rider?.firstName} ${state.rider?.lastName} (${state.rider?.phoneNumber})`
                : "-"
            }
          />
        </InfoText>
        <InfoText className="full-flex" title="Payment Status:">
          <Status
            light
            text={state.paymentCompleted ? "paid" : "pending"}
            className={statuses[state.paymentCompleted ? "paid" : "pending"]}
          />
        </InfoText>
        <div className="d-flex gap-10">
          <Button
            loading={loading}
            onClick={() =>
              acceptOrder(state._id, () => setStatus("processing"))
            }
            borderRadius={4}
            disabled={status !== "pending"}
            height={35}
            width={160}
            fontSize="fs-12"
            title="Accept Order"
          />
          <Button
            loading={loading}
            onClick={() =>
              completeOrder(state._id, () => setStatus("completed"))
            }
            disabled={status !== "processing"}
            borderRadius={4}
            height={35}
            width={160}
            fontSize="fs-12"
            bg="bg-success"
            title="Complete Order"
          />
        </div>
      </div>
      <br />
      <div className="d-flex justify-between gap-10 flex-wrap material-info">
        <InfoText
          className="full-flex"
          title="Should be accepted by:"
          text={formatDateTime(state.estimatedQueueTime).dateTime}
        />
        <InfoText
          className="full-flex"
          title="Accepted on:"
          text={
            state.acceptedAt ? formatDateTime(state.acceptedAt).dateTime : "-"
          }
        />
        <InfoText
          className="full-flex"
          title="Should be completed by:"
          text={formatDateTime(state.estimatedProcessingTime).dateTime}
        />
        <InfoText
          className="full-flex"
          title="Completed on:"
          text={
            state.completedAt ? formatDateTime(state.completedAt).dateTime : "-"
          }
        />
        <InfoText
          className="full-flex"
          title="Should be delivered by:"
          text={formatDateTime(state.estimatedDeliveryTime).dateTime}
        />
        <InfoText
          className="full-flex"
          title="Dispatched At:"
          text={
            state.dispatchedAt
              ? formatDateTime(state.dispatchedAt).dateTime
              : "-"
          }
        />
        <InfoText
          className="full-flex"
          title="Delivered on:"
          text={
            state.deliveredAt ? formatDateTime(state.deliveredAt).dateTime : "-"
          }
        />
      </div>
      <br />
      <Table
        title="Ordered Items"
        hideFooter
        data={state.items}
        head={[
          ...tablehead,
          {
            title: "Total",
            target: ["priceStamp", "quantity"],
            render: (v) => formatCurrency(v[0] * v[1]),
          },
        ]}
      />
    </div>
  );
}

export default ViewOrder;
