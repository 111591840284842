import { useContext, useEffect } from "react";
import useSupply from "../../../hooks/api/useSupply";
import { formatDateTime } from "../../../utils/date";
import { baseStatuses } from "../../../utils/statuses";
import Status from "../../Status";
import Table from "../Table";
import ID from "../ID";
import { path } from "../../../utils/path";
import WithLink from "../WithLink";
import usePagination from "../../../hooks/usePagination";
import AuthContext from "../../../contexts/AuthContext";

const tableHead = [
  {
    title: "ID",
    target: "*",
    render: (
      value,
      id = value._id.split("").reverse().join("").slice(0, 6).toUpperCase()
    ) => (
      <ID
        link={path.suppliers.requests.view.url + `?id=${value._id}`}
        id={id}
        label="View Request"
        state={value}
      />
    ),
  },
  {
    title: "Requested From",
    target: "origin.name",
    render: (t) => <WithLink text={t} />,
  },
  // {
  //   title: "By",
  //   target: "requestedBy",
  //   render: (v) => (
  //     <WithImage
  //       imageUrl={v.profileImage}
  //       text={`${v.firstName} ${v.lastName}`}
  //     />
  //   ),
  // },
  {
    title: "Status",
    target: "status",
    render: (v) => {
      return <Status text={v} light className={baseStatuses[v]} />;
    },
  },
  {
    title: "Note",
    target: "note",
  },
  {
    title: "Time Sent",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  {
    title: "Last updated",
    target: "updatedAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function SupplyRequests({ title }) {
  const { user } = useContext(AuthContext);
  const { getSupplyRequests, supplyrequests, totalCount, loading } =
    useSupply();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 2,
  });

  useEffect(() => {
    if (user.supplier) getSupplyRequests(pageNumber, limit);
    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <Table
      onPaginate={setPageNumber}
      limit={limit}
      totalCount={totalCount}
      data={supplyrequests}
      head={tableHead}
      loading={loading}
      title={title}
    />
  );
}

export default SupplyRequests;
