import { useNavigate } from "react-router-dom";
import Link from "../../assets/icons/Link";

function WithLink({ text, link, className = "" }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!link) return;
    if (link.includes(":")) return window.open(link, "_blank");
    navigate(link);
  };
  return (
    <button
      onClick={handleClick}
      className="d-flex align-center item-link view-action"
    >
      <span className={`d-block fs-14 ${className}`}>{text}</span>
      <Link />
    </button>
  );
}

export default WithLink;
