import Card from "../../../components/Card";
import Map from "../../../components/map/Map";
import Tab from "../../../components/Tab";
import { Route, Routes } from "react-router-dom";
import { path } from "../../../utils/path";
import Concepts from "./Concepts";
import Brands from "./Brands";

const tableTabs = [
  {
    title: "Approved Concepts",
    to: path.conceptAndCreators.url + `?status=approved`,
  },
  {
    title: "Submitted Concepts",
    to: path.conceptAndCreators.url + `?status=pending`,
    badge: "9+",
  },
  {
    title: "Added Concepts",
    to: path.conceptAndCreators.url + `?status=created`,
  },
  {
    title: "Approved Brands",
    to: path.conceptAndCreators.brands.url + `?approved=true`,
  },
  {
    title: "New Brands",
    to: path.conceptAndCreators.brands.url + `?approved=false`,
    badge: "9+",
  },
  // {
  //   title: "Creators",
  //   to: path.conceptAndCreators.creators.url,
  //   badge: "6+",
  // },
];

function Dashboard(props) {
  return (
    <div>
      <div className="cards d-flex">
        <Card className="success-light" title={0} subTitle="Active Concepts" />
        <Card className="info-light" title={0} subTitle="New Concepts" />
        <Card className="info-light" title={0} subTitle="New Brands" />
        <Card className="success-light" title={0} subTitle="Active Brands" />
        <Card
          className="success-light"
          title={0}
          subTitle="Approved Creators"
        />
      </div>
      <br />
      <div className="d-flex tab-column gap-10 flex-wrap">
        <div className="map-container d-flex">
          <Map />
        </div>
        <div style={{ flex: 0.3 }}>
          <Card subTitle="Total Concepts" title={0} />
          <br />
          <Card subTitle="Total Brands" title={0} />
          <br />
          <Card subTitle="Total Creators" title={0} />
        </div>
      </div>
      <br />
      <div>
        <Tab autoSelect canNavigate tabs={tableTabs} />
        <Routes>
          <Route path={path.root} element={<Concepts />} />
          <Route
            path={path.conceptAndCreators.brands.root}
            element={<Brands />}
          />
          {/* <Route
            path={path.conceptAndCreators.creators.root}
            element={<Creators />}
          /> */}
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
