import { toast } from "react-toastify";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";

function useConfig(props) {
  // eslint-disable-next-line
  const { loading, post, get, put, setLoading } = useAPI();
  const [allCountries, setAllCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [units, setUnits] = useState([]);

  const getCountries = async () => {
    const { data, error } = await get(urls.configs.countries);
    if (error) return;
    setCountries(data.countries);
  };

  const getUnits = async () => {
    const { data, error } = await get(urls.configs.units);
    if (error) return;
    setUnits(data.measurements);
  };

  const getCities = async (cb) => {
    const { data, error } = await get(urls.configs.cities);
    if (error) return;
    setCities(data.cities);
    if (typeof cb === "function") cb(data.cities);
  };

  const addCountry = async (fdata, cb) => {
    const { error, message } = await post(urls.configs.countries, fdata);
    if (error) return;
    toast.info(message);
    getCountries();
    if (typeof cb === "function") cb();
  };

  const updateCountry = async (id, fdata, cb) => {
    const { error, message } = await put(
      urls.configs.countries + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    getCountries();
    if (typeof cb === "function") cb();
  };

  const addCity = async (fdata, cb) => {
    const { error, message } = await post(urls.configs.cities, fdata);
    if (error) return;
    toast.info(message);
    getCities();
    if (typeof cb === "function") cb();
  };

  const updateCity = async (id, fdata, cb) => {
    const { error, message } = await put(urls.configs.cities + `/${id}`, fdata);
    if (error) return;
    toast.info(message);
    getCities();
    if (typeof cb === "function") cb();
  };

  const getAllCountries = async () => {
    // Fetch countries with currency codes using RestCountries API
    setLoading(true);
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countriesWithCurrency = data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
          currency: (() => {
            const keys = country?.currencies
              ? Object.keys(country?.currencies)
              : [];
            if (!keys.length) return null;
            return {
              symbol: country.currencies[keys[0]].symbol,
              code: keys[0],
            };
          })(),
        }));
        setAllCountries(countriesWithCurrency);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllCountries2 = async () => {
    setLoading(true);
    fetch("https://api.countrystatecity.in/v1/countries", {
      headers: {
        "X-CSCAPI-KEY": process.env.CREACT_APP_CSC_API_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const getStates = async (country = "NG") => {
    setLoading(true);
    fetch(`https://api.countrystatecity.in/v1/countries/${country}/states`, {
      headers: {
        "X-CSCAPI-KEY": process.env.REACT_APP_CSC_API_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data) setStates(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    getAllCountries2,
    getAllCountries,
    updateCountry,
    allCountries,
    getCountries,
    updateCity,
    addCountry,
    getStates,
    getCities,
    countries,
    getUnits,
    addCity,
    loading,
    states,
    cities,
    units,
  };
}
export default useConfig;
