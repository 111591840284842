import {
  excludeFromObject,
  objectToFormData,
  selectFromObject,
} from "../../utils/array";
import PrepInstruction from "./PrepInstruction";
import AddMachines from "./AddMachines";
import FilePicker from "../FilePicker";
import { useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import Recipe from "./Recipe";
import urls from "../../api/urls";
import Collapsable from "../Collapsable";

const Item = ({
  remove,
  id,
  name,
  bestEatingTime,
  description,
  handleChange,
  machineList,
  ingredientsList,
  prepMachineries,
  iCartMachineries,
  recipe,
  prepInstructions,
  prepKioskInstructions,
  kitchenPrepStorage,
  icartPrepStorage,
  prepTiming,
  ticketTiming,
  extras,
  extrasList = [],
  banner,
  images,
  output = 1,
  items = [],
}) => {
  const allMenuExtras = extrasList
    .filter((_) => _.type === "extra")
    .map((_) => ({ label: _.name, value: _._id }));
  const allMixtureExtras = extrasList
    .filter((_) => _.type === "mixture")
    .map((_) => ({ label: _.name, value: _._id, unit: _.unitOfMeasure }));
  return (
    <Collapsable title={name}>
      <div className="submit-menu-card">
        <div className="inputs">
          <Input
            value={name}
            placeholder="Name"
            onChange={(v) => handleChange(id, "name", v)}
          />
          <Input
            select
            value={bestEatingTime}
            options={[
              { label: "Breakfast", value: "breakfast" },
              { label: "Lunch", value: "lunch" },
              { label: "Dinner", value: "dinner" },
            ]}
            onChange={(v) => handleChange(id, "bestEatingTime", v)}
            placeholder="Best Eating Time"
          />
        </div>
        <br />
        <Input
          textarea
          value={description}
          placeholder="Description"
          onChange={(v) => handleChange(id, "description", v)}
        />
        <br />
        <AddMachines
          machineries={prepMachineries}
          handleChange={(m) => handleChange(id, "prepMachineries", m)}
          list={machineList}
          label="Prep Machineries"
        />
        <br />
        <Recipe
          menu={items}
          extras={allMixtureExtras}
          handleChange={(m) => handleChange(id, "recipe", m)}
          initialRecipe={recipe}
          list={ingredientsList}
        />
        <br />
        <PrepInstruction
          handleChange={(m) => handleChange(id, "prepInstructions", m)}
          initialInstructions={prepInstructions}
          label="Prep Instructions"
        />
        <br />
        <div className="inputs">
          <Input
            value={kitchenPrepStorage}
            placeholder="Prep Storage"
            onChange={(v) => handleChange(id, "kitchenPrepStorage", v)}
          />
          <Input
            value={prepTiming}
            onChange={(v) => handleChange(id, "prepTiming", v)}
            placeholder="Prep Timing"
          />
        </div>
        <br />
        <AddMachines
          machineries={iCartMachineries}
          handleChange={(m) => handleChange(id, "iCartMachineries", m)}
          list={machineList}
          label="iCart Machineries"
        />
        <br />
        <PrepInstruction
          handleChange={(m) => handleChange(id, "prepKioskInstructions", m)}
          initialInstructions={prepKioskInstructions}
          label="Prep Instructions (on iCart)"
        />
        {allMenuExtras.length ? (
          <>
            <br />
            <PrepInstruction
              select
              options={allMenuExtras}
              placeholder="Select Extra"
              handleChange={(m) => handleChange(id, "extras", m)}
              initialInstructions={extras}
              label="Menu Extras"
            />
          </>
        ) : null}
        <br />
        <div className="inputs">
          <Input
            value={icartPrepStorage}
            placeholder="Prep Storage"
            onChange={(v) => handleChange(id, "icartPrepStorage", v)}
          />
          <Input
            value={ticketTiming}
            onChange={(v) => handleChange(id, "ticketTiming", v)}
            placeholder="Ticket Timing"
          />
        </div>
        <br />
        <br />
        <div className="d-flex flex-wrap justify-between gap-10">
          <FilePicker
            initial={banner}
            onSelect={(v) => handleChange(id, "banner", v)}
            placeholder="Select Menu Banner"
            width="48%"
          />
          <FilePicker
            multiple
            initial={images?.length && images[0]}
            onSelect={(v) => handleChange(id, "images", v)}
            placeholder="Upload Menu Images"
            width="48%"
          />
        </div>
        <br />
        <Input
          value={output}
          onChange={(v) => handleChange(id, "output", v)}
          type="number"
          placeholder="Recipe Output"
        />
        <br />
        <Button
          outline
          style={{ marginLeft: "auto" }}
          onClick={remove}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </Collapsable>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Menu"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function Menu({ id, concept, loading, onFinished, addDataToConcept }) {
  const [menu, setMenu] = useState(
    concept?.menu
      ? concept.menu.map((_, idx) => ({
          ...selectFromObject(
            [
              "_id",
              "name",
              "bestEatingTime",
              "description",
              "kitchenPrepStorage",
              "icartPrepStorage",
              "prepTiming",
              "ticketTiming",
              "banner",
              "images",
              "output",
            ],
            _
          ),
          id: idx + 1,
          prepInstructions: _.prepInstructions.map((__, xx) => ({
            idx: xx + 1,
            instruction: __,
          })),
          prepKioskInstructions: _.prepKioskInstructions.map((__, xx) => ({
            idx: xx + 1,
            instruction: __,
          })),
          prepMachineries: _.machineries
            .filter((__) => !__.inKiosk)
            .map((__, xx) => ({
              ...excludeFromObject(["_id"], __),
              id: xx + 1,
              conceptMachine: __.conceptMachine?._id,
            })),
          iCartMachineries: _.machineries
            .filter((__) => __.inKiosk)
            .map((__, xx) => ({
              ...excludeFromObject(["_id"], __),
              id: xx + 1,
              conceptMachine: __.conceptMachine?._id,
            })),

          recipe: _.recipe.map((__, xx) => ({
            ...excludeFromObject(
              ["_id", "conceptIngredient", "conceptExtra"],
              __
            ),
            id: xx + 1,
            conceptIngredient: __.conceptIngredient?._id,
            conceptExtra: __.conceptExtra?._id,
          })),
        }))
      : []
  );

  const handleSave = async () => {
    const converseMenu = menu.map((_) => ({
      ...excludeFromObject(
        ["id", "_id", "prepMachineries", "iCartMachineries"],
        _
      ),
      id: _._id || "",
      recipe: _.recipe.map((__) => ({
        ...excludeFromObject(["id", "ingredient"], __),
        conceptExtra: __.conceptExtra || null,
        conceptIngredient: __.conceptIngredient || null,
      })),
      extras: _.extras?.length ? _.extras.map((__) => __.instruction) : [],
      prepInstructions: _.prepInstructions.map((__) => __.instruction),
      prepKioskInstructions: _.prepKioskInstructions.map(
        (__) => __.instruction
      ),
      machineries: [
        ..._.prepMachineries.map((__) => ({
          ...excludeFromObject(["id"], __),
          inKiosk: false,
        })),
        ..._.iCartMachineries.map((__) => ({
          ...excludeFromObject(["id"], __),
          inKiosk: true,
        })),
      ],
    }));
    const submitted = [];
    for (let i = 0; i < converseMenu.length; i++) {
      let formData = new FormData();
      if (converseMenu[i].banner)
        formData.append("banner", converseMenu[i].banner);
      if (converseMenu[i].images?.length)
        Object.values(converseMenu[i].images).forEach((file) =>
          formData.append("images", file)
        );
      formData.append("extras", JSON.stringify(converseMenu[i].extras));
      formData.append(
        "prepInstructions",
        JSON.stringify(converseMenu[i].prepInstructions)
      );
      formData.append(
        "prepKioskInstructions",
        JSON.stringify(converseMenu[i].prepKioskInstructions)
      );
      formData.append(
        "machineries",
        JSON.stringify(converseMenu[i].machineries)
      );
      formData.append("recipe", JSON.stringify(converseMenu[i].recipe));
      formData = objectToFormData(
        excludeFromObject(
          [
            "banner",
            "images",
            "extras",
            "prepInstructions",
            "prepKioskInstructions",
            "machineries",
            "recipe",
          ],
          converseMenu[i]
        ),
        formData
      );
      const response = await addDataToConcept(
        id,
        formData,
        urls.business.concepts.addMenu
      );
      submitted.push(response);
    }
    onFinished(submitted);
  };

  return (
    <div>
      <Group
        items={menu}
        setItems={setMenu}
        Element={Item}
        AddElement={AddButton}
        extrasList={concept?.extras}
        machineList={concept?.machineries}
        ingredientsList={concept?.ingredients}
      />
      <br />
      <Button loading={loading} onClick={handleSave} title="Save & Continue" />
    </div>
  );
}

export default Menu;
