import Dashboard from "../pages/app/kitchens/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";

function Kitchens(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
    </Routes>
  );
}

export default Kitchens;
