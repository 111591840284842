import Dashboard from "../pages/app/management/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import ViewICart from "../pages/app/management/ViewICart";
import ManagementContext from "../contexts/ManagementContext";

function Management(props) {
  return (
    <ManagementContext.Provider value={{}}>
      <Routes>
        <Route
          path={path.management.icarts.view.path}
          element={<ViewICart />}
        />
        <Route path={path.all} element={<Dashboard />} />
      </Routes>
    </ManagementContext.Provider>
  );
}

export default Management;
