import { useState } from "react";
import Input from "../Input";
import Recipe from "./Recipe";
import Group from "../FlexGroup";
import Button from "../Button";
import { excludeFromObject, selectFromObject } from "../../utils/array";
import urls from "../../api/urls";
import Collapsable from "../Collapsable";

const Extra = ({
  id,
  type,
  name,
  output,
  description,
  recipe = [],
  handleChange,
  unitOfMeasure,
  remove,
  ingredientsList = [],
  items,
}) => {
  return (
    <Collapsable title={name}>
      <div className="inputs">
        <Input
          value={name}
          placeholder="Name"
          onChange={(v) => handleChange(id, "name", v)}
        />
        <Input
          value={description}
          onChange={(v) => handleChange(id, "description", v)}
          placeholder="Description"
        />
      </div>
      <br />
      <div className="inputs">
        <Input
          select
          options={[
            {
              label: "Menu Extra",
              value: "extra",
            },
            {
              label: "Ingredients Prep",
              value: "mixture",
            },
          ]}
          placeholder="Type"
          value={type}
          onChange={(v) => handleChange(id, "type", v)}
        />
        <Input
          select
          options={[
            { label: "Kilograms", value: "kg" },
            { label: "Grams", value: "grams" },
            { label: "Liter", value: "liter" },
            { label: "Unit", value: "unit" },
          ]}
          value={unitOfMeasure}
          onChange={(v) => handleChange(id, "unitOfMeasure", v)}
          placeholder="Unit Of Measure"
        />
      </div>
      <br />
      <Input
        value={output}
        onChange={(v) => handleChange(id, "output", v)}
        placeholder="Recipie Output"
      />
      <br />
      <Recipe
        menu={items}
        initialRecipe={recipe}
        list={ingredientsList}
        handleChange={(m) => handleChange(id, "recipe", m)}
      />
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove Extra"
        color="danger-text"
        fontSize="fs-12"
        width={100}
        height={30}
      />
      <br />
    </Collapsable>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function Extras({ concept, id, loading, onFinished, addDataToConcept }) {
  const [extras, setExtras] = useState(
    concept?.extras
      ? concept.extras.map((_, idx) => ({
          ...selectFromObject(
            ["_id", "type", "name", "description", "unitOfMeasure", "output"],
            _
          ),
          id: idx + 1,
          recipe: _.recipe.map((__, idx) => ({
            ...selectFromObject(
              ["conceptIngredient", "instruction", "quantity"],
              __
            ),
            id: idx + 1,
          })),
        }))
      : []
  );

  const handleSave = async () => {
    const response = await addDataToConcept(
      id,
      {
        extras: extras.map((_) => ({
          ...excludeFromObject(["id", "_id"], _),
          id: _._id,
          recipe: _.recipe.map((__) => ({ ...excludeFromObject(["id"], __) })),
        })),
      },
      urls.business.concepts.extras
    );
    if (response) onFinished(response);
  };

  return (
    <div>
      <Group
        Element={Extra}
        items={extras}
        setItems={setExtras}
        AddElement={AddButton}
        ingredientsList={concept?.ingredients}
      />
      <br />
      <Button loading={loading} onClick={handleSave} title="Save & Continue" />
    </div>
  );
}

export default Extras;
