import Subscriptions from "../../../components/table/withdata/Subscriptions";

function Active(props) {
  return (
    <div>
      <Subscriptions title="Active Subscriptions" />
    </div>
  );
}

export default Active;
