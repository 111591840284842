import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import statuses from "../../../utils/statuses";
import useConcepts from "../../../hooks/api/useConcepts";
import usePagination from "../../../hooks/usePagination";
import { useEffect, useState } from "react";
import { path } from "../../../utils/path";
import useQuery from "../../../hooks/useQuery";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Code",
    target: "_id",
    render: (id) => (
      <ID
        link={path.creators.edit.url + id}
        id={id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="Edit Concept"
      />
    ),
  },
  {
    title: "Name",
    target: ["logo", "name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Creator",
    target: [
      "brand.creator.firstName",
      "brand.creator.lastName",
      "brand.creator.profileImage",
    ],
    render: (values) => (
      <WithImage text={`${values[0]} ${values[1]}`} imageUrl={values[2]} />
    ),
  },
  {
    title: "Total Score",
    target: "totalScore",
  },
  {
    title: "Rating",
    target: "rating",
  },
  // {
  //   title: "Description",
  //   target: "description",
  // },
  {
    title: "Concept Status",
    target: "status",
    render: (v) => (
      <Status
        light
        className={
          v === "rejected"
            ? statuses.danger
            : v === "pending"
            ? statuses.warn
            : v === "active"
            ? statuses.active
            : statuses.info
        }
        text={v}
      />
    ),
  },
];

function Concepts(props) {
  const [selectedConcept, setSelectedCOncept] = useState(null);
  const { getAllConcepts, toggleConceptActivity, allConcepts, loading } =
    useConcepts();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const query = useQuery();

  useEffect(() => {
    getAllConcepts(pageNumber, limit, query.get("status"));
    // eslint-disable-next-line
  }, [pageNumber, limit, query.get("status"), selectedConcept]);

  return (
    <div>
      <Modal isVisible={selectedConcept}>
        <h2 className="fs-24 raleway f-700 text-primary">
          {selectedConcept?.active ? "Deactivate Concept" : "Activate Concept"}
        </h2>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, veniam,
          nisi assumenda sed nihil nemo iste sequi sapiente iure cum
          exercitationem provident dolores accusamus at quo itaque
          necessitatibus autem quae?
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            onClick={() => setSelectedCOncept(null)}
            width={100}
            title="Cancel"
            outline
          />
          <Button
            onClick={() =>
              toggleConceptActivity(selectedConcept?._id, () =>
                setSelectedCOncept(null)
              )
            }
            loading={loading}
            title="Continue"
          />
        </div>
      </Modal>
      <Table
        loading={loading}
        limit={limit}
        totalCount={203}
        data={allConcepts}
        head={[
          ...tableHead,
          {
            title: "Active",
            target: "*",
            render: (v) => (
              <Button
                onClick={() => setSelectedCOncept(v)}
                bg={v.active ? "danger" : "info"}
                height={35}
                borderRadius={3}
                bac
                fontSize="fs-12"
                title={v.active ? "Deactivate" : "Activate"}
              />
            ),
          },
          // {
          //   title: "Active",
          //   target: "active",
          //   render: (v) => (
          //     <Status
          //       light
          //       className={!v ? statuses.danger : statuses.info}
          //       text={v ? "active" : "not active"}
          //     />
          //   ),
          // },
          {
            title: "Actions",
            target: "id",
            render: (v) => <View />,
          },
        ]}
        title="Concepts"
        onPaginate={setPageNumber}
      />
    </div>
  );
}

export default Concepts;
