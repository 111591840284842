import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import { formatDateTime } from "../../../utils/date";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "iCart",
    target: "icart",
    render: (id) => <ID id={id} label="View Details" />,
  },
  {
    title: "Customer",
    target: "name",
    render: (name) => (
      <WithImage
        text={name}
        imageUrl={`https://randomuser.me/api/portraits/men/${(
          Math.random() * 10
        ).toFixed(0)}.jpg`}
      />
    ),
  },

  {
    title: "Primary Brand",
    target: "primaryBrand",
    render: (id) => <WithLink id={id} text={id} />,
  },
  {
    title: "Location",
    target: "location",
    render: (id) => <WithLink id={id} text={id} />,
  },
  {
    title: "City",
    target: "city",
  },
  {
    title: "Status",
    target: "id",
    render: (v) => (
      <Status text="deactivated" className="danger-light danger-text" />
    ),
  },
  {
    title: "Date Deactivated",
    target: "id",
    render: (v) => formatDateTime(new Date()).date,
  },
  {
    title: "Actions",
    target: "id",
    render: (v) => <View />,
  },
];

function Deactivated(props) {
  return (
    <div>
      <Table
        limit={10}
        totalCount={5}
        data={[]}
        head={tableHead}
        title="Deactivated Subscriptions"
      />
    </div>
  );
}

export default Deactivated;
