import { useEffect, useState } from "react";
import Status from "../../../components/Status";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import useAccounts from "../../../hooks/api/useAccounts";
import usePagination from "../../../hooks/usePagination";
import { accountStatuses, baseStatuses } from "../../../utils/statuses";
import { path } from "../../../utils/path";

const tableHead = [
  {
    target: "#",
    title: "#",
  },
  {
    title: "Name",
    target: ["firstName", "lastName", "profileImage"],
    render: (values) => (
      <WithImage text={`${values[0]} ${values[1]}`} imageUrl={values[2]} />
    ),
  },
  {
    title: "Phone Number",
    target: "phoneNumber",
    render: (v) => <WithLink lin text={v} />,
  },
  {
    title: "Email",
    target: "email",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Role",
    target: "role",
    render: (v) => <Status className={baseStatuses[v]} light text={v} />,
  },
  {
    title: "Status",
    target: "status",
    render: (v) => {
      return (
        <Status
          text={v}
          light
          className={v === "deleted" ? "disabled" : accountStatuses[v]}
        />
      );
    },
  },
  {
    title: "Actions",
    target: "_id",
    render: (v) => <View link={`${path.accounts.users.details.url}?id=${v}`} />,
  },
];

function Users({ role }) {
  const [search, setSearch] = useState("");
  const { getAllAccounts, searchAccoount, pagination, allAccounts, loading } =
    useAccounts();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });

  useEffect(() => {
    if (search) searchAccoount(search, limit, role);
    else getAllAccounts(pageNumber, limit, role);
    // eslint-disable-next-line
  }, [pageNumber, limit, search, role]);
  return (
    <div>
      <Table
        search={search}
        onSearch={setSearch}
        loading={loading}
        data={allAccounts}
        head={tableHead}
        limit={limit}
        totalCount={pagination.total}
        onPaginate={setPageNumber}
        title="Registered Users"
      />
    </div>
  );
}

export default Users;
