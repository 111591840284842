const statuses = Object.freeze({
  success: "success",
  true: "success",
  completed: "success",
  paid: "success",
  active: "success",
  warn: "warn",
  pending: "warn",
  inactive: "danger",
  canceled: "danger",
  disabled: "disabled",
  info: "info",
  danger: "danger",
  false: "danger",
  placed: "status-tint5",
  delivered: "status-tint5",
  successLight: "success-text success-light",
  warnlight: "warn-text warn-light",
  dangerLight: "danger-text danger-light",
  processing: "info",
});

export default statuses;

export const accountStatuses = {
  active: statuses.active,
  deleted: statuses.disabled,
  restricted: statuses.warn,
  deleted: statuses.placed,
};

export const baseStatuses = {
  completed: statuses.success,
  delivered: statuses.success,
  true: statuses.success,
  accepted: statuses.info,
  sent: statuses.info,
  moderate: statuses.warn,
  high: statuses.danger,
  false: statuses.danger,
  rejected: statuses.danger,
  pending: statuses.warn,
  requested: statuses.placed,
  placed: statuses.placed,
  processing: statuses.info,
  rider: statuses.placed,
  customer: statuses.success,
  creator: statuses.warn,
  staff: statuses.info,
  canceled: statuses.danger,
};
