import { useContext, useEffect, useState } from "react";
import FilePicker from "../../../components/FilePicker";
import { Form, FormInput, Submit } from "../../../components/form";
import MaterialsContext from "../../../contexts/MaterialsContext";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import useInventory from "../../../hooks/api/useInventory";
import Group from "../../../components/FlexGroup";
import Delete from "../../../assets/icons/Delete";
import { materialSchema } from "../../../utils/validators/inventory";
import { excludeFromObject } from "../../../utils/array";
import { useLocation, useNavigate } from "react-router-dom";
import Chv from "../../../assets/icons/Chv";
import useConfig from "../../../hooks/api/useConfig";

const SubMaterial = ({
  remove,
  id,
  item,
  quantity,
  handleChange,
  materials = [],
}) => {
  return (
    <>
      <div className="items-list d-flex justify-between">
        <div className="list-itm full-flex">
          <Input
            value={item}
            select
            placeholder="Select Item"
            onChange={(v) => handleChange(id, "item", v)}
            options={materials.map((_) => ({ value: _._id, label: _.name }))}
          />
        </div>
        <div className="list-itm full-flex">
          <Input
            value={quantity}
            type="number"
            placeholder="Quantity"
            onChange={(v) => handleChange(id, "quantity", v)}
          />
        </div>
        <Button
          width={50}
          onClick={remove}
          lSvg={<Delete />}
          className="bg-warn"
        />
      </div>
      <br />
    </>
  );
};

const Collasable = ({ children, title }) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = () => setIsOpened(!isOpened);

  return (
    <div>
      <div className="btn-collaps d-flex align-center" onClick={toggleIsOpened}>
        <span className="text-primary fs-12 f-500">{title}</span>
        <Chv
          style={{
            transform: `rotate(${isOpened ? "180" : "0"}deg)`,
            marginLeft: 10,
          }}
          color="var(--main-yellow)"
        />
      </div>
      <br />
      {isOpened && children}
    </div>
  );
};

function EditMaterial({ edit }) {
  const { state } = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [subMaterials, setSubMaterials] = useState(
    state?.mixture
      ? state?.mixture.map((_, idx) => ({
          item: _.item._id,
          quantity: _.quantity,
          id: idx + 1,
        }))
      : []
  );
  const { categories } = useContext(MaterialsContext);
  const { materials, getMaterials, addMaterial, updateMaterial, loading } =
    useInventory();
  const { getUnits, units, loading: loading2 } = useConfig();
  const navigate = useNavigate();

  const handleSave = (data) => {
    const body = {
      ...excludeFromObject(["height", "width", "voltage", "current"], data),
      tags: JSON.stringify(data.tags.split(",")),
      dimentionInft: {
        width: data.width,
        height: data.height,
      },
      electricInfo: {
        powerConsumption: {
          voltage: data.voltage,
          current: data.current,
        },
      },
      mixture: JSON.stringify(
        subMaterials.map((_) => excludeFromObject(["id"], _))
      ),
    };
    if (edit && state)
      updateMaterial(state._id, body, selectedImage, () => navigate(-1));
    else addMaterial(body, selectedImage, () => navigate(-1));
  };

  useEffect(() => {
    getMaterials();
    getUnits();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <h2 className="text-primary">
        {edit ? "Edit Item" : "New Raw Material"}
      </h2>
      <br />
      <Form
        onSubmit={handleSave}
        validationSchema={materialSchema}
        initialValues={{
          name: state?.name || "",
          category: state?.category?._id || "",
          unitOfMeasure: state?.unitOfMeasure || "unit",
          tags: state?.tags?.join(",") || "",
          width: state?.width || 0,
          height: state?.height || 0,
          voltage: state?.voltage || 0,
          current: state?.current || 0,
          lifeSpanInDays: state?.lifeSpanInDays || 0,
          specification: state?.specification || "",
          model: state?.model || "",
          description: state?.description || "",
        }}
      >
        <span className="text-primary fs-12 f-500">Basic details</span>
        <br />
        <br />
        <div className="inputs">
          <FormInput name="name" placeholder="Name" />
          <FormInput
            select
            options={categories.map((c) => ({ label: c.name, value: c._id }))}
            name="category"
            placeholder="Select Category"
          />
        </div>
        <br />
        <FormInput name="specification" placeholder="Specification" />
        <br />
        <br />
        <FilePicker
          initial={state?.imageUrl}
          onSelect={setSelectedImage}
          height={100}
        />
        <br />
        <div className="inputs">
          <FormInput
            select
            options={units.map((_) => ({
              label: _.name,
              value: _.value,
            }))}
            name="unitOfMeasure"
            placeholder="Measurement"
          />
          <FormInput name="tags" placeholder="Tags" />
        </div>
        <br />
        <FormInput textarea name="description" placeholder="Description" />
        <br />
        <Collasable title="More details">
          <div className="inputs">
            <FormInput
              name="lifeSpanInDays"
              placeholder="Life Span(days)"
              type="number"
            />
            <FormInput name="model" placeholder="Model" />
          </div>
        </Collasable>
        <Collasable title="Mixture details">
          <Group
            materials={materials}
            items={subMaterials}
            setItems={setSubMaterials}
            Element={SubMaterial}
          />
        </Collasable>
        <Collasable title="Electrical details">
          <div className="inputs">
            <FormInput type="number" name="voltage" placeholder="Voltage (V)" />
            <FormInput
              type="number"
              name="current"
              placeholder="Current (amp)"
            />
          </div>
        </Collasable>
        <Collasable title="Dimention details (ft)">
          <div className="inputs">
            <FormInput type="number" name="width" placeholder="Width" />
            <FormInput type="number" name="height" placeholder="Height" />
          </div>
        </Collasable>
        <br />
        <Submit
          loading={loading || loading2}
          width={170}
          title="Save and Continue"
        />
      </Form>
    </div>
  );
}

export default EditMaterial;
