function AccountBasicDetails({ account }) {
  return (
    <table>
      <tr>
        <td>Full Name</td>
        <td>
          {account.firstName} {account.lastName}
        </td>
      </tr>
      <tr>
        <td>Email</td>
        <td>
          <a
            href={`mailto:${account.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {account.email}
          </a>
        </td>
      </tr>
      <tr>
        <td>Phone</td>
        <td>
          <a
            href={`tel:${account.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {account.phoneNumber}
          </a>
        </td>
      </tr>
      <tr>
        <td>Country</td>
        <td>{account.address.country || "-"}</td>
      </tr>
      <tr>
        <td>State</td>
        <td>{account.address.state || "-"}</td>
      </tr>
      <tr>
        <td>City</td>
        <td>{account.address.city || "-"}</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>{account.address.street || "-"}</td>
      </tr>
      <tr>
        <td>Push Token</td>
        <td>{account.devicePushToken || "-"}</td>
      </tr>
    </table>
  );
}

export default AccountBasicDetails;
