import PlusIcon from "../../../assets/icons/PlusIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import { formatDateTime } from "../../../utils/date";
import statuses from "../../../utils/statuses";
import { getRandomImage } from "../../../utils/string";
import Status from "../../Status";
import Table from "../Table";
import View from "../View";
import WithImage from "../WithImage";
import WithLink from "../WithLink";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "From",
    target: "from",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "By",
    target: "by",
    render: (v) => (
      <WithImage imageUrl={getRandomImage()}>
        <WithLink text={v} />
      </WithImage>
    ),
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        className={`${v ? statuses.warn : statuses.success}-text ${
          v ? statuses.warn : statuses.success
        }-light`}
        text={!v ? "recieved" : "pending"}
      />
    ),
  },
  {
    title: "Time Requested",
    target: "date",
    render: (v) =>
      formatDateTime(
        (() => {
          const d = new Date();
          d.setMinutes(d.getMinutes() - 40);
          return d;
        })()
      ).dateTime,
  },
  {
    title: "Time Sent",
    target: "date",
    render: (v) =>
      formatDateTime(
        (() => {
          const d = new Date();
          d.setMinutes(d.getMinutes() - 30);
          return d;
        })()
      ).dateTime,
  },
  {
    title: "Last updated",
    target: "date",
    render: (v) => formatDateTime(new Date()).dateTime,
  },
  {
    title: "Action",
    target: "date",
    render: (v) => <View />,
  },
];

function RecentSupplyToICart({ width, hideFooter }) {
  return (
    <Table
      hideFooter={hideFooter}
      data={[
        {
          from: "Gudu Prep Room",
          by: "Mannir Yusuf",
          status: true,
          date: "a",
        },
        {
          from: "Gudu Prep Room",
          by: "Mannir Isah",
          status: false,
          date: "a",
        },
      ]}
      btn1={{
        width: 200,
        rSvg: <PlusIcon />,
      }}
      btn2={{
        outline: true,
        width: 200,
        rSvg: <ViewIcon color="var(--main-yellow)" />,
      }}
      head={tableHead}
      title="Recent Supplies"
      maxWidth={width}
    />
  );
}

export default RecentSupplyToICart;
