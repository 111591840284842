import { useState } from "react";
import CheckBox from "./CheckBox";
import store from "../utils/store";

function Settings(props) {
  const [settings, setSettings] = useState(store.getItem("settings") || {});
  return (
    <div style={{ padding: 20 }} className="inter container">
      <h2 className="text-brown">Settings</h2>
      <br />
      <div className="d-flex align-center gap-10">
        <span className="text-brown d-block">iCart Operating Device</span>
        <CheckBox
          value={settings.isOperatingDevice}
          onChange={() => {
            setSettings({
              ...settings,
              isOperatingDevice: !settings?.isOperatingDevice,
            });
            store.setItem("settings", {
              ...settings,
              isOperatingDevice: !settings?.isOperatingDevice,
            });
          }}
        />
      </div>
    </div>
  );
}

export default Settings;
