import { useContext, useEffect, useState } from "react";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithLink from "../../../components/table/WithLink";
import statuses from "../../../utils/statuses";
import useModal from "../../../hooks/useModal";
import LocationProfile from "../../../components/management/LocationProfile";
import useLocations from "../../../hooks/api/useLocations";
import usePagination from "../../../hooks/usePagination";
import AppContext from "../../../contexts/AppContext";

const tableHead = [
  {
    title: "City",
    target: "city.name",
  },
  {
    title: "Country",
    target: "",
  },
  {
    title: "Coordinates",
    target: ["lat", "lng"],
    render: (v) => <WithLink text={v.join(",")} />,
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        className={
          v === "occupied"
            ? statuses.placed
            : v === "active"
            ? statuses.active
            : statuses.inactive
        }
        text={v}
      />
    ),
  },
];

function Locations(props) {
  const { closeModal, openModal, isOpened } = useModal();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const {
    locations,
    createLocation,
    totalCount,
    updateLocation,
    loading,
    getLocations,
  } = useLocations();
  const { formatCurrency } = useContext(AppContext);

  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const refresh = () => getLocations(pageNumber, limit);
  const handleSave = (data) => {
    const cb = () => {
      refresh();
      closeModal();
    };
    if (selectedLocation) updateLocation(selectedLocation?._id, data, cb);
    else createLocation(data, cb);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <div>
      <LocationProfile
        onSave={handleSave}
        isOpened={isOpened}
        closeModal={closeModal}
        loading={loading}
        selectedLocation={selectedLocation}
      />
      <Table
        totalCount={totalCount}
        limit={limit}
        onPaginate={setPageNumber}
        loading={loading}
        btn1={{
          icon: <PlusIcon />,
          title: "Add New Location",
          onClick: () => {
            openModal();
            setSelectedLocation(null);
          },
        }}
        data={locations}
        head={[
          {
            title: "#",
            target: "#",
          },
          {
            title: "Address",
            target: "*",
            render: (v) => (
              <ID
                onClick={() => {
                  setSelectedLocation(v);
                  openModal();
                }}
                id={v.label}
                label="Edit Location"
              />
            ),
          },
          {
            title: "Subscription Fee",
            target: "fee",
            render: formatCurrency,
          },
          ...tableHead,
          {
            title: "Actions",
            target: "id",
            render: (v) => <View />,
          },
        ]}
        title="Locations"
      />
    </div>
  );
}

export default Locations;
