import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Input from "../Input";
import Modal from "../Modal";
import Delete from "../../assets/icons/Delete";
import Button from "../Button";
import useRoles from "../../hooks/api/useRoles";
import { Form, FormInput, Submit } from "../form";
import { roleSchema } from "../../utils/validators/auth";

export const Permission = ({
  remove,
  id,
  permissions = [],
  permission,
  handleChange,
}) => (
  <div style={{ width: "100%", marginBottom: 6 }} className="d-flex gap-10">
    <div className="full-flex">
      <Input
        placeholder="Select Permission"
        select
        value={permission}
        onChange={(v) => handleChange(id, "permission", v)}
        options={permissions.map((_) => ({
          label: _.description,
          value: _.id,
        }))}
      />
    </div>
    <Button width={50} onClick={remove} lSvg={<Delete />} className="bg-warn" />
  </div>
);

function RoleProfile({ isOpened, selected, closeModal }) {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { permissions, getPermissions, updateRole, createRole, loading } =
    useRoles();

  const handleSave = async (data) => {
    data = {
      ...data,
      permissions: selectedPermissions.map((_) => parseInt(_.permission)),
    };
    if (selected) updateRole(selected._id, data, closeModal);
    else createRole(data, closeModal);
  };

  useEffect(() => {
    getPermissions();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selected)
      setSelectedPermissions(
        selected?.permissions?.map((_, idx) => ({ permission: _, id: idx + 1 }))
      );
  }, [selected]);

  return (
    <Modal isVisible={isOpened} closeModal={closeModal}>
      <h2 className="fs-24 raleway f-700 text-primary">Register New Role</h2>
      <br />
      <Form
        validationSchema={roleSchema}
        onSubmit={handleSave}
        initialValues={{
          title: selected?.title,
          description: selected?.description,
        }}
      >
        <FormInput name="title" placeholder="Title" />
        <br />
        <FormInput name="description" placeholder="Description" />
        <br />
        <span className="fs-14 d-block text-brown f-700">Permissions</span>
        <br />
        <Group
          Element={Permission}
          items={selectedPermissions}
          setItems={setSelectedPermissions}
          title="Permissions"
          permissions={permissions}
        />
        <Submit loading={loading} onClick={closeModal} title="Save" />
      </Form>
    </Modal>
  );
}

export default RoleProfile;
