import { useContext, useState } from "react";
import Card from "../../../components/Card";
import Table from "../../../components/table/Table";
import { formatDateTime } from "../../../utils/date";
import Status from "../../../components/Status";
import statuses from "../../../utils/statuses";
import PlusIcon from "../../../assets/icons/PlusIcon";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/Modal";
import { Form, FormInput, Submit } from "../../../components/form";
import { addCategorySchema } from "../../../utils/validators/inventory";
import View from "../../../components/table/View";
import MaterialsContext from "../../../contexts/MaterialsContext";

const tableHead = [
  {
    title: "#",
    target: "#",
    className: "w-50",
  },
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Description",
    target: "description",
  },
  {
    title: "Status",
    target: "name",
    render: (v) => (
      <Status
        text="Active"
        className={`${statuses.active}-light ${statuses.active}-text`}
      />
    ),
  },
  {
    title: "Date Created",
    target: "createdAt",
    render: (v) => formatDateTime(v).date,
  },
];

function ProductCategories(props) {
  const { categories, loading, addCategory, updateCategory } =
    useContext(MaterialsContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { isOpened, openModal, closeModal } = useModal();

  const handleSubmit = (data) => {
    if (selectedCategory)
      updateCategory(selectedCategory._id, data, closeModal);
    else addCategory(data, closeModal);
  };

  return (
    <div>
      <Modal closeModal={closeModal} isVisible={isOpened}>
        <h2 className="fs-24 raleway f-700 text-primary">
          {selectedCategory
            ? `Update ${selectedCategory.name}`
            : "Create New Category"}
        </h2>
        <br />
        <Form
          onSubmit={handleSubmit}
          validationSchema={addCategorySchema}
          initialValues={{
            name: selectedCategory?.name || "",
            description: selectedCategory?.description || "",
          }}
        >
          <FormInput placeholder="Name" name="name" />
          <br />
          <FormInput textarea placeholder="Description" name="description" />
          <br />
          <Submit loading={loading} title="Save and Continue" />
        </Form>
      </Modal>
      <div className="cards d-flex">
        <Card
          title={categories.length}
          subTitle="Active Categories"
          className="success-light"
        />
        <Card title={0} subTitle="Inactive Categories" className="warn-light" />
        <Card title={categories.length} subTitle="Total Categories" />
      </div>
      <br />
      <Table
        head={[
          ...tableHead,
          {
            title: "Action",
            target: "*",
            render: (v) => (
              <View
                onClick={() => {
                  setSelectedCategory(v);
                  openModal();
                }}
              />
            ),
          },
        ]}
        data={categories}
        title="Product Categories"
        loading={loading}
        btn1={{
          icon: <PlusIcon />,
          title: "Create Category",
          onClick: openModal,
        }}
      />
    </div>
  );
}

export default ProductCategories;
