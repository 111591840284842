import { useEffect, useState } from "react";
import Button from "../Button";
import Group from "../FlexGroup";
import Input from "../Input";
import Modal from "../Modal";
import { Permission } from "./RoleProfile";

function DepartmentProfile({
  selectedDepartment,
  closeModal,
  permissions,
  handleSave,
  isOpened,
}) {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [name, setName] = useState("");

  useEffect(() => {
    if (selectedDepartment) {
      setSelectedPermissions(
        selectedDepartment?.permissions?.map((_, idx) => ({
          permission: _,
          id: idx + 1,
        }))
      );
      setName(selectedDepartment?.name);
    }
  }, [selectedDepartment]);

  return (
    <Modal isVisible={isOpened} closeModal={closeModal}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selectedDepartment
          ? `Update: ${selectedDepartment.name}`
          : "Register New Department"}
      </h2>
      <br />
      <Input value={name} onChange={setName} placeholder="Name" />
      <br />
      <span className="fs-14 d-block text-brown f-700">Permissions</span>
      <br />
      <Group
        Element={Permission}
        permissions={permissions}
        items={selectedPermissions}
        setItems={setSelectedPermissions}
        title="Roles"
      />
      <Button
        disabled={!name}
        onClick={() =>
          handleSave({
            name,
            permissions: selectedPermissions.map((_) => parseInt(_.permission)),
          })
        }
        title="Save"
      />
    </Modal>
  );
}

export default DepartmentProfile;
