import Status from "../../../components/Status";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import { formatDateTime } from "../../../utils/date";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Customer",
    target: "name",
    render: (name) => (
      <WithImage
        text={name}
        imageUrl={`https://randomuser.me/api/portraits/men/${(
          Math.random() * 10
        ).toFixed(0)}.jpg`}
      />
    ),
  },

  {
    title: "Primary Brand",
    target: "primaryBrand",
    render: (id) => <WithLink id={id} text={id} />,
  },
  {
    title: "Suggested Location",
    target: "location",
    render: (id) => <WithLink id={id} text={id} />,
  },
  {
    title: "City",
    target: "city",
  },
  {
    title: "Status",
    target: "id",
    render: (v) => <Status text="pending" className="warn-light warn-text" />,
  },
  {
    title: "Payment",
    target: "paid",
    render: (v) => (
      <Status
        text={`${v ? "paid" : "pending"}`}
        className={`${v ? "success" : "warn"}-light ${
          v ? "success" : "warn"
        }-text`}
      />
    ),
  },
  {
    title: "Date",
    target: "id",
    render: (v) => formatDateTime(new Date()).date,
  },
  {
    title: "Actions",
    target: "id",
    render: (v) => <View />,
  },
];

function New(props) {
  return (
    <div>
      <Table
        limit={20}
        totalCount={203}
        data={[]}
        head={tableHead}
        title="New Subscriptions"
      />
    </div>
  );
}

export default New;
