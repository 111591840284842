import { useEffect } from "react";
import ViewIcon from "../../../assets/icons/ViewIcon";
import useOperations from "../../../hooks/api/useOperations";
import usePagination from "../../../hooks/usePagination";
import { formatDateTime } from "../../../utils/date";
import { path } from "../../../utils/path";
import Status from "../../Status";
import ID from "../ID";
import Table from "../Table";
import WithImage from "../WithImage";
import WithLink from "../WithLink";
import statuses from "../../../utils/statuses";

const tableHead = [
  {
    title: "ID",
    target: "*",
    render: (v) => (
      <ID
        state={v.length ? { ...v[0], items: v[1], _id: v[2] } : v}
        link={
          path.iCartOperating.onlineOrders.details.url +
          `?id=${v.length ? v[0].id : v.id}`
        }
        label="View Order"
        id={v.length ? v[0].id : v.id}
      />
    ),
  },
  {
    title: "User",
    target: "customerInformation",
    render: (info) => <WithImage text={info.name} />,
  },
  {
    title: "Phone Number",
    target: "customerInformation.phoneNumber",
    render: (phoneNumber) => (
      <WithLink link={`tel:${phoneNumber}`} text={phoneNumber} />
    ),
  },
  {
    title: "Delivery address",
    target: "deliveryTo.address",
    render: (name) => <WithLink text={name} />,
  },
  {
    title: "Order Status",
    target: "status",
    render: (v) => <Status light text={v} className={statuses[v]} />,
  },
  {
    title: "Payment Status",
    target: "paymentCompleted",
    render: (v, status = v ? "paid" : "pending") => (
      <Status light text={status} className={statuses[status]} />
    ),
  },
  {
    title: "Date",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  // {
  //   title: "Action",
  //   target: "id",
  //   render: (v) => <View />,
  // },
];

function OnlineOrders({
  title = "Online Orders",
  btn1 = {
    title: "View all",
    icon: <ViewIcon />,
    link: path.iCartOperating.onlineOrders.url,
  },
  initialLimit = 5,
  hideFooter = false,
  kiosk = false,
  status,
}) {
  const { getKioskOrders, getOrders, orders, loading, totalOrders } =
    useOperations();
  const { limit, pageNumber, setPageNumber } = usePagination({ initialLimit });

  useEffect(() => {
    if (kiosk) getKioskOrders(pageNumber, limit, status);
    else getOrders(pageNumber, limit, status);
  }, [pageNumber, limit, kiosk, status]);

  return (
    <Table
      totalCount={totalOrders}
      onPaginate={setPageNumber}
      limit={limit}
      head={
        kiosk
          ? tableHead.map((_) => ({
              ..._,
              target:
                _.target === "*"
                  ? ["order", "items", "_id"]
                  : `order.${_.target}`,
            }))
          : tableHead
      }
      loading={loading}
      data={orders}
      btn1={btn1}
      title={title}
      hideFooter={hideFooter}
    />
  );
}

export default OnlineOrders;
