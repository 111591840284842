import { icons } from "../assets/icons/Icons";
import SearchIcon from "../assets/icons/SearchIcon";
import Button from "./Button";
import Card from "./Card";
import Icon from "./Icon";
import Input from "./Input";
import Status from "./Status";
import Tab from "./Tab";
import Table from "./table/Table";

const Svg = ({ color = "#f7941d" }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 0.5H1.66667C0.741667 0.5 0 1.25 0 2.16667V13.8333C0 14.75 0.741667 15.5 1.66667 15.5H13.3333C14.25 15.5 15 14.75 15 13.8333V2.16667C15 1.25 14.25 0.5 13.3333 0.5ZM13.3333 13.8333H1.66667V2.16667H13.3333V13.8333ZM6.66667 12.1667H8.33333V8.83333H11.6667V7.16667H8.33333V3.83333H6.66667V7.16667H3.33333V8.83333H6.66667V12.1667Z"
      fill={color}
    />
  </svg>
);

function Dev(props) {
  return (
    <div>
      <Tab
        onSwitch={console.log}
        tabs={[
          {
            title: "Tab One",
          },
          {
            title: "Tab Two",
          },
          {
            title: "Tab Three",
          },
          {
            title: "Tab Four",
          },
        ]}
      />
      <br />
      <div className="cards d-flex">
        <Card className="success-light" />
        <Card className="warn-light" />
        <Card className="danger-light" />
        <Card />
      </div>
      <br />
      <Table />
      <br />
      <Input placeholder="Email" />
      <br />
      <Input
        borderColor="var(--yellow-tints-5)"
        rIcon={
          <Icon>
            <SearchIcon />
          </Icon>
        }
        placeholder="Email"
      />
      <br />
      <div style={{ backgroundColor: "black", padding: 10 }}>
        {Object.values(icons).map((Icon, idx) => (
          <Icon key={idx} />
        ))}
      </div>
      <br />
      <div className="flex">
        <div>
          <br />
          <Button
            rSvg={<Svg color="white" />}
            width={50}
            height={50}
            // title="Next"
          />
          <br />
          <Button
            rSvg={<Svg />}
            width={50}
            height={50}
            outline
            // title="Next"
          />
          <br />
          <Button width={200} title="Next" />
          <br />
          <Button width={200} outline title="Next" />
          <br />
          <br />
          <Button lSvg={<Svg color="white" />} width={200} title="Next" />
          <br />
          <Button lSvg={<Svg />} width={200} outline title="Next" />
          <br />
          <br />
          <Button rSvg={<Svg color="white" />} width={200} title="Next" />
          <br />
          <Button rSvg={<Svg />} width={200} outline title="Next" />
          <br />
        </div>
        <div style={{ paddingLeft: 30, paddingTop: 20 }}>
          <Status className="success" text="Done" />
          <br />
          <Status className="warn" text="pending" />
          <br />
          <Status className="danger" text="canceled" />
          <br />
          <Status className="status-tint5" text="placed" />
          <br />
          <Status className="disabled" text="disabled" />
          <br />
          <Status className="success-text" text="Done" />
          <br />
          <Status className="warn-text" text="pending" />
          <br />
          <Status className="danger-text" text="canceled" />
          <br />
          <Status className="success-text success-light" text="Available" />
          <br />
          <Status className="warn-text warn-light" text="low on stock" />
          <br />
          <Status className="danger-text danger-light" text="out of stock" />
          <br />
          <Icon className="warn-light"></Icon>
          <br />
          <Icon className="danger-light"></Icon>
          <br />
          <Icon className="success-light"></Icon>
          <br />
          <Icon className="warn-light round"></Icon>
          <br />
          <Icon className="danger-light round"></Icon>
          <br />
          <Icon className="success-light round"></Icon>
        </div>
      </div>
    </div>
  );
}

export default Dev;
