import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import Dashboard from "../pages/app/conceptAndMenus/Dashboard";
import ViewConcept from "../pages/app/conceptAndMenus/ViewConcept";

function ConceptsAndCreators(props) {
  return (
    <Routes>
      <Route
        path={path.conceptAndCreators.viewConcept.root}
        element={<ViewConcept />}
      />
      <Route path={path.all} element={<Dashboard />} />
    </Routes>
  );
}

export default ConceptsAndCreators;
