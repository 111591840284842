import { useEffect, useState } from "react";
import StepsIndicator from "../../../components/StepsIndicator";
import BasicInfo from "../../../components/concepts/BasicInfo";
import Menu from "../../../components/concepts/Menu";
import Packaging from "../../../components/concepts/Packaging";
import Machineries from "../../../components/concepts/Machineries";
import Review from "../../../components/concepts/Review";
import Ingredients from "../../../components/concepts/Ingredients";
import PrepTimeTable from "../../../components/concepts/PrepTimeTable";
import Grading from "../../../components/concepts/Grading";
import Approval from "../../../components/concepts/Approval";
import Activation from "../../../components/concepts/Activation";
import { useSearchParams } from "react-router-dom";
import useConcepts from "../../../hooks/api/useConcepts";
import { getNestedValueFromObject } from "../../../utils/array";
import Loader from "../../../components/Loader";
import { doesValueExists } from "../../../utils/object";
import statuses from "../../../utils/statuses";
import Extras from "../../../components/concepts/Extras";

const allSteps = [
  {
    id: 1,
    title: "Concept Details",
    details:
      "Please provide the key details of your culinary concept. This includes the name, its origin, and a brief description. Make sure to highlight what makes your concept unique and how it stands out in the food space.",
    Element: BasicInfo,
    target: "concept",
  },
  {
    id: 2,
    title: "Machineries",
    details:
      "List any special tools or equipment required to bring your culinary concept to life. This could include specific kitchen appliances, machinery, or tools that are essential for its preparation or presentation.",
    Element: Machineries,
    target: "machineries",
  },
  {
    id: 3,
    title: "Ingredient",
    details:
      "Provide a list of all ingredients required for your culinary concept. Be specific about quantities, sourcing, and any unique or locally sourced ingredients that give your dishes a special touch. Highlight any allergens or dietary considerations.",
    Element: Ingredients,
    target: "ingredients",
  },
  {
    id: 4,
    title: "Extras & Preps",
    details:
      "Provide a list of all menu extras and mixtures required for your culinary concept. Be specific about quantities, sourcing, and any unique or locally sourced ingredients that give your dishes a special touch. Highlight any allergens or dietary considerations.",
    target: "extras",
    Element: Extras,
  },

  {
    id: 5,
    title: "Menu Details",
    details:
      "Outline the main dishes or items that form the core of your culinary concept. Be sure to include detailed recipes, including ingredients, preparation steps, and any special techniques or cooking methods. Highlight unique features or flavors that will captivate your audience and bring your concept to life.",
    Element: Menu,
    target: "menu",
  },
  {
    id: 6,
    title: "Packaging",
    details:
      "Describe the packaging or presentation style for your culinary concept. Consider how the packaging aligns with the brand identity and customer experience. Mention any sustainable or eco-friendly packaging choices that enhance the appeal of your concept.",
    Element: Packaging,
    target: "concept.packaging",
  },
  {
    id: 7,
    title: "Review & Submit",
    details:
      "Lorem ipsum dolor sit amet consectetur. Donec enim iaculis diam aliquet id purus suspendisse ullamcorper faucibus.",
    Element: Review,
  },
  {
    id: 8,
    title: "Grading",
    details: "Concept grading for iCart Africa",
    Element: Grading,
    target: "concept?.grade",
  },
  {
    id: 9,
    title: "Approve for Test Run",
    details: "Test Running",
    Element: Approval,
  },
  {
    id: 10,
    title: "Prep Making & Distribution Time Table",
    details:
      "Concept Prep Making & Distribution Time Table for iCart Prep Rooms!",
    Element: PrepTimeTable,
  },

  {
    id: 11,
    title: "Activation",
    details:
      "Aconcept Configuration & Activation Globaly/City on iCart Africa.",
    Element: Activation,
    // target: "concept.regions",
  },
];

function EditConcept({ pageTitle }) {
  const steps = allSteps;
  const [currentStep, setCurrentStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const goToNext = () => setCurrentStep(currentStep + 1);
  const { Element, title, details, id } = steps[currentStep] || steps[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const { getConcept, concept, addDataToConcept, loading } = useConcepts();

  useEffect(() => {
    if (searchParams.get("id")) getConcept(searchParams.get("id"));
    // eslint-disable-next-line
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (concept) {
      const completed = steps
        .filter(
          (_) =>
            doesValueExists(getNestedValueFromObject(concept, _.target)) ||
            (_.id === 6 && concept.concept.status === statuses.pending) ||
            (_.id === 7 && concept.concept.rating > 0)
        )
        .map((_) => ({
          data: getNestedValueFromObject(concept, _.target),
          id: _.id,
        }));
      if (completed.length) {
        setCompleted(completed);
        setCurrentStep(completed[completed.length - 1].id);
      }
    }
    // eslint-disable-next-line
  }, [concept]);

  return (
    <div>
      <h2 className="fs-24 f-700 text-primary raleway">{pageTitle}</h2>
      <br />
      <div className="d-flex gap-10">
        {steps.map((_, idx) => (
          <StepsIndicator
            key={idx.toString()}
            end={idx === steps.length - 1}
            index={_.id}
            skiped={
              currentStep > idx && !completed.find((__) => __.id === _.id)
            }
            className={
              idx === currentStep
                ? "current"
                : idx < currentStep
                ? "completed"
                : ""
            }
            onClick={() => setCurrentStep(idx)}
          />
        ))}
      </div>
      <br />
      <br />
      <div className="d-flex gap-10 tab-column flex-wrap ">
        <div className="steps-details">
          <h2 className="raleway fs-18 f-700 text-brown">{title}</h2>
          <br />
          <p style={{ color: "#3A3A3C" }} className="fs-12 f-400">
            {details}
          </p>
        </div>
        <br />
        <div className="full-flex">
          {id && loading && !concept ? (
            <div
              style={{ padding: 40 }}
              className="d-flex justify-center align-center"
            >
              <Loader loading />
            </div>
          ) : (
            <Element
              getConcept={getConcept}
              loading={loading}
              concept={concept}
              id={searchParams.get("id")}
              addDataToConcept={addDataToConcept}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              onFinished={(data) => {
                if (data) setCompleted([...completed, { id, data }]);
                if (currentStep > steps.length) goToNext();
              }}
            />
          )}
          <br />
          {/* <Button
            disabled={currentStep >= steps.length - 1}
            title="Next"
            onClick={goToNext}
          /> */}
        </div>
        <div style={{ width: "25%" }} />
      </div>
    </div>
  );
}

export default EditConcept;
