import { useState } from "react";
import StarFill from "../assets/icons/StarFill";
import { formFrom } from "../utils/array";

const Star = ({ number, showNumber, update, current }) => (
  <button
    onClick={update}
    style={
      showNumber ? null : { backgroundColor: "transparent", borderWidth: 0 }
    }
    className={`review-star raleway f-700 d-flex justify-center align-center ${
      (showNumber ? current === number : current >= number) ? "marked" : ""
    }`}
  >
    {showNumber ? number : <StarFill color="var(--yellow-tints-7)" />}
  </button>
);

function StarsReview({
  limit = 5,
  showNumber = true,
  defaultRating = 1,
  onChange,
}) {
  const [currentRating, setCurrentRating] = useState(defaultRating);

  return (
    <div className="d-flex align-center gap-10">
      {formFrom(1, limit).map((_) => (
        <Star
          showNumber={showNumber}
          key={_}
          current={currentRating}
          number={_}
          update={() => {
            if (typeof onChange === "function") {
              setCurrentRating(_);
              onChange(_);
            }
          }}
        />
      ))}
    </div>
  );
}

export default StarsReview;
