import { selectFromObject } from "../../utils/array";
import StarsReview from "../StarsReview";
import Collapsable from "../Collapsable";
import urls from "../../api/urls";
import Group from "../FlexGroup";
import { useState } from "react";
import Button from "../Button";
import Input from "../Input";

const GradingGroup = ({
  id,
  score,
  comment,
  title = "",
  handleChange,
  defaultOpened = false,
}) => {
  return (
    <Collapsable title={title} defaultOpened={defaultOpened}>
      <span
        style={{ marginBottom: 5 }}
        className="fs-14 d-block text-brown f-700"
      >
        Rating
      </span>
      <StarsReview
        defaultRating={score}
        onChange={(v) => handleChange(id, "score", v)}
      />
      <br />
      <span
        style={{ marginBottom: 5 }}
        className="fs-14 d-block text-brown f-700"
      >
        Comment
      </span>
      <Input
        value={comment}
        onChange={(v) => handleChange(id, "comment", v)}
        placeholder=""
        textarea
      />
    </Collapsable>
  );
};

const Fragment = () => null;

function Grading({ addDataToConcept, concept, loading, onFinished, id }) {
  const [scores, setScores] = useState(
    concept?.concept?.grade
      ? scoresTemplate.map((_) => ({
          ..._,
          score: concept.concept.grade[_.objKey].score || 1,
          comment: concept.concept.grade[_.objKey].comment || "",
        }))
      : scoresTemplate
  );
  const submitGrading = async () => {
    const obj = {};
    scores.forEach(
      (_) => (obj[_.objKey] = selectFromObject(["score", "comment"], _))
    );
    const response = await addDataToConcept(
      id,
      obj,
      urls.business.concepts.grade
    );
    if (response) onFinished(response);
  };

  return (
    <div>
      <Group
        items={scores}
        setItems={setScores}
        AddElement={Fragment}
        Element={GradingGroup}
      />
      <br />
      <Button
        loading={loading}
        onClick={submitGrading}
        title="Save & Continue"
      />
    </div>
  );
}

export default Grading;

const scoresTemplate = [
  {
    id: 1,
    title: "Prep Time",
    score: 1,
    comment: "",
    objKey: "prepTime",
  },
  {
    id: 2,
    title: "Ticket Time",
    score: 1,
    comment: "",
    objKey: "ticketTime",
  },
  {
    id: 3,
    title: "Ingredient Simplicity",
    score: 1,
    comment: "",
    objKey: "ingredientSimplicity",
  },
  {
    id: 4,
    title: "Storage Requirements",
    score: 1,
    comment: "",
    objKey: "storageRequirements",
  },
  {
    id: 5,
    title: "Equipments Needed",
    score: 1,
    comment: "",
    objKey: "equipmentsNeeded",
  },
  {
    id: 6,
    title: "Ease of Assembly",
    score: 1,
    comment: "",
    objKey: "easeOfAssembly",
  },
  {
    id: 7,
    title: "Portability",
    score: 1,
    comment: "",
    objKey: "portability",
  },
  {
    id: 8,
    title: "Versatility",
    score: 1,
    comment: "",
    objKey: "versatility",
  },
  {
    id: 9,
    title: "Cost Efficiency",
    score: 1,
    comment: "",
    objKey: "costEfficiency",
  },
  {
    id: 10,
    title: "Customer Demand",
    score: 1,
    comment: "",
    objKey: "customerDemand",
  },
  {
    id: 11,
    title: "Regulatory Compliance",
    score: 1,
    comment: "",
    objKey: "regulatoryCompliance",
  },
];
