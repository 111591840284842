import { useState } from "react";
import urls from "../../api/urls";
import useAPI from "./useAPI";
import { toast } from "react-toastify";

const useBanking = () => {
  const [banks, setBanks] = useState([]);
  const [savedAccounts, setSavedAccounts] = useState([]);
  const [verifiedData, setVerifiedData] = useState(null);
  const { get, loading, post, patch } = useAPI();

  const getBanks = async () => {
    try {
      const { data, error } = await get(urls.banking.banks);
      if (error) return;
      setBanks(data.banks);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyAccount = async (bankCode, accountNumber) => {
    try {
      setVerifiedData(null);
      const { data, error } = await post(urls.banking.verify, {
        bankCode,
        accountNumber,
      });
      if (error) return;
      setVerifiedData(data.response);
      toast.info(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const addBank = async (details, cb) => {
    try {
      const { error, data } = await post(urls.banking.add, details);
      if (error) return;
      toast.success(data.message);
      if (typeof cb === "function") cb(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSavedAccounts = async () => {
    try {
      const { data, error } = await get(urls.banking.accounts);
      if (error) return;
      setSavedAccounts(data.bankAccounts);
    } catch (error) {
      console.log(error);
    }
  };
  const updateAccountStatus = async (id, active) => {
    try {
      await patch(urls.banking.accounts + "/" + id, { active });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loading,
    getBanks,
    banks,
    verifiedData,
    verifyAccount,
    addBank,
    savedAccounts,
    getSavedAccounts,
    updateAccountStatus,
  };
};

export default useBanking;
