import { useEffect } from "react";
import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import useOutlets from "../../../hooks/api/useOutlets";
import usePagination from "../../../hooks/usePagination";
import { path } from "../../../utils/path";
import statuses from "../../../utils/statuses";
import { getRandomImage } from "../../../utils/string";
import { outletTypes } from "../../../utils/vars";
import useConcepts from "../../../hooks/api/useConcepts";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Code",
    target: "*",
    render: (id) => (
      <ID
        link={path.conceptAndCreators.viewConcept.url + `?id=${id._id}`}
        id={id._id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="View Conceot"
        state={id}
      />
    ),
  },
  {
    title: "Name",
    target: ["logo", "name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        light
        className={
          v === "rejected"
            ? statuses.danger
            : v === "pending"
            ? statuses.warn
            : v === "active"
            ? statuses.active
            : statuses.info
        }
        text={v}
      />
    ),
  },
];

function Concepts({ ...props }) {
  const { allConcepts, totalCount, loading, getAllConcepts } = useConcepts();

  const { pageNumber, limit, setPageNumber } = usePagination({
    initialLimit: 5,
  });

  useEffect(() => {
    getAllConcepts(pageNumber, limit, "approved");
  }, [pageNumber, limit]);

  return (
    <Table
      {...props}
      loading={loading}
      data={allConcepts}
      limit={limit}
      onPaginate={setPageNumber}
      totalCount={totalCount}
      head={tableHead}
    />
  );
}

export default Concepts;
