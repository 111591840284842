import { useEffect } from "react";
import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import useOutlets from "../../../hooks/api/useOutlets";
import usePagination from "../../../hooks/usePagination";
import { path } from "../../../utils/path";
import statuses from "../../../utils/statuses";
import { getRandomImage } from "../../../utils/string";
import { outletTypes } from "../../../utils/vars";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "iCart ID",
    target: "*",
    render: (id) => (
      <ID
        state={id}
        link={path.management.icarts.view.url + `?id=${id.name}`}
        id={id.name}
        label="View iCart"
      />
    ),
  },
  // {
  //   title: "Customer Info",
  //   target: "customer",
  //   render: (name) => <WithImage text={name} imageUrl={getRandomImage()} />,
  // },
  {
    title: "Location",
    target: "location",
    render: (v) => <WithLink text={v.label} />,
  },
  {
    title: "Status",
    target: "active",
    render: (v) => (
      <Status className={statuses[v?.toString()]} text={v?.toString()} />
    ),
  },
  {
    title: "Actions",
    target: "*",
    render: (v) => (
      <View
        title="View iCart"
        state={v}
        link={path.management.icarts.view.url + `?id=${v._id}`}
      />
    ),
  },
];

function ICarts({ ...props }) {
  const { outlets, totalCount, loading, getOutlets } = useOutlets();

  const { pageNumber, limit, setPageNumber } = usePagination({
    initialLimit: 5,
  });

  useEffect(() => {
    getOutlets(pageNumber, limit, outletTypes.kiosk);
  }, [pageNumber, limit]);

  return (
    <Table
      {...props}
      loading={loading}
      data={outlets}
      limit={limit}
      onPaginate={setPageNumber}
      totalCount={totalCount}
      // data={[
      //   {
      //     id: "iC10001ABJNG",
      //     customer: "Ismail Dalhatu",
      //     location: "MKK Plaza Gudu Abuja, Nigeria",
      //     status: "active",
      //   },
      //   {
      //     id: "iC10025GOMNG",
      //     customer: "Abubakar Sadiq Dalhatu",
      //     location: "Gombe, Nigeria",
      //     status: "purchased",
      //   },
      //   {
      //     id: "iC10024GOMNG",
      //     customer: "Usman Dalhatu",
      //     location: "New Yoker Maitama Abuja, Nigeria",
      //     status: "inactive",
      //   },
      // ]}
      head={tableHead}
    />
  );
}

export default ICarts;
