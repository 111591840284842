import { useContext, useEffect, useState } from "react";
import Cash from "../../assets/icons/Cash";
import PlusIcon from "../../assets/icons/PlusIcon";
import Button from "../Button";
import AppContext from "../../contexts/AppContext";
import Card from "../Card";
import Table from "../table/Table";
import useWallet from "../../hooks/api/useWallet";
import Modal from "../Modal";
import FormattedInput from "../FormattedInput";
import Input from "../Input";
import useModal from "../../hooks/useModal";
import { COLORS } from "../../utils/colors";
import Icon from "../Icon";
import Unblock from "../../assets/icons/Unblock";
import BlockIcon from "../../assets/icons/BlockIcon";
import Transactions from "../table/withdata/Transactions";

const actions = ["Credit", "Debit", "Block", "Unblock"];

function AccountWallet({ account }) {
  const [amount, setAmount] = useState(null);
  const [type, setType] = useState(0);
  const { formatCurrency } = useContext(AppContext);
  const {
    creditWallet,
    debitWallet,
    blockWallet,
    unBlockWallet,
    loading,
    getUserWallet,
    wallet,
  } = useWallet();
  const { openModal, closeModal, isOpened } = useModal();

  useEffect(() => {
    getUserWallet(account._id);
  }, []);

  return (
    <div>
      <Modal isVisible={isOpened}>
        <h2 className="fs-24 raleway f-700 text-primary">
          {actions[type]} Wallet
        </h2>
        <br />
        {type < 2 ? (
          <>
            <FormattedInput placeholder="Amount" onChange={setAmount} />
            <br />
            <Input placeholder="Note" textarea />
            <br />
          </>
        ) : (
          <>
            <p>
              {type === 2
                ? `Blocking ${account.firstName} wallet would result in inability to perform any transaction on the wallet including withdrawals`
                : `Ublock wallet`}
            </p>
            <br />
          </>
        )}
        <div className="d-flex gap-10">
          <Button onClick={closeModal} title="Cancel" outline width={100} />
          <Button
            disabled={!amount && type < 2}
            loading={loading}
            onClick={() => {
              if (type < 2) {
                type
                  ? creditWallet({ amount, account: account._id }, closeModal)
                  : debitWallet({ amount, account: account._id }, closeModal);
              } else
                type === 2
                  ? blockWallet(account._id, closeModal)
                  : unBlockWallet(account._id, closeModal);
            }}
            title="Continue"
          />
        </div>
      </Modal>
      <div className="cards wallet-cards d-flex">
        <div style={{ flex: 1, position: "relative" }} className="bordered">
          <Icon
            className={`wallet-action-icon ${
              wallet?.status === "active" ? "danger-light" : "success-light"
            }`}
            onClick={() => {
              setType(wallet.status === "active" ? 2 : 3);
              openModal();
            }}
          >
            {wallet.status === "active" ? (
              <BlockIcon color="var(--alerts-danger)" />
            ) : (
              <Unblock color="var(--alerts-success)" />
            )}
          </Icon>
          <span className="sp d-block fs-14 f-400 raleway">
            Available Balance
          </span>
          <br />
          <span className="sp d-block text-brown fs-28 f-600">
            {formatCurrency(wallet?.balance, wallet?.currency)}
          </span>
          <span className="fs-11 f-700 text-brown">
            Ledger Balance:{" "}
            {formatCurrency(wallet?.ledgerBalance, wallet?.currency)}
          </span>
          <br />
          <div style={{ width: "100%" }} className="btns flex-wrap wallet-btns">
            <Button
              disabled={wallet?.status !== "active"}
              onClick={() => {
                setType(1);
                openModal();
              }}
              lSvg={<PlusIcon />}
              title="Credit Wallet"
            />
            <Button
              disabled={wallet?.status !== "active"}
              onClick={() => {
                setType(0);
                openModal();
              }}
              lSvg={<Cash />}
              title="Debit Wallet"
              outline
            />
          </div>
        </div>
        <Card
          formatTitle={false}
          title={formatCurrency(0, wallet?.currency)}
          style={{ flex: 0.4 }}
          subTitle="Total Spent"
          className="justify-center align-center warn-light"
        />
        <Card
          formatTitle={false}
          className="justify-center align-center success-light"
          title={formatCurrency(0, wallet?.currency)}
          style={{ flex: 0.4 }}
          subTitle="Money In"
        />
        <Card
          formatTitle={false}
          title={formatCurrency(0, wallet?.currency)}
          style={{ flex: 0.4 }}
          className="justify-center align-center info-light"
          subTitle="Transaction Volume"
        />
      </div>
      <br />
      <Transactions
        refresh={isOpened}
        currency={wallet?.currency}
        account={account}
        maxWidth={0.7}
        title="Recent Transactions"
      />
    </div>
  );
}

export default AccountWallet;
