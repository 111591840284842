import DashboardIcon from "../../../assets/icons/DashboardIcon";
import { CardWithIcon } from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";
import RegisterBrand from "../../../components/RegisterBrand";
import MyConcepts from "../../../components/table/withdata/MyConcepts";
import { months } from "../../../utils/date";

function Dashboard(props) {
  return (
    <div>
      <RegisterBrand />
      <div className="cards d-flex">
        <CardWithIcon
          title={3}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="warn-light"
          subTitle="Pending Concepts"
        />
        <CardWithIcon
          title={2}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="success-light"
          subTitle="Active Concepts"
        />
        <CardWithIcon
          title={7}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="info-light"
          subTitle="Total Concepts"
        />
        <CardWithIcon
          title={2}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="danger-light"
          subTitle="Rejected Concepts"
        />
      </div>
      <br />
      <Revenue labels={months} height={400} title="Revenue" />
      <br />
      <MyConcepts />
    </div>
  );
}

export default Dashboard;
