import { useEffect } from "react";
import Loader from "../../../components/Loader";
import useConcepts from "../../../hooks/api/useConcepts";
import useQuery from "../../../hooks/useQuery";
import Image from "../../../components/Image";
import StarsReview from "../../../components/StarsReview";
import NotFound from "../../../components/NotFound";
import Collapsable from "../../../components/Collapsable";
import WithImage from "../../../components/table/WithImage";
import { units } from "../../../utils/vars";

const getConvertedRatio = (value, unit) => {
  const to = units.find((x) => x.value === unit).conversions[0];
  const _value = to ? (value / to.ratio).toFixed(2) : value;
  return _value < 1 ? `${value}${unit}` : `${_value}${to ? to.name : unit}`;
};

const Recipe = ({ _, ingredients = [], output }) => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Qauntity</th>
        <th>Instruction</th>
        {/* {withoutput ? <th>Output</th> : null} */}
      </tr>
    </thead>
    <tbody>
      {_.recipe.map((xx) => {
        const i = xx.conceptIngredient || xx.conceptExtra;
        return (
          <tr key={xx._id}>
            <td>
              <WithImage
                text={i.name}
                imageUrl={
                  ingredients.find((y) => y.material._id === i.material)
                    ?.material?.imageUrl
                }
              />
            </td>
            <td>
              {xx.quantity}
              {i.unitOfMeasure}
            </td>
            <td style={{ maxWidth: 150, whiteSpace: "normal" }}>
              {xx.instruction}
            </td>
          </tr>
        );
      })}
      {output ? (
        <tr>
          <td></td>
          <td>
            <strong>Output: </strong>
          </td>
          <td>
            <strong>{output}</strong>
          </td>
        </tr>
      ) : null}
    </tbody>
  </table>
);

function ViewConcept(props) {
  const { getConceptsDetails, conceptDetails, loading } = useConcepts();
  const q = useQuery();

  useEffect(() => {
    getConceptsDetails(q.get("id"));
  }, []);

  if (!q.get("id") || (!loading && !conceptDetails)) return <NotFound />;

  if (loading)
    return (
      <div
        style={{ height: "100%" }}
        className="d-flex align-center justify-center"
      >
        <Loader loading />
      </div>
    );
  return (
    <div>
      <div className="concept-head d-flex gap-10">
        <div
          className="full-flex concept-img-head"
          style={{ width: "50%", height: 250 }}
        >
          <Image src={conceptDetails.concept.logo} />
        </div>
        <div style={{ width: "50%" }}>
          <h2 className="text-brown">{conceptDetails.concept.name}</h2>
          <p>{conceptDetails.concept.description} </p>
          <br />
          <div className="d-flex gap-10 align-center">
            <h2 className="text-brown">4.5</h2>
            <StarsReview showNumber={false} defaultRating={4} />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div style={{ gap: 20 }} className="d-flex">
        <div className="full-flex width-50">
          <Collapsable
            className="fs-19 f-700 text-primary raleway text-brown"
            color="var(--yellow-tints-5)"
            title="Raw Materials"
          >
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Specification</th>
                </tr>
              </thead>
              <tbody>
                {conceptDetails.ingredients.map((_) => (
                  <tr key={_._id}>
                    <td>
                      <WithImage
                        imageUrl={_.material?.imageUrl}
                        text={_.name}
                      />
                    </td>
                    <td>
                      {units.find((x) => x.value === _.unitOfMeasure)?.name ||
                        _.unitOfMeasure}
                    </td>
                    <td>{_.material.specification || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Collapsable>
        </div>
        <div className="full-flex width-50">
          <Collapsable
            className="fs-19 f-700 text-primary raleway text-brown"
            color="var(--yellow-tints-5)"
            title="Machineries"
          >
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Model</th>
                </tr>
              </thead>
              <tbody>
                {conceptDetails.machineries.map((_) => (
                  <tr key={_._id}>
                    <td>
                      <WithImage
                        imageUrl={_.material?.imageUrl}
                        text={_.name}
                      />
                    </td>
                    <td>{_.model}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Collapsable>
        </div>
      </div>
      <br />
      <br />
      <div style={{ gap: 20 }} className="d-flex">
        <div className="full-flex width-50">
          <Collapsable
            className="fs-19 f-700 text-primary raleway text-brown"
            color="var(--yellow-tints-5)"
            title="Extras"
          >
            {conceptDetails.extras.map((_) => (
              <Collapsable showInfo={false} title={_.name} key={_._id}>
                <Recipe
                  output={getConvertedRatio(_.output, _.unitOfMeasure)}
                  ingredients={conceptDetails.ingredients}
                  _={_}
                />
              </Collapsable>
            ))}
          </Collapsable>
        </div>
        <div className="full-flex width-50">
          <Collapsable
            className="fs-19 f-700 text-primary raleway text-brown"
            color="var(--yellow-tints-5)"
            title="Menu"
          >
            {conceptDetails.menu.map((_) => (
              <Collapsable title={_.name} key={_._id}>
                <Recipe
                  withoutput
                  ingredients={conceptDetails.ingredients}
                  _={_}
                />
              </Collapsable>
            ))}
          </Collapsable>
        </div>
      </div>
    </div>
  );
}

export default ViewConcept;
