import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useLocations(props) {
  const [totalCount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const { loading, post, get, put } = useAPI();

  const getLocations = async (pageNumber = 1, limit = 10, type) => {
    const obj = { pageNumber, limit };
    if (type) obj.type = type;
    const { data, error } = await get(
      addParamsToUrl(urls.locations.baseUrl, obj)
    );
    if (error) return;
    setTotalCount(data.pagination.total);
    setLocations(data.locations);
  };

  const createLocation = async (fdata, cb) => {
    const { error, message } = await post(urls.locations.baseUrl, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const updateLocation = async (id, fdata, cb) => {
    const { error, message } = await put(
      urls.locations.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    locations,
    totalCount,
    getLocations,
    createLocation,
    updateLocation,
  };
}
export default useLocations;
