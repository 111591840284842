import { useContext } from "react";
import Modal from "../Modal";
import AppContext from "../../contexts/AppContext";
import { Form, FormInput, Submit } from "../form";
import { citySchema } from "../../utils/validators/management";
import Button from "../Button";
import useConfig from "../../hooks/api/useConfig";

function CityProfile({ closeModal, selected, isOpened }) {
  const { addCity, updateCity, loading, countries } = useContext(AppContext);
  const { getStates, states, loading: statesLoading } = useConfig();

  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selected ? `Update ${selected?.name}` : "Register New City"}
      </h2>
      <br />
      <Form
        onSubmit={(v) => {
          if (selected) updateCity(selected._id, v, closeModal);
          else addCity(v, closeModal);
        }}
        validationSchema={citySchema}
        initialValues={{
          name: selected?.name || "",
          country: selected?.country?._id || "",
          code: selected?.code || "",
        }}
      >
        <div className="inputs">
          <FormInput
            select
            listener={(v) => {
              const code = countries.find((_) => _._id === v)?.code;
              if (code) getStates(code);
            }}
            options={countries.map((_) => ({ label: _.name, value: _._id }))}
            placeholder="Country"
            name="country"
          />
          <FormInput
            select
            listener={(v, setFieldValue) => {
              const state = states.find((_) => _.name === v);
              if (state) {
                setFieldValue("code", state.iso2);
              }
            }}
            loading={statesLoading}
            options={states.map((_) => ({ label: _.name, value: _.name }))}
            placeholder="Name"
            name="name"
          />
        </div>
        <br />
        <FormInput name="code" placeholder="City Code" />
        <br />
        <div className="d-flex gap-10">
          <Button onClick={closeModal} width={100} title="Cancel" outline />
          <Submit loading={loading} title="Save" />
        </div>
      </Form>
    </Modal>
  );
}

export default CityProfile;
