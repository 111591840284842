import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Base from "./routes/Base";
import Auth from "./routes/Auth";
import { useState } from "react";
import AuthContext from "./contexts/AuthContext";
import Splash from "./pages/auth/Splash";
import { BrowserRouter } from "react-router-dom";
import DeviceSetup from "./components/DeviceSetup";

function App(props) {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ user, setUser }}>
        {!isReady ? (
          <Splash setUser={setUser} setIsReady={setIsReady} />
        ) : user ? (
          <Base />
        ) : (
          <Auth user={user} />
        )}
        <DeviceSetup />
        <ToastContainer />
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
