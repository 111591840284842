import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import Dashboard from "../pages/app/store/Dashboard";

function Stores(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
    </Routes>
  );
}

export default Stores;
