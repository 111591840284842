import AuthContext from "../../contexts/AuthContext";
import store from "../../utils/store.js";
import { useContext } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { toast } from "react-toastify";

const useProfile = () => {
  const { get, patch, loading, errorResponse } = useAPI();
  const { setUser } = useContext(AuthContext);

  const restoreLocalUser = () => {
    let profile = store.getProfile();
    if (!profile) return null;
    return profile;
  };

  const restoreUser = async () => {
    if (!store.getTokens()?.accessToken) return;
    const { data, error } = await get(urls.profile.me);
    if (error) return;
    const { profile, brand, supplier, staff } = data;
    setUser({ ...profile, brand, supplier, staff });
  };

  const changeImage = async (image) => {
    const formData = new FormData();
    formData.append("profileImage", image);
    const { error, data } = await patch(urls.profile.image, formData);
    if (error || typeof data === "string") return;
    toast.success(data.message);
    restoreUser();
  };

  const updateMyInfo = async (details, cb) => {
    const { error, data } = await patch(urls.profile.info, details);
    if (error) return;
    toast.success(data.message);
    restoreUser();
  };
  return {
    restoreLocalUser,
    errorResponse,
    updateMyInfo,
    changeImage,
    restoreUser,
    loading,
  };
};

export default useProfile;
