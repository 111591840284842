export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDateTime(date) {
  date = new Date(date); // Ensure `date` is a Date object
  const now = new Date(); // Current date

  // Calculate the difference in milliseconds
  const diffInMs = now - date;
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);

  // Return human-readable time differences
  if (diffInSeconds < 10) {
    return { time: "just now", date: "just now", dateTime: "just now" };
  } else if (diffInSeconds < 60) {
    return {
      time: `${diffInSeconds} seconds ago`,
      date: `${diffInSeconds} seconds ago`,
      dateTime: `${diffInSeconds} seconds ago`,
    };
  } else if (diffInMinutes < 60) {
    return {
      time: `${diffInMinutes} minutes ago`,
      date: `${diffInMinutes} minutes ago`,
      dateTime: `${diffInMinutes} minutes ago`,
    };
  } else if (diffInHours < 6) {
    return {
      time: `${diffInHours} hours ago`,
      date: `${diffInHours} hours ago`,
      dateTime: `${diffInHours} hours ago`,
    };
  }

  // Check if the given date is today or yesterday
  const isToday = date.toDateString() === now.toDateString();
  const isYesterday =
    new Date(now.setDate(now.getDate() - 1)).toDateString() ===
    date.toDateString();

  // Get time in HH:MM format
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Add leading zero to minutes and hours if needed
  if (minutes < 10) minutes = "0" + minutes;
  if (hours < 10) hours = "0" + hours;

  const time = `${hours}:${minutes}`;

  // If the date is today or yesterday, return "Today" or "Yesterday"
  let formattedDate;
  if (isToday) {
    formattedDate = "Today";
  } else if (isYesterday) {
    formattedDate = "Yesterday";
  } else {
    // Otherwise, get date in "Day d M yyyy" format
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    formattedDate = `${day} ${dayOfMonth} ${month} ${year}`;
  }

  return { time, date: formattedDate, dateTime: `${formattedDate} at ${time}` };
}
