import { useEffect } from "react";
import { Form, FormInput, Submit } from "../form";
import Modal from "../Modal";
import { outletSchema } from "../../utils/validators/management";
import useLocations from "../../hooks/api/useLocations";
import Button from "../Button";

function OutletProfile({
  closeModal,
  loading,
  selectedOutlet,
  onSave,
  isOpened,
}) {
  const { locations, loading: loading2, getLocations } = useLocations();

  useEffect(() => {
    getLocations(1, 100, "outlet");
    // eslint-disable-next-line
  }, []);

  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selectedOutlet
          ? `Update: ${selectedOutlet.name}`
          : "Register New Outlet"}
      </h2>
      <br />
      <Form
        onSubmit={onSave}
        validationSchema={outletSchema}
        initialValues={{
          name: selectedOutlet?.name || "",
          location: selectedOutlet?.location?._id || "",
          type: selectedOutlet?.type || "",
        }}
      >
        <div className="inputs">
          <FormInput name="name" placeholder="Name" />
          <FormInput
            select
            options={locations.map((_) => ({ label: _.label, value: _._id }))}
            name="location"
            placeholder="Location"
          />
        </div>
        <br />
        <FormInput
          options={[
            {
              label: "Office",
              value: "office",
            },
            {
              label: "Preproom",
              value: "prep-room",
            },
            {
              label: "Store",
              value: "store",
            },
          ]}
          name="type"
          select
          placeholder="Type"
        />
        <br />
        <div className="d-flex gap-10">
          <Button onClick={closeModal} width={100} title="Cancel" outline />
          <Submit loading={loading || loading2} title="Save" />
        </div>
      </Form>
    </Modal>
  );
}

export default OutletProfile;
