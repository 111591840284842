import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";

const Item = ({
  remove,
  id,
  conceptIngredient,
  instruction,
  quantity,
  handleChange,
  list = [],
  extras = [],
  conceptExtra,
}) => {
  const options = [
    ...list.map((_) => ({
      label: _.name,
      value: _._id,
      unit: _.unitOfMeasure,
    })),
    ...(extras.length ? extras : []),
  ];
  return (
    <>
      <div className="submit-menu-card">
        <div className="inputs gap-10">
          {/* {extras.length ? (
            <>
              <div className="inputs">
                <Input
                  select
                  options={list.map((_) => ({ label: _.name, value: _._id }))}
                  value={conceptIngredient}
                  placeholder="Ingredient"
                  onChange={(v) => handleChange(id, "conceptIngredient", v)}
                />
                <Input
                  select
                  options={extras}
                  value={conceptExtra}
                  placeholder="Select Extra"
                  onChange={(v) => handleChange(id, "conceptExtra", v)}
                />
              </div>
              <br />
            </>
          ) : (
          
          )} */}
          <Input
            select
            options={options}
            value={conceptIngredient || conceptExtra}
            placeholder="Ingredient"
            onChange={(v) => {
              if (extras.length) {
                if (extras.find((_) => _.value === v)) {
                  handleChange(id, "conceptIngredient", null);
                  handleChange(id, "conceptExtra", v);
                } else {
                  handleChange(id, "conceptIngredient", v);
                  handleChange(id, "conceptExtra", null);
                }
              } else {
                handleChange(id, "conceptIngredient", v);
              }
            }}
          />

          <Input
            type="number"
            value={quantity}
            placeholder="Quantity"
            onChange={(v) => handleChange(id, "quantity", v)}
          />
          <div
            style={{
              width: 60,
              height: 48,
              borderRadius: 3,
            }}
            className="fs-12 f-700 input-unset text-brown d-flex justify-center align-center"
          >
            {
              options.find(
                (_) => _.value === conceptExtra || _.value === conceptIngredient
              )?.unit
            }
          </div>
        </div>
        <br />
        <Input
          textarea
          value={instruction}
          placeholder="Instruction"
          onChange={(v) => handleChange(id, "instruction", v)}
        />
        <br />
        <Button
          outline
          style={{ marginLeft: "auto" }}
          onClick={remove}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function Recipe({ list, initialRecipe = [], handleChange, extras, menu = [] }) {
  const [recipe, setRecipe] = useState(initialRecipe);

  useEffect(() => {
    handleChange(recipe);
    // eslint-disable-next-line
  }, [recipe]);

  return (
    <div>
      <span className="fs-14 d-block text-brown f-700">Recipe</span>
      {menu.length ? (
        <>
          <br />
          <Input
            select
            onChange={(v) => {
              const _recipe = menu.find((_) => _.id === parseInt(v))?.recipe;
              if (_recipe) setRecipe(_recipe);
            }}
            options={menu
              .filter((_) => _.recipe?.length)
              .map((_) => ({ label: _.name, value: _.id }))}
            placeholder="Import From Other Menu"
          />
        </>
      ) : null}
      <br />
      <Group
        items={recipe}
        setItems={setRecipe}
        Element={Item}
        AddElement={AddButton}
        list={list}
        extras={extras}
      />
    </div>
  );
}

export default Recipe;
