import ProductCategories from "../pages/app/materials/ProductCategories";
import MaterialsContext from "../contexts/MaterialsContext";
import Dashboard from "../pages/app/materials/Dashboard";
import useInventory from "../hooks/api/useInventory";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import { useEffect } from "react";
import ViewMaterial from "../pages/app/materials/ViewMaterial";
import "../css/pages/materials.css";
import EditMaterial from "../pages/app/materials/EditMaterial";

function Materials(props) {
  const { categories, getCategories, loading, addCategory, updateCategory } =
    useInventory();

  useEffect(() => {
    getCategories(1, 100);

    // eslint-disable-next-line
  }, []);

  return (
    <MaterialsContext.Provider
      value={{
        categories,
        loading,
        getCategories,
        addCategory,
        updateCategory,
      }}
    >
      <Routes>
        <Route path={path.root} element={<Dashboard />} />
        <Route path={path.new} element={<EditMaterial />} />
        <Route path={path.edit} element={<EditMaterial edit />} />
        <Route path={path.details} element={<ViewMaterial />} />
        <Route
          path={path.materials.categories.path}
          element={<ProductCategories />}
        />
      </Routes>
    </MaterialsContext.Provider>
  );
}

export default Materials;
