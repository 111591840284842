import { useContext, useEffect, useState } from "react";
import Status from "../../../components/Status";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import useSupply from "../../../hooks/api/useSupply";
import usePagination from "../../../hooks/usePagination";
import { formatDateTime } from "../../../utils/date";
import { baseStatuses } from "../../../utils/statuses";
import ID from "../../../components/table/ID";
import { path } from "../../../utils/path";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import AppContext from "../../../contexts/AppContext";
import { formatNumber } from "../../../utils/numbers";

const head = [
  {
    title: "ID",
    target: "supplyRequest",
    render: (
      value,
      id = value?._id?.split("").reverse()?.join("")?.slice(0, 6)?.toUpperCase()
    ) => (
      <ID
        link={path.inventory.requests.view.url + `?id=${value?._id}`}
        id={id}
        label="View Request"
      />
    ),
  },
  {
    title: "Item",
    target: ["material.imageUrl", "material.name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Quantity",
    target: ["item.requestedQuantity", "item.supplyUnit"],
    render: (v) => formatNumber(v[0], "") + " " + `(${v[1]})`,
  },
  {
    title: "Supplier",
    target: "supplier.storeName",
    render: (t) => <WithLink text={t} />,
  },
  {
    title: "Requested From",
    target: "origin.name",
    render: (t) => <WithLink text={t} />,
  },
  {
    title: "Sent By",
    target: "requestedBy",
    render: (v) => (
      <WithImage
        imageUrl={v.profileImage}
        text={`${v.firstName} ${v.lastName}`}
      />
    ),
  },
  {
    title: "Request Time",
    target: "supplyRequest.createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  {
    title: "Delivered At",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function Supplies(props) {
  const [selectedSettleResponse, setSelectedSettleResponse] = useState(null);
  const {
    getAllSupplyRequests,
    releaseSupplyPayment,
    totalCount,
    supplyrequests,
    loading,
  } = useSupply();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const { formatCurrency } = useContext(AppContext);
  useEffect(() => {
    getAllSupplyRequests(pageNumber, limit);
  }, [limit, pageNumber]);

  return (
    <div>
      <Modal isVisible={selectedSettleResponse}>
        <h2 className="fs-24 raleway f-700 text-primary">
          Settle Supply Payment
        </h2>

        <br />
        <p>
          Release payment of{" "}
          <strong style={{ color: "var(--alerts-info)" }}>
            {formatCurrency(selectedSettleResponse?.cost)}
          </strong>{" "}
          to{" "}
          <strong style={{ color: "var(--alerts-danger)" }}>
            {selectedSettleResponse?.supplier?.storeName}
          </strong>{" "}
          for supply:{" "}
          <strong>
            {selectedSettleResponse?.item?.requestedQuantity} (
            {selectedSettleResponse?.item?.supplyUnit})
          </strong>{" "}
          of <strong>{selectedSettleResponse?.item?.material?.name}</strong>?
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            onClick={() => setSelectedSettleResponse(null)}
            width={100}
            title="Cancel"
            outline
          />
          <Button
            onClick={() =>
              releaseSupplyPayment(selectedSettleResponse?._id, () =>
                getAllSupplyRequests(pageNumber, limit)
              )
            }
            loading={loading}
            title="Continue"
          />
        </div>
      </Modal>
      <Table
        data={supplyrequests}
        onPaginate={setPageNumber}
        limit={limit}
        totalCount={totalCount}
        loading={loading}
        head={[
          ...head,
          {
            title: "Payment",
            target: "*",
            render: (v) => (
              <Button
                bg="info"
                width={130}
                onClick={() => {
                  setSelectedSettleResponse(v);
                }}
                height={40}
                borderRadius={3}
                fontSize="fs-12"
                disabled={v.paymentReleased}
                title="Release Payment"
              />
            ),
          },
        ]}
        title="Prep & Raw Material Supplies"
      />
    </div>
  );
}

export default Supplies;

const a = {
  _id: "673ddcfb616ead1f96677e79",
  supplyRequest: {
    _id: "672fad82329895459adaf0f2",
    createdAt: "2024-11-09T18:44:18.329Z",
    id: "672fad82329895459adaf0f2",
  },
  item: {
    _id: "672fad82329895459adaf0f4",
    material: {
      _id: "66f14af31dbb9e57ec5461c5",
      name: "White Rice",
      imageUrl: "6b876871d86739beacdd5976d40f339a.jpeg",
      id: "66f14af31dbb9e57ec5461c5",
    },
    requestedQuantity: 1,
    supplyUnit: "25kgb",
    priority: "high",
    status: "delivered",
  },
  requestedBy: {
    _id: "66c5f4a342020b0679eeb2ce",
    firstName: "John",
    lastName: "Store",
    phoneNumber: "+2349022314974",
  },
  origin: {
    imageUrl: null,
    _id: "66c860737e8bf47d58d5b93a",
    name: "Main Store",
    id: "66c860737e8bf47d58d5b93a",
  },
  supplier: {
    _id: "672e17f637edb5522c45287f",
    storeName: "iCart Supplier",
    logo: "781f3e09c40f610de6e24f7ba19e0d64.jpg",
  },
  paymentReleased: false,
  paidOn: null,
  costStamp: 1,
  cost: 1,
  createdAt: "2024-11-20T12:58:35.116Z",
  updatedAt: "2024-11-20T12:58:35.116Z",
  __v: 0,
};
