import { useEffect, useState } from "react";
import Status from "../../../components/Status";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithLink from "../../../components/table/WithLink";
import useDepartments from "../../../hooks/api/useDepartments";
import usePagination from "../../../hooks/usePagination";
import { accountStatuses } from "../../../utils/statuses";
import PlusIcon from "../../../assets/icons/PlusIcon";
import useModal from "../../../hooks/useModal";
import DepartmentProfile from "../../../components/account/DepartmentProfile";
import useRoles from "../../../hooks/api/useRoles";

const tableHead = [
  {
    target: "#",
    title: "#",
  },
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Code",
    target: "_id",
    render: (id) => id.split("").reverse().join("").slice(0, 6).toUpperCase(),
  },
  {
    title: "Staffs",
    target: "staffs",
  },
  {
    title: "Permissions & Access",
    target: "permissions",
    render: (v = []) => (
      <div className="d-flex flex-wrap">
        {v.map((_) => (
          <WithLink text={_} />
        ))}
      </div>
    ),
  },
  {
    title: "Status",
    target: "status",
    render: (v) => {
      return (
        <Status
          text={v}
          light
          className={v === "deleted" ? "disabled" : accountStatuses[v]}
        />
      );
    },
  },
];

function Departments(props) {
  const {
    getAllDepartments,
    updateDepartment,
    departments,
    createDepartment,
    loading,
  } = useDepartments();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { openModal, closeModal, isOpened } = useModal();
  const {
    getPermissions,
    permissions,
    loading: permissionLoading,
  } = useRoles();
  const refresh = () => getAllDepartments(pageNumber, limit);

  const handleSaveNew = (data) => {
    const cb = () => {
      refresh();
      closeModal();
      setSelectedDepartment(null);
    };
    if (selectedDepartment) updateDepartment(selectedDepartment._id, data, cb);
    else createDepartment(data);
  };

  useEffect(() => {
    refresh();
    getPermissions();

    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <div>
      <DepartmentProfile
        selectedDepartment={selectedDepartment}
        permissions={permissions}
        handleSave={handleSaveNew}
        isOpened={isOpened}
        closeModal={closeModal}
      />
      <Table
        btn1={{
          icon: <PlusIcon />,
          title: "Add New Department",
          onClick: openModal,
        }}
        data={departments}
        head={[
          ...tableHead,
          {
            title: "Actions",
            target: "*",
            render: (v) => (
              <View
                onClick={() => {
                  setSelectedDepartment(v);
                  openModal();
                }}
              />
            ),
          },
        ]}
        limit={limit}
        onPaginate={setPageNumber}
        loading={loading || permissionLoading}
        totalCount={20}
        title="Working Departments"
      />
    </div>
  );
}

export default Departments;
