import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithLink from "../../../components/table/WithLink";
import statuses from "../../../utils/statuses";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import useModal from "../../../hooks/useModal";
import CityProfile from "../../../components/management/CityProfile";

const tableHead = [
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Country",
    target: "country.name",
  },
  {
    title: "Active iCarts",
    target: "icarts",
    render: (v = 0) => (
      <span className="d-flex justify-center align-center">{v}</span>
    ),
  },
  {
    title: "Total iCarts",
    target: "icarts",
    render: (v = 0) => (
      <span className="d-flex justify-center align-center">{v}</span>
    ),
  },
  {
    title: "Locations",
    target: "locations",
    render: (v = 0) => (
      <span className="d-flex justify-center align-center">{v}</span>
    ),
  },
  {
    title: "Prep Rooms",
    target: "kitchens",
    render: (v = 0) => (
      <span className="d-flex justify-center align-center">{v}</span>
    ),
  },
  {
    title: "Coordinates",
    target: "coords",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        className={v === "active" ? statuses.active : statuses.inactive}
        text={v}
      />
    ),
  },
  {
    title: "Actions",
    target: "code",
    render: (v) => <View />,
  },
];

function Cities(props) {
  const { cities, loading } = useContext(AppContext);
  const [selected, setSelected] = useState(null);
  const { closeModal, openModal, isOpened } = useModal();

  return (
    <div>
      <CityProfile
        selected={selected}
        isOpened={isOpened}
        closeModal={(v) => {
          setSelected(null);
          closeModal();
        }}
      />
      <Table
        btn1={{
          icon: <PlusIcon />,
          title: "Add New City",
          onClick: openModal,
        }}
        data={cities}
        head={[
          { title: "#", target: "#" },
          {
            title: "Code",
            target: "*",
            render: (v) => (
              <ID
                id={v.code}
                onClick={() => {
                  setSelected(v);
                  openModal();
                }}
                label="Edit Details"
              />
            ),
          },
          ...tableHead,
        ]}
        loading={loading}
        title="Operating Cities"
      />
    </div>
  );
}

export default Cities;
