import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import statuses, { baseStatuses } from "../../../utils/statuses";
import { useEffect, useState } from "react";
import useBrand from "../../../hooks/api/useBrand";
import usePagination from "../../../hooks/usePagination";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import useQuery from "../../../hooks/useQuery";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Code",
    target: "_id",
    render: (id) => (
      <ID
        id={id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="Edit Brand"
      />
    ),
  },
  {
    title: "Name",
    target: ["name", "logo"],
    render: (values) => <WithImage text={values[0]} imageUrl={values[1]} />,
  },
  {
    title: "Creator",
    target: ["creator.firstName", "creator.lastName", "creator.profileImage"],
    render: (values) => (
      <WithImage text={`${values[0]} ${values[1]}`} imageUrl={values[2]} />
    ),
  },
  {
    title: "Approved",
    target: "approved",
    render: (v) => (
      <Status light text={v?.toString()} className={baseStatuses[v]} />
    ),
  },
  {
    title: "Status",
    target: "status",
    render: (v) => <Status light className={statuses[v]} text={v} />,
  },
];

function Brands(props) {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const { getAllBrands, allBrands, loading, updateBrandStatus, approveBrand } =
    useBrand();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const query = useQuery();

  const refresh = () => {
    getAllBrands(pageNumber, limit, query.get("approved"));
    setSelectedBrand(null);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [pageNumber, limit, query.get("approved")]);

  return (
    <div>
      <Modal
        closeModal={() => setSelectedBrand(null)}
        isVisible={selectedBrand}
      >
        <h2 className="fs-24 raleway f-700 text-primary">
          {selectedBrand?.approved
            ? selectedBrand?.status === "active"
              ? "Deactivate"
              : "Activate"
            : "Approve"}{" "}
          Brand
        </h2>
        <br />
        <p>
          {selectedBrand?.approved
            ? selectedBrand?.status === "active"
              ? "Deactivate"
              : "Activate"
            : "Approve"}{" "}
          <strong>{selectedBrand?.name}</strong>
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            outline
            width={120}
            title="Cancel"
            onClick={() => setSelectedBrand(null)}
          />
          <Button
            loading={loading}
            onClick={() =>
              !selectedBrand?.approved
                ? approveBrand(selectedBrand?._id, refresh)
                : updateBrandStatus(
                    selectedBrand?._id,
                    selectedBrand?.status === "active" ? "inactive" : "active",
                    refresh
                  )
            }
            title="Continue"
          />
        </div>
      </Modal>
      <Table
        loading={loading}
        limit={limit}
        totalCount={203}
        title="Brands"
        head={[
          ...tableHead,
          {
            title: "Action",
            target: "*",
            render: (v) => (
              <div className="d-flex gap-10">
                <Button
                  disabled={v.approved}
                  onClick={() => setSelectedBrand(v)}
                  width={150}
                  height={40}
                  fontSize="fs-12"
                  className="bg-info"
                  title="Approve Brand"
                />
                <Button
                  disabled={!v.approved}
                  onClick={() => setSelectedBrand(v)}
                  width={150}
                  height={40}
                  fontSize="fs-12"
                  className={v.status === "inactive" ? "bg-info" : "bg-danger"}
                  title={`${
                    v.status === "inactive" ? "Activate" : "Deactivate"
                  } Brand`}
                />
              </div>
            ),
          },
        ]}
        data={allBrands}
        onPaginate={setPageNumber}
      />
    </div>
  );
}

export default Brands;
