import { objectToFormData } from "../../utils/array";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";

function useConcepts(props) {
  const [conceptDetails, setConceptDetails] = useState(null);
  const [allConcepts, setAllConcepts] = useState([]);
  const [totalCount, setTototalCount] = useState(0);
  const [concept, setConcept] = useState(null);
  // eslint-disable-next-line
  const [requests, setRequests] = useState([]);
  // eslint-disable-next-line
  const { loading, post, get, put, patch, fdelete } = useAPI();

  const getConcepts = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.concepts.myConcepts, {
        pageNumber,
        limit,
      })
    );
    if (error) return;
    setRequests(data.concepts);
  };

  const getConceptsDetails = async (id) => {
    // return setConceptDetails({
    //   concept: {
    //     layout: null,
    //     _id: "66f1afdff53c3a8e1affc4c9",
    //     name: "Quick Rice",
    //     brand: {
    //       _id: "66f1af56f53c3a8e1affc4be",
    //       name: "Quick Rice",
    //       description: "Quick Rice by iCart Africa",
    //       creator: {
    //         _id: "67012d6d43f86f822ad3d7d8",
    //         firstName: "iCart",
    //         lastName: "Kitchen",
    //         email: "kitchen@geticart.com",
    //         phoneNumber: "+2349022314980",
    //       },
    //       logo: "43a8a57a7c95785e1339a955759ce195.jpeg",
    //     },
    //     creator: "67012d6d43f86f822ad3d7d8",
    //     active: true,
    //     origin: "Nigeria",
    //     description: "Modernizing Nigerian Fried Rice",
    //     packaging: "66f305f2afd845fa43fc88f5",
    //     packageImages: [],
    //     totalScore: 0,
    //     rating: 0,
    //     percentage: 0,
    //     status: "approved",
    //     testMode: false,
    //     testStart: null,
    //     testEnd: null,
    //     availableRegions: [],
    //     timeTable: [],
    //     createdAt: "2024-09-23T18:13:51.370Z",
    //     updatedAt: "2024-12-02T11:11:18.841Z",
    //     __v: 3,
    //     regions: [
    //       {
    //         city: {
    //           _id: "66c70e800458e736c620c3fd",
    //           name: "Nairobi",
    //           country: "66c70e110458e736c620c3e8",
    //           status: "active",
    //           createdAt: "2024-08-22T10:10:08.240Z",
    //           updatedAt: "2024-08-22T10:10:08.240Z",
    //           __v: 0,
    //         },
    //         activationCost: 8700,
    //         _id: "67406396f39951a55a222895",
    //         active: true,
    //       },
    //       {
    //         city: {
    //           _id: "66c70e2e0458e736c620c3ef",
    //           name: "Abuja",
    //           country: "66c70e0e0458e736c620c3e6",
    //           status: "active",
    //           createdAt: "2024-08-22T10:08:46.663Z",
    //           updatedAt: "2024-10-02T13:10:50.931Z",
    //           __v: 0,
    //           code: "ABJ",
    //         },
    //         activationCost: 450000,
    //         _id: "67406396f39951a55a222896",
    //         active: true,
    //       },
    //     ],
    //     logo: "2b8358a145ae261f9e11b90537744ff4.png",
    //   },
    //   splits: [
    //     {
    //       _id: "674a4f857bec34872bab6986",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       costOfSales: 0,
    //       region: "66c70e2e0458e736c620c3ef",
    //       subscriberProfit: 10,
    //       iCart: 10,
    //       creator: 10,
    //       operator: 10,
    //       createdAt: "2024-11-29T23:34:29.882Z",
    //       updatedAt: "2024-12-02T12:15:29.913Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "674a4f827bec34872bab6975",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       costOfSales: 0,
    //       region: "66c70e800458e736c620c3fd",
    //       subscriberProfit: 10,
    //       iCart: 10,
    //       creator: 10,
    //       operator: 10,
    //       createdAt: "2024-11-29T23:34:26.605Z",
    //       updatedAt: "2024-12-02T12:15:28.085Z",
    //       __v: 0,
    //     },
    //   ],
    //   ingredients: [
    //     {
    //       _id: "66f1b1c4f53c3a8e1affc676",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f14af31dbb9e57ec5461c5",
    //       name: "White Rice",
    //       unitOfMeasure: "g",
    //       createdAt: "2024-09-23T18:21:56.311Z",
    //       updatedAt: "2024-11-29T23:07:19.965Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c4f53c3a8e1affc678",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f15d121dbb9e57ec546326",
    //       name: "Carrots",
    //       unitOfMeasure: "g",
    //       createdAt: "2024-09-23T18:21:56.476Z",
    //       updatedAt: "2024-11-29T23:07:20.210Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c4f53c3a8e1affc67a",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f17177a9614afc43c2ecba",
    //       name: "Red Bell Pepper",
    //       unitOfMeasure: "g",
    //       createdAt: "2024-09-23T18:21:56.619Z",
    //       updatedAt: "2024-11-29T23:07:20.590Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c4f53c3a8e1affc67c",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f171a7a9614afc43c2ecc6",
    //       name: "Green Bell Pepper",
    //       unitOfMeasure: "g",
    //       createdAt: "2024-09-23T18:21:56.762Z",
    //       updatedAt: "2024-11-29T23:07:20.899Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c4f53c3a8e1affc67e",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16c61a9614afc43c2ec27",
    //       name: "Sweet Corn (Can)",
    //       unitOfMeasure: "g",
    //       createdAt: "2024-09-23T18:21:56.905Z",
    //       updatedAt: "2024-11-29T23:07:21.161Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc680",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f15d6b1dbb9e57ec546339",
    //       name: "Cabbage",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:57.048Z",
    //       updatedAt: "2024-11-29T23:07:21.467Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc682",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f156501dbb9e57ec5462b4",
    //       name: "Ginger",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:57.192Z",
    //       updatedAt: "2024-11-29T23:07:21.718Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc684",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f155ba1dbb9e57ec5462a1",
    //       name: "Garlic",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:57.335Z",
    //       updatedAt: "2024-11-29T23:07:22.025Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc686",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f14dac1dbb9e57ec5461ff",
    //       name: "Sunflower Oil",
    //       unitOfMeasure: "L",
    //       createdAt: "2024-09-23T18:21:57.478Z",
    //       updatedAt: "2024-11-29T23:07:22.265Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc688",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16be5a9614afc43c2ec1b",
    //       name: "Sesame Oil",
    //       unitOfMeasure: "ml",
    //       createdAt: "2024-09-23T18:21:57.622Z",
    //       updatedAt: "2024-11-29T23:07:22.537Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc68a",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f14c8f1dbb9e57ec5461ec",
    //       name: "Green Onion",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:57.764Z",
    //       updatedAt: "2024-11-29T23:07:22.873Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c5f53c3a8e1affc68c",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f156c71dbb9e57ec5462c7",
    //       name: "Onion",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:57.907Z",
    //       updatedAt: "2024-11-29T23:07:23.169Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c6f53c3a8e1affc68e",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f153501dbb9e57ec54624b",
    //       name: "Dark Soy Sauce",
    //       unitOfMeasure: "ml",
    //       createdAt: "2024-09-23T18:21:58.050Z",
    //       updatedAt: "2024-11-29T23:07:23.472Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c6f53c3a8e1affc690",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f154021dbb9e57ec54627b",
    //       name: "Light Soy Sauce",
    //       unitOfMeasure: "ml",
    //       createdAt: "2024-09-23T18:21:58.192Z",
    //       updatedAt: "2024-11-29T23:07:23.770Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c6f53c3a8e1affc692",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f14f1e1dbb9e57ec546237",
    //       name: "Oyster Sauce",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:58.335Z",
    //       updatedAt: "2024-11-29T23:07:24.175Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c6f53c3a8e1affc694",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16887a9614afc43c2ebc3",
    //       name: "White Pepper",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-23T18:21:58.478Z",
    //       updatedAt: "2024-11-29T23:07:24.481Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f1b1c6f53c3a8e1affc696",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f15b191dbb9e57ec546300",
    //       name: "Chicken Sausage",
    //       unitOfMeasure: "unit",
    //       createdAt: "2024-09-23T18:21:58.622Z",
    //       updatedAt: "2024-11-29T23:07:24.788Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f303d6afd845fa43fc8798",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f302f5afd845fa43fc8683",
    //       name: "Vegetable Oil",
    //       unitOfMeasure: "ml",
    //       createdAt: "2024-09-24T18:24:22.598Z",
    //       updatedAt: "2024-11-29T23:07:25.096Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f30a5fafd845fa43fc8e5d",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f14bd81dbb9e57ec5461d9",
    //       name: "Egg",
    //       unitOfMeasure: "unit",
    //       createdAt: "2024-09-24T18:52:15.660Z",
    //       updatedAt: "2024-11-29T23:07:25.403Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41aa3afd845fa43fc994a",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f15cb71dbb9e57ec546313",
    //       name: "Chicken Breast",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:13:55.914Z",
    //       updatedAt: "2024-11-29T23:07:25.711Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41aa4afd845fa43fc994c",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f15a7f1dbb9e57ec5462ed",
    //       name: "Beef",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:13:56.057Z",
    //       updatedAt: "2024-11-29T23:07:25.953Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41efaafd845fa43fc9e30",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16b6ba9614afc43c2ec0f",
    //       name: "Coriander Powder",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:32:26.898Z",
    //       updatedAt: "2024-11-29T23:07:26.185Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41efbafd845fa43fc9e32",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16694a9614afc43c2eba3",
    //       name: "Knorr Seasoning Powder",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:32:27.041Z",
    //       updatedAt: "2024-11-29T23:07:26.530Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41efbafd845fa43fc9e34",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f41e5dafd845fa43fc9db9",
    //       name: "Dried Thyme",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:32:27.183Z",
    //       updatedAt: "2024-11-29T23:07:26.837Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f41efbafd845fa43fc9e36",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16045cfda2f7315522e70",
    //       name: "Corn Starch",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T14:32:27.325Z",
    //       updatedAt: "2024-11-29T23:07:27.144Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f4288aafd845fa43fca4d3",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f42850afd845fa43fca48a",
    //       name: "Maggi Chicken Seasoning",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T15:13:14.082Z",
    //       updatedAt: "2024-11-29T23:07:27.453Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f4331fafd845fa43fcb0bb",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f43301afd845fa43fcb08e",
    //       name: "Sugar",
    //       unitOfMeasure: "grams",
    //       createdAt: "2024-09-25T15:58:23.415Z",
    //       updatedAt: "2024-11-29T23:07:27.697Z",
    //       __v: 0,
    //     },
    //     {
    //       _id: "66f436e8afd845fa43fcb49c",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       material: "66f16aaaa9614afc43c2ec03",
    //       name: "Cumin Powder",
    //       unitOfMeasure: "unit",
    //       createdAt: "2024-09-25T16:14:32.310Z",
    //       updatedAt: "2024-11-29T23:07:27.972Z",
    //       __v: 0,
    //     },
    //   ],
    //   machineries: [
    //     {
    //       _id: "66f1b423f53c3a8e1affc6bc",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       name: "Wok Pan",
    //       model: "standard",
    //       maintanance: [],
    //       createdAt: "2024-09-23T18:32:03.536Z",
    //       updatedAt: "2024-11-29T15:01:02.421Z",
    //       __v: 0,
    //     },
    //   ],
    //   extras: [
    //     {
    //       material: null,
    //       _id: "66f4351aafd845fa43fcb330",
    //       name: "Marinated Chicken",
    //       description: "Marinated Chicken",
    //       unitOfMeasure: "grams",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       recipe: [
    //         {
    //           conceptIngredient: "66f41aa3afd845fa43fc994a",
    //           quantity: 300,
    //           instruction: "Wash, Dice and put into a bowl",
    //           _id: "66f437b1afd845fa43fcb65b",
    //         },
    //         {
    //           conceptIngredient: "66f41efaafd845fa43fc9e30",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb65c",
    //         },
    //         {
    //           conceptIngredient: "66f4288aafd845fa43fca4d3",
    //           quantity: 4,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb65d",
    //         },
    //         {
    //           conceptIngredient: "66f41efbafd845fa43fc9e34",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb65e",
    //         },
    //         {
    //           conceptIngredient: "66f41efbafd845fa43fc9e36",
    //           quantity: 4,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb65f",
    //         },
    //         {
    //           conceptIngredient: "66f30a5fafd845fa43fc8e5d",
    //           quantity: 0.25,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb660",
    //         },
    //         {
    //           conceptIngredient: "66f303d6afd845fa43fc8798",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb661",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c5f53c3a8e1affc684",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb662",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c5f53c3a8e1affc682",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "66f437b1afd845fa43fcb663",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c6f53c3a8e1affc694",
    //           quantity: 0.3,
    //           instruction: "Add into the bowl and mix",
    //           _id: "66f437b1afd845fa43fcb664",
    //         },
    //       ],
    //       output: 300,
    //       type: "mixture",
    //       createdAt: "2024-09-25T16:06:50.660Z",
    //       updatedAt: "2024-09-25T16:17:53.930Z",
    //       __v: 0,
    //     },
    //     {
    //       material: null,
    //       _id: "66f4376bafd845fa43fcb541",
    //       name: "Marinated Beef",
    //       description: "Marinated Beef",
    //       unitOfMeasure: "g",
    //       concept: "66f1afdff53c3a8e1affc4c9",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       recipe: [
    //         {
    //           conceptIngredient: "66f41aa4afd845fa43fc994c",
    //           quantity: 300,
    //           instruction: "Wash, cut into small strips and put into a bowl",
    //           _id: "674a491bfc0b450ee349f95b",
    //         },
    //         {
    //           conceptIngredient: "66f436e8afd845fa43fcb49c",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f95c",
    //         },
    //         {
    //           conceptIngredient: "66f4288aafd845fa43fca4d3",
    //           quantity: 4,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f95d",
    //         },
    //         {
    //           conceptIngredient: "66f41efbafd845fa43fc9e34",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f95e",
    //         },
    //         {
    //           conceptIngredient: "66f41efbafd845fa43fc9e36",
    //           quantity: 8,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f95f",
    //         },
    //         {
    //           conceptIngredient: "66f30a5fafd845fa43fc8e5d",
    //           quantity: 0.5,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f960",
    //         },
    //         {
    //           conceptIngredient: "66f303d6afd845fa43fc8798",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f961",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c5f53c3a8e1affc684",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f962",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c5f53c3a8e1affc682",
    //           quantity: 2,
    //           instruction: "Add into the bowl",
    //           _id: "674a491bfc0b450ee349f963",
    //         },
    //         {
    //           conceptIngredient: "66f1b1c6f53c3a8e1affc694",
    //           quantity: 0.3,
    //           instruction: "Add into the bowl and mix",
    //           _id: "674a491bfc0b450ee349f964",
    //         },
    //       ],
    //       output: 300,
    //       type: "mixture",
    //       createdAt: "2024-09-25T16:16:43.929Z",
    //       updatedAt: "2024-11-29T23:07:07.040Z",
    //       __v: 0,
    //     },
    //   ],
    //   menu: [
    //     {
    //       countPerPackage: 1,
    //       _id: "66f438dbafd845fa43fcba1b",
    //       name: "Chicken Stir Fried Rice",
    //       description: "Chicken Stir Fried Rice",
    //       concept: {
    //         layout: null,
    //         _id: "66f1afdff53c3a8e1affc4c9",
    //         name: "Quick Rice",
    //         brand: "66f1af56f53c3a8e1affc4be",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         active: true,
    //         origin: "Nigeria",
    //         description: "Modernizing Nigerian Fried Rice",
    //         packaging: "66f305f2afd845fa43fc88f5",
    //         packageImages: [],
    //         totalScore: 0,
    //         rating: 0,
    //         percentage: 0,
    //         status: "approved",
    //         testMode: false,
    //         testStart: null,
    //         testEnd: null,
    //         availableRegions: [],
    //         timeTable: [],
    //         createdAt: "2024-09-23T18:13:51.370Z",
    //         updatedAt: "2024-12-02T11:11:18.841Z",
    //         __v: 3,
    //         regions: [
    //           {
    //             city: "66c70e800458e736c620c3fd",
    //             activationCost: 8700,
    //             _id: "67406396f39951a55a222895",
    //             active: true,
    //           },
    //           {
    //             city: "66c70e2e0458e736c620c3ef",
    //             activationCost: 450000,
    //             _id: "67406396f39951a55a222896",
    //             active: true,
    //           },
    //         ],
    //         logo: "2b8358a145ae261f9e11b90537744ff4.png",
    //       },
    //       brand: {
    //         _id: "66f1af56f53c3a8e1affc4be",
    //         name: "Quick Rice",
    //         description: "Quick Rice by iCart Africa",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         approved: true,
    //         status: "active",
    //         logo: "43a8a57a7c95785e1339a955759ce195.jpeg",
    //         createdAt: "2024-09-23T18:11:36.377Z",
    //         updatedAt: "2024-09-30T12:46:28.297Z",
    //         __v: 0,
    //       },
    //       bestEatingTime: "lunch",
    //       bestServeTo: "",
    //       expiring: "",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       creator: "67012d6d43f86f822ad3d7d8",
    //       machineries: [
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: false,
    //           _id: "674a4891fc0b450ee349f5f9",
    //         },
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: true,
    //           _id: "674a4891fc0b450ee349f5fa",
    //         },
    //       ],
    //       recipe: [
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Heat on a wok pan",
    //           _id: "674a4891fc0b450ee349f5fb",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68c",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156c71dbb9e57ec5462c7",
    //             name: "Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.907Z",
    //             updatedAt: "2024-11-29T23:07:23.169Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 36,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f5fc",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 13,
    //           instruction: "Minced the head and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f5fd",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc682",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156501dbb9e57ec5462b4",
    //             name: "Ginger",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.192Z",
    //             updatedAt: "2024-11-29T23:07:21.718Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f5fe",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc684",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f155ba1dbb9e57ec5462a1",
    //             name: "Garlic",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.335Z",
    //             updatedAt: "2024-11-29T23:07:22.025Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f5ff",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc678",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15d121dbb9e57ec546326",
    //             name: "Carrots",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.476Z",
    //             updatedAt: "2024-11-29T23:07:20.210Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 20,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f600",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc67a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f17177a9614afc43c2ecba",
    //             name: "Red Bell Pepper",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.619Z",
    //             updatedAt: "2024-11-29T23:07:20.590Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 18,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f601",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc694",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16887a9614afc43c2ebc3",
    //             name: "White Pepper",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.478Z",
    //             updatedAt: "2024-11-29T23:07:24.481Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f602",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f4288aafd845fa43fca4d3",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f42850afd845fa43fca48a",
    //             name: "Maggi Chicken Seasoning",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-25T15:13:14.082Z",
    //             updatedAt: "2024-11-29T23:07:27.453Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 4,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f603",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc676",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14af31dbb9e57ec5461c5",
    //             name: "White Rice",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.311Z",
    //             updatedAt: "2024-11-29T23:07:19.965Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 200,
    //           instruction: "Cook and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f604",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc690",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f154021dbb9e57ec54627b",
    //             name: "Light Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.192Z",
    //             updatedAt: "2024-11-29T23:07:23.770Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f605",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc692",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14f1e1dbb9e57ec546237",
    //             name: "Oyster Sauce",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.335Z",
    //             updatedAt: "2024-11-29T23:07:24.175Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 8,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f606",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Chopped the green part and add into the wok pan",
    //           _id: "674a4891fc0b450ee349f607",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f4331fafd845fa43fcb0bb",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f43301afd845fa43fcb08e",
    //             name: "Sugar",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-25T15:58:23.415Z",
    //             updatedAt: "2024-11-29T23:07:27.697Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4891fc0b450ee349f608",
    //         },
    //         {
    //           conceptIngredient: null,
    //           conceptExtra: {
    //             material: null,
    //             _id: "66f4351aafd845fa43fcb330",
    //             name: "Marinated Chicken",
    //             description: "Marinated Chicken",
    //             unitOfMeasure: "grams",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             images: [],
    //             active: false,
    //             approved: false,
    //             recipe: [
    //               {
    //                 conceptIngredient: "66f41aa3afd845fa43fc994a",
    //                 quantity: 300,
    //                 instruction: "Wash, Dice and put into a bowl",
    //                 _id: "66f437b1afd845fa43fcb65b",
    //               },
    //               {
    //                 conceptIngredient: "66f41efaafd845fa43fc9e30",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb65c",
    //               },
    //               {
    //                 conceptIngredient: "66f4288aafd845fa43fca4d3",
    //                 quantity: 4,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb65d",
    //               },
    //               {
    //                 conceptIngredient: "66f41efbafd845fa43fc9e34",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb65e",
    //               },
    //               {
    //                 conceptIngredient: "66f41efbafd845fa43fc9e36",
    //                 quantity: 4,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb65f",
    //               },
    //               {
    //                 conceptIngredient: "66f30a5fafd845fa43fc8e5d",
    //                 quantity: 0.25,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb660",
    //               },
    //               {
    //                 conceptIngredient: "66f303d6afd845fa43fc8798",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb661",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c5f53c3a8e1affc684",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb662",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c5f53c3a8e1affc682",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "66f437b1afd845fa43fcb663",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c6f53c3a8e1affc694",
    //                 quantity: 0.3,
    //                 instruction: "Add into the bowl and mix",
    //                 _id: "66f437b1afd845fa43fcb664",
    //               },
    //             ],
    //             output: 300,
    //             type: "mixture",
    //             createdAt: "2024-09-25T16:06:50.660Z",
    //             updatedAt: "2024-09-25T16:17:53.930Z",
    //             __v: 0,
    //           },
    //           inKiosk: false,
    //           quantity: 50,
    //           instruction:
    //             "Add into the wok pan and stir fry for a few seconds",
    //           _id: "674a4891fc0b450ee349f609",
    //         },
    //       ],
    //       extras: [],
    //       output: 1,
    //       prepInstructions: ["Do it well"],
    //       kitchenPrepStorage: "Chill",
    //       prepTiming: 1,
    //       icartPrepStorage: "Chill",
    //       ticketTiming: 10,
    //       prepKioskInstructions: ["Do it well"],
    //       healthScore: [],
    //       createdAt: "2024-09-25T16:22:51.485Z",
    //       updatedAt: "2024-11-29T23:04:49.212Z",
    //       __v: 0,
    //     },
    //     {
    //       countPerPackage: 1,
    //       _id: "66f4326fafd845fa43fcb00d",
    //       name: "Beef Stir Fried Rice",
    //       description: "Beef Stir Fried Rice",
    //       concept: {
    //         layout: null,
    //         _id: "66f1afdff53c3a8e1affc4c9",
    //         name: "Quick Rice",
    //         brand: "66f1af56f53c3a8e1affc4be",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         active: true,
    //         origin: "Nigeria",
    //         description: "Modernizing Nigerian Fried Rice",
    //         packaging: "66f305f2afd845fa43fc88f5",
    //         packageImages: [],
    //         totalScore: 0,
    //         rating: 0,
    //         percentage: 0,
    //         status: "approved",
    //         testMode: false,
    //         testStart: null,
    //         testEnd: null,
    //         availableRegions: [],
    //         timeTable: [],
    //         createdAt: "2024-09-23T18:13:51.370Z",
    //         updatedAt: "2024-12-02T11:11:18.841Z",
    //         __v: 3,
    //         regions: [
    //           {
    //             city: "66c70e800458e736c620c3fd",
    //             activationCost: 8700,
    //             _id: "67406396f39951a55a222895",
    //             active: true,
    //           },
    //           {
    //             city: "66c70e2e0458e736c620c3ef",
    //             activationCost: 450000,
    //             _id: "67406396f39951a55a222896",
    //             active: true,
    //           },
    //         ],
    //         logo: "2b8358a145ae261f9e11b90537744ff4.png",
    //       },
    //       brand: {
    //         _id: "66f1af56f53c3a8e1affc4be",
    //         name: "Quick Rice",
    //         description: "Quick Rice by iCart Africa",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         approved: true,
    //         status: "active",
    //         logo: "43a8a57a7c95785e1339a955759ce195.jpeg",
    //         createdAt: "2024-09-23T18:11:36.377Z",
    //         updatedAt: "2024-09-30T12:46:28.297Z",
    //         __v: 0,
    //       },
    //       bestEatingTime: "",
    //       bestServeTo: "",
    //       expiring: "",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       creator: "67012d6d43f86f822ad3d7d8",
    //       machineries: [
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: false,
    //           _id: "674a4896fc0b450ee349f6d1",
    //         },
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: true,
    //           _id: "674a4896fc0b450ee349f6d2",
    //         },
    //       ],
    //       recipe: [
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Heat on a wok pan",
    //           _id: "674a4896fc0b450ee349f6d3",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68c",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156c71dbb9e57ec5462c7",
    //             name: "Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.907Z",
    //             updatedAt: "2024-11-29T23:07:23.169Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 36,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d4",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 13,
    //           instruction: "Minced the head and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d5",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc682",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156501dbb9e57ec5462b4",
    //             name: "Ginger",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.192Z",
    //             updatedAt: "2024-11-29T23:07:21.718Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d6",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc684",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f155ba1dbb9e57ec5462a1",
    //             name: "Garlic",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.335Z",
    //             updatedAt: "2024-11-29T23:07:22.025Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d7",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc678",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15d121dbb9e57ec546326",
    //             name: "Carrots",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.476Z",
    //             updatedAt: "2024-11-29T23:07:20.210Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 20,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d8",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc67a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f17177a9614afc43c2ecba",
    //             name: "Red Bell Pepper",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.619Z",
    //             updatedAt: "2024-11-29T23:07:20.590Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 18,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6d9",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc694",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16887a9614afc43c2ebc3",
    //             name: "White Pepper",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.478Z",
    //             updatedAt: "2024-11-29T23:07:24.481Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6da",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f4288aafd845fa43fca4d3",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f42850afd845fa43fca48a",
    //             name: "Maggi Chicken Seasoning",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-25T15:13:14.082Z",
    //             updatedAt: "2024-11-29T23:07:27.453Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 4,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6db",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc676",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14af31dbb9e57ec5461c5",
    //             name: "White Rice",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.311Z",
    //             updatedAt: "2024-11-29T23:07:19.965Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 200,
    //           instruction: "Cook and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6dc",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc68e",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f153501dbb9e57ec54624b",
    //             name: "Dark Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.050Z",
    //             updatedAt: "2024-11-29T23:07:23.472Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6dd",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc690",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f154021dbb9e57ec54627b",
    //             name: "Light Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.192Z",
    //             updatedAt: "2024-11-29T23:07:23.770Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6de",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc692",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14f1e1dbb9e57ec546237",
    //             name: "Oyster Sauce",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.335Z",
    //             updatedAt: "2024-11-29T23:07:24.175Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 8,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6df",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Chopped the green part and add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6e0",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f4331fafd845fa43fcb0bb",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f43301afd845fa43fcb08e",
    //             name: "Sugar",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-25T15:58:23.415Z",
    //             updatedAt: "2024-11-29T23:07:27.697Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Add into the wok pan",
    //           _id: "674a4896fc0b450ee349f6e1",
    //         },
    //         {
    //           conceptIngredient: null,
    //           conceptExtra: {
    //             material: null,
    //             _id: "66f4376bafd845fa43fcb541",
    //             name: "Marinated Beef",
    //             description: "Marinated Beef",
    //             unitOfMeasure: "g",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             images: [],
    //             active: false,
    //             approved: false,
    //             recipe: [
    //               {
    //                 conceptIngredient: "66f41aa4afd845fa43fc994c",
    //                 quantity: 300,
    //                 instruction:
    //                   "Wash, cut into small strips and put into a bowl",
    //                 _id: "674a491bfc0b450ee349f95b",
    //               },
    //               {
    //                 conceptIngredient: "66f436e8afd845fa43fcb49c",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f95c",
    //               },
    //               {
    //                 conceptIngredient: "66f4288aafd845fa43fca4d3",
    //                 quantity: 4,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f95d",
    //               },
    //               {
    //                 conceptIngredient: "66f41efbafd845fa43fc9e34",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f95e",
    //               },
    //               {
    //                 conceptIngredient: "66f41efbafd845fa43fc9e36",
    //                 quantity: 8,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f95f",
    //               },
    //               {
    //                 conceptIngredient: "66f30a5fafd845fa43fc8e5d",
    //                 quantity: 0.5,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f960",
    //               },
    //               {
    //                 conceptIngredient: "66f303d6afd845fa43fc8798",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f961",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c5f53c3a8e1affc684",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f962",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c5f53c3a8e1affc682",
    //                 quantity: 2,
    //                 instruction: "Add into the bowl",
    //                 _id: "674a491bfc0b450ee349f963",
    //               },
    //               {
    //                 conceptIngredient: "66f1b1c6f53c3a8e1affc694",
    //                 quantity: 0.3,
    //                 instruction: "Add into the bowl and mix",
    //                 _id: "674a491bfc0b450ee349f964",
    //               },
    //             ],
    //             output: 300,
    //             type: "mixture",
    //             createdAt: "2024-09-25T16:16:43.929Z",
    //             updatedAt: "2024-11-29T23:07:07.040Z",
    //             __v: 0,
    //           },
    //           inKiosk: false,
    //           quantity: 50,
    //           instruction:
    //             "Add into the wok pan and stir fry for a few seconds",
    //           _id: "674a4896fc0b450ee349f6e2",
    //         },
    //       ],
    //       extras: [],
    //       output: 1,
    //       prepInstructions: ["Do it well"],
    //       kitchenPrepStorage: "chill",
    //       prepTiming: 3,
    //       icartPrepStorage: "chill",
    //       ticketTiming: 12,
    //       prepKioskInstructions: ["Do it well"],
    //       healthScore: [],
    //       createdAt: "2024-09-25T15:55:27.782Z",
    //       updatedAt: "2024-11-29T23:04:54.390Z",
    //       __v: 0,
    //     },
    //     {
    //       countPerPackage: 1,
    //       _id: "66f30ac4afd845fa43fc8f44",
    //       name: "Egg Stir Fried Rice",
    //       description: "Egg Stir Fried Rice",
    //       concept: {
    //         layout: null,
    //         _id: "66f1afdff53c3a8e1affc4c9",
    //         name: "Quick Rice",
    //         brand: "66f1af56f53c3a8e1affc4be",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         active: true,
    //         origin: "Nigeria",
    //         description: "Modernizing Nigerian Fried Rice",
    //         packaging: "66f305f2afd845fa43fc88f5",
    //         packageImages: [],
    //         totalScore: 0,
    //         rating: 0,
    //         percentage: 0,
    //         status: "approved",
    //         testMode: false,
    //         testStart: null,
    //         testEnd: null,
    //         availableRegions: [],
    //         timeTable: [],
    //         createdAt: "2024-09-23T18:13:51.370Z",
    //         updatedAt: "2024-12-02T11:11:18.841Z",
    //         __v: 3,
    //         regions: [
    //           {
    //             city: "66c70e800458e736c620c3fd",
    //             activationCost: 8700,
    //             _id: "67406396f39951a55a222895",
    //             active: true,
    //           },
    //           {
    //             city: "66c70e2e0458e736c620c3ef",
    //             activationCost: 450000,
    //             _id: "67406396f39951a55a222896",
    //             active: true,
    //           },
    //         ],
    //         logo: "2b8358a145ae261f9e11b90537744ff4.png",
    //       },
    //       brand: {
    //         _id: "66f1af56f53c3a8e1affc4be",
    //         name: "Quick Rice",
    //         description: "Quick Rice by iCart Africa",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         approved: true,
    //         status: "active",
    //         logo: "43a8a57a7c95785e1339a955759ce195.jpeg",
    //         createdAt: "2024-09-23T18:11:36.377Z",
    //         updatedAt: "2024-09-30T12:46:28.297Z",
    //         __v: 0,
    //       },
    //       bestEatingTime: "lunch",
    //       bestServeTo: "",
    //       expiring: "",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       creator: "67012d6d43f86f822ad3d7d8",
    //       machineries: [
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: false,
    //           _id: "674a489bfc0b450ee349f7b7",
    //         },
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: true,
    //           _id: "674a489bfc0b450ee349f7b8",
    //         },
    //       ],
    //       recipe: [
    //         {
    //           conceptIngredient: {
    //             _id: "66f30a5fafd845fa43fc8e5d",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14bd81dbb9e57ec5461d9",
    //             name: "Egg",
    //             unitOfMeasure: "unit",
    //             createdAt: "2024-09-24T18:52:15.660Z",
    //             updatedAt: "2024-11-29T23:07:25.403Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Crack and whisk",
    //           _id: "674a489bfc0b450ee349f7b9",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 5,
    //           instruction:
    //             "Heat in the wok pan and add the whisked egg fry and set aside",
    //           _id: "674a489bfc0b450ee349f7ba",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Heat in the wok pan",
    //           _id: "674a489bfc0b450ee349f7bb",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68c",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156c71dbb9e57ec5462c7",
    //             name: "Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.907Z",
    //             updatedAt: "2024-11-29T23:07:23.169Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 36,
    //           instruction: "Add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7bc",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 13,
    //           instruction: "Minced the white and add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7bd",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc682",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156501dbb9e57ec5462b4",
    //             name: "Ginger",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.192Z",
    //             updatedAt: "2024-11-29T23:07:21.718Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced the white and add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7be",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc684",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f155ba1dbb9e57ec5462a1",
    //             name: "Garlic",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.335Z",
    //             updatedAt: "2024-11-29T23:07:22.025Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 3,
    //           instruction: "Minced the white and add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7bf",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc678",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15d121dbb9e57ec546326",
    //             name: "Carrots",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.476Z",
    //             updatedAt: "2024-11-29T23:07:20.210Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 20,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c0",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc67a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f17177a9614afc43c2ecba",
    //             name: "Red Bell Pepper",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.619Z",
    //             updatedAt: "2024-11-29T23:07:20.590Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 18,
    //           instruction: "Dice finely and add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c1",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc694",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16887a9614afc43c2ebc3",
    //             name: "White Pepper",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.478Z",
    //             updatedAt: "2024-11-29T23:07:24.481Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c2",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f4288aafd845fa43fca4d3",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f42850afd845fa43fca48a",
    //             name: "Maggi Chicken Seasoning",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-25T15:13:14.082Z",
    //             updatedAt: "2024-11-29T23:07:27.453Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 4,
    //           instruction: "Add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c3",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc676",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14af31dbb9e57ec5461c5",
    //             name: "White Rice",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.311Z",
    //             updatedAt: "2024-11-29T23:07:19.965Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 200,
    //           instruction:
    //             "Cook and add into the wok pan stir fry for a few seconds",
    //           _id: "674a489bfc0b450ee349f7c4",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc690",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f154021dbb9e57ec54627b",
    //             name: "Light Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.192Z",
    //             updatedAt: "2024-11-29T23:07:23.770Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c5",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc690",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f154021dbb9e57ec54627b",
    //             name: "Light Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.192Z",
    //             updatedAt: "2024-11-29T23:07:23.770Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a489bfc0b450ee349f7c6",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc692",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14f1e1dbb9e57ec546237",
    //             name: "Oyster Sauce",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.335Z",
    //             updatedAt: "2024-11-29T23:07:24.175Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 8,
    //           instruction:
    //             "Add into the wok pan and stir fry for a few seconds, and add the egg",
    //           _id: "674a489bfc0b450ee349f7c7",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc688",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16be5a9614afc43c2ec1b",
    //             name: "Sesame Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:57.622Z",
    //             updatedAt: "2024-11-29T23:07:22.537Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 7.5,
    //           instruction: "Add into the wok pan ",
    //           _id: "674a489bfc0b450ee349f7c8",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 13,
    //           instruction:
    //             "Chopped green onion without the head and add into the wok pan and stir fry for a few seconds",
    //           _id: "674a489bfc0b450ee349f7c9",
    //         },
    //       ],
    //       extras: [],
    //       output: 1,
    //       prepInstructions: ["Do it well"],
    //       kitchenPrepStorage: "chill",
    //       prepTiming: 1,
    //       icartPrepStorage: "chill",
    //       ticketTiming: 3,
    //       prepKioskInstructions: ["Do it well"],
    //       healthScore: [],
    //       createdAt: "2024-09-24T18:53:56.344Z",
    //       updatedAt: "2024-11-29T23:04:59.763Z",
    //       __v: 0,
    //     },
    //     {
    //       countPerPackage: 1,
    //       _id: "66f1c0c2f53c3a8e1affc7b3",
    //       name: "Suasage Stir Fried Rice",
    //       description: "Suasage Stir Fried Rice",
    //       concept: {
    //         layout: null,
    //         _id: "66f1afdff53c3a8e1affc4c9",
    //         name: "Quick Rice",
    //         brand: "66f1af56f53c3a8e1affc4be",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         active: true,
    //         origin: "Nigeria",
    //         description: "Modernizing Nigerian Fried Rice",
    //         packaging: "66f305f2afd845fa43fc88f5",
    //         packageImages: [],
    //         totalScore: 0,
    //         rating: 0,
    //         percentage: 0,
    //         status: "approved",
    //         testMode: false,
    //         testStart: null,
    //         testEnd: null,
    //         availableRegions: [],
    //         timeTable: [],
    //         createdAt: "2024-09-23T18:13:51.370Z",
    //         updatedAt: "2024-12-02T11:11:18.841Z",
    //         __v: 3,
    //         regions: [
    //           {
    //             city: "66c70e800458e736c620c3fd",
    //             activationCost: 8700,
    //             _id: "67406396f39951a55a222895",
    //             active: true,
    //           },
    //           {
    //             city: "66c70e2e0458e736c620c3ef",
    //             activationCost: 450000,
    //             _id: "67406396f39951a55a222896",
    //             active: true,
    //           },
    //         ],
    //         logo: "2b8358a145ae261f9e11b90537744ff4.png",
    //       },
    //       brand: {
    //         _id: "66f1af56f53c3a8e1affc4be",
    //         name: "Quick Rice",
    //         description: "Quick Rice by iCart Africa",
    //         creator: "67012d6d43f86f822ad3d7d8",
    //         approved: true,
    //         status: "active",
    //         logo: "43a8a57a7c95785e1339a955759ce195.jpeg",
    //         createdAt: "2024-09-23T18:11:36.377Z",
    //         updatedAt: "2024-09-30T12:46:28.297Z",
    //         __v: 0,
    //       },
    //       bestEatingTime: "lunch",
    //       bestServeTo: "",
    //       expiring: "",
    //       images: [],
    //       active: false,
    //       approved: false,
    //       creator: "67012d6d43f86f822ad3d7d8",
    //       machineries: [
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 1,
    //           inKiosk: false,
    //           _id: "674a48a1fc0b450ee349f888",
    //         },
    //         {
    //           conceptMachine: {
    //             _id: "66f1b423f53c3a8e1affc6bc",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             name: "Wok Pan",
    //             model: "standard",
    //             maintanance: [],
    //             createdAt: "2024-09-23T18:32:03.536Z",
    //             updatedAt: "2024-11-29T15:01:02.421Z",
    //             __v: 0,
    //           },
    //           quantity: 2,
    //           inKiosk: true,
    //           _id: "674a48a1fc0b450ee349f889",
    //         },
    //       ],
    //       recipe: [
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 0.031,
    //           instruction: "Add In a wok pan on high heat",
    //           _id: "674a48a1fc0b450ee349f88a",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68c",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156c71dbb9e57ec5462c7",
    //             name: "Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.907Z",
    //             updatedAt: "2024-11-29T23:07:23.169Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 36,
    //           instruction: "Add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f88b",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc682",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f156501dbb9e57ec5462b4",
    //             name: "Ginger",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.192Z",
    //             updatedAt: "2024-11-29T23:07:21.718Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f88c",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc684",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f155ba1dbb9e57ec5462a1",
    //             name: "Garlic",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.335Z",
    //             updatedAt: "2024-11-29T23:07:22.025Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2,
    //           instruction: "Minced and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f88d",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc678",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15d121dbb9e57ec546326",
    //             name: "Carrots",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.476Z",
    //             updatedAt: "2024-11-29T23:07:20.210Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 20,
    //           instruction: "Chopped and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f88e",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc67a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f17177a9614afc43c2ecba",
    //             name: "Red Bell Pepper",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.619Z",
    //             updatedAt: "2024-11-29T23:07:20.590Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 18,
    //           instruction: "Chopped and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f88f",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 13,
    //           instruction: "Chopped green onion head and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f890",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc67c",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f171a7a9614afc43c2ecc6",
    //             name: "Green Bell Pepper",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.762Z",
    //             updatedAt: "2024-11-29T23:07:20.899Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 0,
    //           instruction: "Chopped and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f891",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f303d6afd845fa43fc8798",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f302f5afd845fa43fc8683",
    //             name: "Vegetable Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-24T18:24:22.598Z",
    //             updatedAt: "2024-11-29T23:07:25.096Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 0.031,
    //           instruction: "Add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f892",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc696",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15b191dbb9e57ec546300",
    //             name: "Chicken Sausage",
    //             unitOfMeasure: "unit",
    //             createdAt: "2024-09-23T18:21:58.622Z",
    //             updatedAt: "2024-11-29T23:07:24.788Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 1,
    //           instruction: "Sliced and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f893",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc680",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f15d6b1dbb9e57ec546339",
    //             name: "Cabbage",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.048Z",
    //             updatedAt: "2024-11-29T23:07:21.467Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 0,
    //           instruction: "Chopped and add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f894",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc694",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16887a9614afc43c2ebc3",
    //             name: "White Pepper",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.478Z",
    //             updatedAt: "2024-11-29T23:07:24.481Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 1,
    //           instruction: "Add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f895",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c4f53c3a8e1affc676",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14af31dbb9e57ec5461c5",
    //             name: "White Rice",
    //             unitOfMeasure: "g",
    //             createdAt: "2024-09-23T18:21:56.311Z",
    //             updatedAt: "2024-11-29T23:07:19.965Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 100,
    //           instruction:
    //             "Add into the wok pan and stir fry for a few seconds",
    //           _id: "674a48a1fc0b450ee349f896",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc68e",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f153501dbb9e57ec54624b",
    //             name: "Dark Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.050Z",
    //             updatedAt: "2024-11-29T23:07:23.472Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f897",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc690",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f154021dbb9e57ec54627b",
    //             name: "Light Soy Sauce",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:58.192Z",
    //             updatedAt: "2024-11-29T23:07:23.770Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction: "Add into the wok pan",
    //           _id: "674a48a1fc0b450ee349f898",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c6f53c3a8e1affc692",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14f1e1dbb9e57ec546237",
    //             name: "Oyster Sauce",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:58.335Z",
    //             updatedAt: "2024-11-29T23:07:24.175Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 8,
    //           instruction:
    //             "Add into the wok pan and stir fry for a few seconds",
    //           _id: "674a48a1fc0b450ee349f899",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc688",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f16be5a9614afc43c2ec1b",
    //             name: "Sesame Oil",
    //             unitOfMeasure: "ml",
    //             createdAt: "2024-09-23T18:21:57.622Z",
    //             updatedAt: "2024-11-29T23:07:22.537Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 2.5,
    //           instruction: "Add into the wok pan ",
    //           _id: "674a48a1fc0b450ee349f89a",
    //         },
    //         {
    //           conceptIngredient: {
    //             _id: "66f1b1c5f53c3a8e1affc68a",
    //             concept: "66f1afdff53c3a8e1affc4c9",
    //             material: "66f14c8f1dbb9e57ec5461ec",
    //             name: "Green Onion",
    //             unitOfMeasure: "grams",
    //             createdAt: "2024-09-23T18:21:57.764Z",
    //             updatedAt: "2024-11-29T23:07:22.873Z",
    //             __v: 0,
    //           },
    //           conceptExtra: null,
    //           inKiosk: false,
    //           quantity: 10,
    //           instruction:
    //             "Chopped green onion without the head and add into the wok pan and stir fry for a few seconds",
    //           _id: "674a48a1fc0b450ee349f89b",
    //         },
    //       ],
    //       extras: [],
    //       output: 1,
    //       prepInstructions: ["Make it quick"],
    //       kitchenPrepStorage: "chill",
    //       prepTiming: 1,
    //       icartPrepStorage: "chill",
    //       ticketTiming: 3,
    //       prepKioskInstructions: ["do it well"],
    //       healthScore: [],
    //       createdAt: "2024-09-23T19:25:54.668Z",
    //       updatedAt: "2024-11-29T23:05:05.040Z",
    //       __v: 0,
    //     },
    //   ],
    // });
    const { data, error } = await get(
      urls.business.concepts.baseUrl + `/${id}`
    );
    if (error) return;
    setConceptDetails(data);
  };

  const getConcept = async (id) => {
    const { data, error } = await get(
      urls.business.concepts.baseUrl + `/${id}`
    );
    if (error) return;
    setConcept(data);
  };

  const submitConcept = async (fdata) => {
    fdata = objectToFormData(fdata);
    const { data, error } = await post(urls.business.concepts.baseUrl, fdata);
    if (error) return null;
    return data;
  };

  const addDataToConcept = async (id, fdata, url, isDelete) => {
    if (!id) return toast.info("Basic Info not Provided!");
    const putUrls = [
      urls.business.concepts.details,
      urls.business.concepts.status,
    ];
    const patchEndpoints = [
      urls.business.concepts.grade,
      urls.business.concepts.regions,
      urls.business.concepts.updateTimetable,
    ];
    const method = isDelete
      ? fdelete
      : patchEndpoints.includes(url)
      ? patch
      : putUrls.includes(url)
      ? put
      : post;
    const { data, error, message } = await method(
      url.replace(":id", id),
      fdata
    );
    if (error) return null;
    await getConcept(id);
    toast.info(message);
    return data;
  };

  const getAllConcepts = async (pageNumber = 1, limit = 10, status) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.concepts.baseUrl, {
        pageNumber,
        limit,
        status,
      })
    );
    if (error) return;
    setAllConcepts(data.concepts);
    setTototalCount(data.pagination?.totoal || 0);
  };

  const toggleConceptActivity = async (id, cb) => {
    const { data, error } = await put(
      urls.business.concepts.activity.replace(":id", id)
    );
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    concept,
    requests,
    getConcept,
    totalCount,
    getConcepts,
    allConcepts,
    submitConcept,
    conceptDetails,
    getAllConcepts,
    addDataToConcept,
    getConceptsDetails,
    toggleConceptActivity,
  };
}
export default useConcepts;
