import { addParamsToUrl } from "../../utils/helpers";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import { toast } from "react-toastify";

function useSuppliers(props) {
  const [suppliers, setSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const { loading, put, get } = useAPI();

  const getSuppliers = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.suppliers.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setSuppliers(data.suppliers);
    setTotal(data.pagination.total);
  };

  const updateStoreStatus = async (id, cb) => {
    const { error, message } = await put(
      urls.business.suppliers.status.replace(":id", id)
    );
    if (error) return;
    toast.success(message);
    if (typeof cb === "function") cb();
  };

  return {
    total,
    loading,
    suppliers,
    getSuppliers,
    updateStoreStatus,
  };
}
export default useSuppliers;
