import * as Yup from "yup";

export const addCategorySchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  description: Yup.string().label("Description"),
});

export const materialSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  category: Yup.string().required().label("Category"),
  unitOfMeasure: Yup.string().required().label("Unit of Measure"),
  tags: Yup.string().required().label("Tags"),
  width: Yup.number().label("Width"),
  height: Yup.number().label("Height"),
  voltage: Yup.number().label("Voltage"),
  current: Yup.number().label("Current"),
  lifeSpanInDays: Yup.number().label("Life Span"),
  model: Yup.string().label("Model"),
  description: Yup.string().required().label("Description"),
});
