import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import "../css/pages/sales.css";
import Dashboard from "../pages/app/business/Dashboard";
import ViewBussiness from "../pages/app/business/ViewBussiness";

function Businesses(props) {
  return (
    <Routes>
      <Route path={path.business.view.path} element={<ViewBussiness />} />
      <Route path={path.all} element={<Dashboard />} />
    </Routes>
  );
}

export default Businesses;
