import { Route, Routes, useNavigate } from "react-router-dom";
import Revenue from "../../../components/charts/Revenue";
import AppContext from "../../../contexts/AppContext";
import Card from "../../../components/Card";
import { path } from "../../../utils/path";
import Tab from "../../../components/Tab";
import Deactivated from "./Deactivated";
import Purchased from "./Purchased";
import { useContext } from "react";
import Active from "./Active";
import New from "./New";

const tableTabs = [
  {
    title: "Active Subscriptions",
    to: path.business.url,
  },
  {
    title: "New Subscriptions",
    to: path.business.new.url,
    badge: "9+",
  },
  {
    title: "Deactivated Subscriptions",
    to: path.business.deactivated.url,
  },
  {
    title: "Purchased iCarts",
    to: path.business.purchased.url,
  },
];

function Dashboard(props) {
  const navigate = useNavigate();
  const handleNavigation = (tab) => navigate(tab.to);
  const { currentCity } = useContext(AppContext);

  return (
    <div>
      <div className="cards d-flex">
        <Card
          title={0}
          className="success-light"
          subTitle="Active Subscriptions"
        />
        <Card
          title={0}
          className="danger-light"
          subTitle="Deactivated Subscriptions"
        />
        <Card title={0} subTitle="Total Subscriptions" />
        <Card title={0} subTitle="Total Purchased" />
      </div>
      <br />
      <div className="d-flex tab-column gap-10 justify-between">
        <div className="full-flex">
          <Revenue random={false} title="Revenue" />
        </div>
        <div
          className="d-flex gap-10 flex-column justify-between"
          style={{
            flex: 0.4,
          }}
        >
          <Card
            title={0}
            symbol={currentCity?.country?.symbol}
            style={{ height: "50%" }}
            subTitle="Yearly Revenue"
          />
          <Card
            title={0}
            symbol={currentCity?.country?.symbol}
            style={{ height: "50%" }}
            subTitle="Total Revenue"
          />
        </div>
      </div>
      <br />
      <div>
        <Tab autoSelect onSwitch={handleNavigation} tabs={tableTabs} />
        <Routes>
          <Route path={path.root} element={<Active />} />
          <Route
            path={path.business.deactivated.root}
            element={<Deactivated />}
          />
          <Route path={path.business.new.root} element={<New />} />
          <Route path={path.business.purchased.root} element={<Purchased />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
