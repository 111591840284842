import Card from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";
import Table from "../../../components/table/Table";
import MaterialRequests from "../../../components/table/withdata/MaterialRequests";
import PrepRequests from "../../../components/table/withdata/PrepRequests";
import Concepts from "../management/Concepts";
import ICarts from "../management/ICarts";

function Dashboard(props) {
  return (
    <div>
      <div className="d-flex tab-column flex-wrap gap-10">
        <div className="full-flex">
          <div className="cards d-flex">
            <Card subTitle="New Requests" />
            <Card subTitle="Delivered Supplies" />
          </div>
          <br />
          <Revenue title="Statistics" />
        </div>
        <div style={{ minWidth: "30%" }}>
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Todo</h2>
            <br />
            {/* <span className="fs-14">You have no todo!</span> */}
            <ul>
              <li style={{ textDecorationLine: "line-through" }}>
                <strong style={{ color: "var(--alerts-warn)" }}>
                  Quick Rice
                </strong>{" "}
                prep to be sent to{" "}
                <strong style={{ color: "var(--alerts-info)" }}>
                  iCRT10002NG
                </strong>{" "}
                on{" "}
                <strong style={{ color: "var(--alerts-success)" }}>
                  Mon July 15 at 09:00am
                </strong>
              </li>
              <li>
                <strong style={{ color: "var(--alerts-warn)" }}>
                  Quick Rice
                </strong>{" "}
                prep to be sent to{" "}
                <strong style={{ color: "var(--alerts-info)" }}>
                  iCRT10003NG
                </strong>{" "}
                on{" "}
                <strong style={{ color: "var(--alerts-success)" }}>
                  Mon July 15 at 11:00am
                </strong>
              </li>
            </ul>
          </div>
          <br />
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Activities</h2>
          </div>
        </div>
      </div>
      <br />
      <div className="d-flex gap-10 flex-wrap">
        <MaterialRequests title="Prep Requests" />
        <div style={{ width: "50%" }} className="full-flex">
          <ICarts title="Active iCarts" hideFooter={true} maxWidth={0.49} />
        </div>
        <div style={{ width: "50%" }} className="full-flex">
          <Concepts title="Active Concepts" hideFooter={true} maxWidth={0.49} />
        </div>
      </div>
      <br />
    </div>
  );
}

export default Dashboard;
