import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import Pen from "../../assets/icons/Pen";
import FilePicker from "../FilePicker";
import Icon from "../Icon";

const Card = ({ title }) => (
  <div className="card">
    <span className="d-block f-700 text-brown">{title}</span>
    <br />
    <FilePicker />
    <br />

    <div className="d-flex align-center justify-end gap-10">
      <Icon>
        <ArrowDownIcon
          style={{ transform: "rotate(-45deg)" }}
          color="var(--main-yellow)"
        />
      </Icon>
      <Icon>
        <Pen style={{ transform: "scale(1.3)" }} />
      </Icon>
    </div>
  </div>
);

function AccountKYC({ account }) {
  return (
    <div>
      <h2 className="text-brown">KYC</h2>
      <br />
      <div className="d-flex gap-10 flex-column">
        <Card title="Identity Document" />
        <Card title="Driving License" />
        <Card title="Vehicle Photography" />
        <Card title="Vehicle Registration" />
      </div>
    </div>
  );
}

export default AccountKYC;
