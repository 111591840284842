import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useAccounts(props) {
  const [pagination, setPagination] = useState({ total: 0 });
  const [allAccounts, setAllAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const { loading, post, get, put, setLoading } = useAPI();

  const getAllAccounts = async (pageNumber = 1, limit = 10, role) => {
    const { data, error } = await get(
      addParamsToUrl(urls.accounts.baseUrl, { pageNumber, limit, role })
    );
    if (error) return;
    setAllAccounts(data.users);
    setPagination(data.pagination);
  };

  const getAccount = async (id) => {
    const { data, error } = await get(urls.accounts.baseUrl + `/${id}`);
    if (error) return;
    setAccount(data.user);
  };

  const updateUser = async (id, fdata, cb) => {
    const { data, error } = await put(urls.accounts.baseUrl + `/${id}`, fdata);
    if (error) return;
    toast.info(data.message);
    await getAccount(id);
    if (typeof cb === "function") cb();
  };

  const searchAccoount = async (searchKey, limit = 20, role) => {
    if (!searchKey) return setAllAccounts([]);
    const { data, error, errorMEssage } = await get(
      addParamsToUrl(urls.accounts.search, { searchKey, limit, role })
    );
    if (error) return toast.warn(errorMEssage);
    setAllAccounts(data);
  };

  const sendNotification = async (message) => {
    try {
      setLoading(true);
      await fetch(urls.accounts.sendNotification, {
        method: "POST",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });
      toast.success("Notification Sent!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    account,
    getAccount,
    updateUser,
    pagination,
    allAccounts,
    setAllAccounts,
    getAllAccounts,
    searchAccoount,
    sendNotification,
  };
}
export default useAccounts;
