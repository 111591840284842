import { addParamsToUrl } from "../../utils/helpers";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import { toast } from "react-toastify";
import store from "../../utils/store";

function useOperations(props) {
  const [totalOrders, setTotalOrders] = useState(0);
  const { loading, patch, post, get, put } = useAPI();
  const [orders, setOrders] = useState([]);

  const getOrders = async (pageNumber = 1, limit = 10, status) => {
    const { data, error } = await get(
      addParamsToUrl(urls.operations.orders, { pageNumber, limit, status })
    );
    if (error || !data.orders) return;
    setOrders(data.orders);
    setTotalOrders(data.pagination.total);
  };

  const getKioskOrders = async (pageNumber = 1, limit = 10, status) => {
    const kioskId = store.getItem("outlet")?.kioskId;
    console.log(store.getItem("outlet"));
    if (!kioskId) return toast.warn("System not configured");
    const { data, error } = await get(
      addParamsToUrl(urls.operations.kioskOrder, {
        pageNumber,
        limit,
        kioskId,
        status,
      })
    );

    if (error || !data.orders) return;
    setOrders(data.orders);
    setTotalOrders(data.pagination.total);
  };

  const acceptOrder = async (id, cb) => {
    const { error, data } = await patch(
      urls.operations.acceptOrder.replace(":id", id)
    );
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };
  const completeOrder = async (id, cb) => {
    const { error, data } = await patch(
      urls.operations.completeOrder.replace(":id", id)
    );
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  return {
    totalOrders,
    loading,
    orders,
    getOrders,
    acceptOrder,
    completeOrder,
    getKioskOrders,
  };
}
export default useOperations;
