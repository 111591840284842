import { useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import urls from "../../api/urls";
import { excludeFromObject } from "../../utils/array";

const Timing = ({
  remove,
  item,
  handleChange,
  id,
  time,
  incharge,
  process,
  placeholder = "Ingredient",
  options = [],
  extras = [],
  ingredients = [],
  conceptIngredient,
  conceptExtra,
}) => {
  const _options = [
    ...(ingredients.length
      ? ingredients.map((_) => ({
          label: _.name,
          value: _._id,
        }))
      : []),
    ...(extras.length
      ? extras.map((_) => ({
          label: _.name,
          value: _._id,
        }))
      : []),
  ];
  return (
    <div className="submit-menu-card">
      <br />
      <br />
      <div className="inputs">
        <Input
          select
          options={_options}
          value={conceptIngredient || conceptExtra}
          placeholder="Item"
          onChange={(v) => {
            if (extras.length) {
              if (extras.find((_) => _.value === v)) {
                handleChange(id, "conceptIngredient", null);
                handleChange(id, "conceptExtra", v);
              } else {
                handleChange(id, "conceptIngredient", v);
                handleChange(id, "conceptExtra", null);
              }
            } else {
              handleChange(id, "conceptIngredient", v);
            }
          }}
        />
        {/* <Input
          value={extras}
          onChange={(v) => handleChange(id, "item", v)}
          placeholder={placeholder}
          select
          options={extras.map((_) => ({ label: _.name, value: _._id }))}
        /> */}
        <Input
          value={process}
          onChange={(v) => handleChange(id, "process", v)}
          placeholder="Process"
        />
      </div>
      <br />
      <div className="inputs">
        <Input
          value={time}
          onChange={(v) => handleChange(id, "time", v)}
          placeholder="Time"
          time
          type="time"
        />
        <Input
          value={incharge}
          onChange={(v) => handleChange(id, "inCharge", v)}
          placeholder="In Charge"
          select
          options={options}
        />
      </div>
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
      <br />
    </div>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function PrepTimeTable({ concept, onFinished, id, loading, addDataToConcept }) {
  const [instructions1, setInstructions1] = useState([{ id: 1 }]);
  const [instructions2, setInstructions2] = useState([{ id: 1 }]);

  const handleSave = async () => {
    const response = await addDataToConcept(
      id,
      {
        timeTable: [
          ...instructions1.map((_) => ({
            ...excludeFromObject(["id"], _),
            type: "prep",
          })),
        ],
      },
      urls.business.concepts.updateTimetable
    );
    const response2 = await addDataToConcept(
      id,
      {
        timeTable: [
          ...instructions1.map((_) => ({
            ...excludeFromObject(["id"], _),
            type: "distribution",
          })),
        ],
      },
      urls.business.concepts.updateTimetable
    );
    if (response && response2) onFinished(response);
  };

  return (
    <div>
      <span className="fs-14 d-block text-brown f-700">Prep Time Table</span>
      <br />
      <Group
        items={instructions1}
        setItems={setInstructions1}
        Element={Timing}
        AddElement={AddButton}
        options={[
          {
            label: "Chefs",
            value: "chefs",
          },
          {
            label: "Kitchen Asistant",
            value: "ka",
          },
        ]}
        extras={concept?.extras}
        ingredients={concept?.ingredients}
      />
      <span className="fs-14 d-block text-brown f-700">
        Distribution Time Table
      </span>
      <br />
      <Group
        items={instructions2}
        setItems={setInstructions2}
        Element={Timing}
        options={[
          {
            label: "Prep Room Driver",
            value: "driver",
          },
          {
            label: "Supervisor",
            value: "supervisor",
          },
        ]}
        placeholder="Item"
        AddElement={AddButton}
        extras={concept?.extras}
        ingredients={concept?.ingredients}
      />
      <br />
      <Button loading={loading} onClick={handleSave} title="Save & Continue" />
    </div>
  );
}

export default PrepTimeTable;
