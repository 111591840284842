import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import statuses from "../../../utils/statuses";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import useModal from "../../../hooks/useModal";
import CountryProfile from "../../../components/management/CountryProfile";
import { formatNumber } from "../../../utils/numbers";

const tableHead = [
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Currency Code",
    target: "currency",
  },
  {
    title: "Currency Symbol",
    target: "symbol",
  },
  {
    title: "VAT",
    target: "vat",
    render: (v) => `${v || 0}%`,
  },
  // {
  //   title: "Coordinates",
  //   target: "coords",
  //   render: (v) => <WithLink text={v} />,
  // },
  // {
  //   title: "Operating Cities",
  //   target: "cities",
  //   render: (v) => (
  //     <span className="d-flex justify-center align-center">{v}</span>
  //   ),
  // },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        className={v === "active" ? statuses.active : statuses.inactive}
        text={v}
      />
    ),
  },
  {
    title: "Actions",
    target: "code",
    render: (v) => <View />,
  },
];

function Countries(props) {
  const { countries, loading } = useContext(AppContext);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { closeModal, openModal, isOpened } = useModal();

  return (
    <div>
      <CountryProfile
        selected={selectedCountry}
        closeModal={(v) => {
          setSelectedCountry(null);
          closeModal();
        }}
        isOpened={isOpened}
      />
      <Table
        loading={loading}
        btn1={{
          icon: <PlusIcon />,
          title: "Add New Country",
          onClick: openModal,
        }}
        data={countries}
        head={[
          { title: "#", target: "#" },
          {
            title: "Code",
            target: "*",
            render: (v) => (
              <ID
                onClick={() => {
                  setSelectedCountry(v);
                  openModal();
                }}
                id={v.code}
                label="Edit Details"
              />
            ),
          },
          {
            title: "iCart Purchase",
            target: "icartPurchaseAmount",
            render: (v) => formatNumber(v || 0, "$"),
          },
          ...tableHead,
        ]}
        title="Operating Countries"
      />
    </div>
  );
}

export default Countries;
