import { countrySchema } from "../../utils/validators/management";
import AppContext from "../../contexts/AppContext";
import { Form, FormInput, Submit } from "../form";
import useConfig from "../../hooks/api/useConfig";
import { useContext, useEffect } from "react";
import Modal from "../Modal";
import FormattedInput from "../FormattedInput";
import Button from "../Button";

function CountryProfile({ closeModal, selected, isOpened }) {
  const { addCountry, updateCountry, loading } = useContext(AppContext);
  const { getAllCountries, loading: loading2, allCountries } = useConfig();

  useEffect(() => {
    getAllCountries();

    // eslint-disable-next-line
  }, []);

  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selected ? `Update ${selected?.name}` : "Register New Country"}
      </h2>
      <br />
      <Form
        onSubmit={(v) => {
          if (selected) updateCountry(selected._id, v, closeModal);
          else addCountry(v, closeModal);
        }}
        validationSchema={countrySchema}
        initialValues={{
          name: selected?.name || "",
          currency: selected?.currency || "",
          symbol: selected?.symbol || "",
          vat: selected?.vat || "",
          code: selected?.code || "",
          icartPurchaseAmount: selected?.icartPurchaseAmount || "",
        }}
      >
        <div className="inputs">
          <FormInput
            select
            listener={(value, setFieldValue) => {
              const country = allCountries.find((_) => _.name === value);
              if (country?.currency) {
                setFieldValue("currency", country.currency.code);
                setFieldValue("symbol", country.currency.symbol);
                setFieldValue("code", country.code);
              }
            }}
            options={allCountries.map((_) => ({
              value: _.name,
              label: _.name,
            }))}
            name="name"
            placeholder="Country Name"
          />
          <FormInput name="code" placeholder="Country Code" />
        </div>
        <br />
        <div className="inputs">
          <FormInput name="currency" placeholder="Currency Code" />
          <FormInput name="symbol" placeholder="Currency Symbol" />
        </div>
        <br />
        <div className="inputs">
          <FormInput
            Wrapper={FormattedInput}
            name="icartPurchaseAmount"
            placeholder="Purchase Amount"
          />
          <FormInput
            name="vat"
            type="number"
            min={1}
            max={100}
            placeholder="VAT Percentage"
          />
        </div>
        <br />
        <div className="d-flex gap-10">
          <Button onClick={closeModal} width={100} title="Cancel" outline />
          <Submit loading={loading || loading2} title="Save" />
        </div>
      </Form>
    </Modal>
  );
}

export default CountryProfile;
