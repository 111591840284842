import { useContext, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import AppContext from "../../contexts/AppContext";
import FormattedInput from "../FormattedInput";
import urls from "../../api/urls";
import Collapsable from "../Collapsable";
import CheckBox from "../CheckBox";
import { excludeFromObject } from "../../utils/array";

const Region = ({
  city,
  menu = [],
  cities,
  id,
  activationCost,
  handleChange,
  split = {},
  _menu = [],
  remove,
  active,
}) => (
  <Collapsable title={cities.find((_) => _._id === city)?.name || "City"}>
    <div className="submit-menu-card">
      <div className="inputs gap-10">
        <Input
          select
          options={cities.map((_) => ({ label: _.name, value: _._id }))}
          value={city}
          placeholder="City"
          onChange={(v) => handleChange(id, "city", v)}
        />
        <FormattedInput
          value={activationCost}
          placeholder="Activation Cost"
          onChange={(v) => handleChange(id, "activationCost", v)}
        />
      </div>
      <br />
      <span className="fs-14 d-block text-brown f-700">
        Sales Splitting Fomular (%)
      </span>
      <br />
      <div className="inputs">
        <Input
          value={split?.subscriberProfit}
          onChange={(v) =>
            handleChange(id, "split", { ...split, subscriberProfit: v })
          }
          placeholder="Subscriber"
          type="number"
          min={1}
          max={100}
        />
        <Input
          value={split?.iCart}
          onChange={(v) => handleChange(id, "split", { ...split, iCart: v })}
          placeholder="iCart Africa"
          type="number"
          min={1}
          max={100}
        />
      </div>
      <br />
      <div className="inputs">
        <Input
          value={split?.creator}
          onChange={(v) => handleChange(id, "split", { ...split, creator: v })}
          placeholder="Creator"
          type="number"
          min={1}
          max={100}
        />
        <Input
          value={split?.operator}
          onChange={(v) => handleChange(id, "split", { ...split, operator: v })}
          placeholder="Operator"
          type="number"
          min={1}
          max={100}
        />
      </div>
      <br />
      <span className="fs-14 d-block text-brown f-700">Menu Markups</span>
      <br />
      {menu.map((_, idx) => (
        <div
          style={{ marginBottom: 20 }}
          className="d-flex align-center gap-10"
          key={idx}
        >
          <div
            style={{ padding: "13px" }}
            className="full-flex fs-14 f-700 input-unset text-brown"
          >
            {_.name}:
          </div>

          <div style={{ width: 150 }}>
            <Input
              value={_menu.find((__) => __.id === _._id)?.markup}
              onChange={(v) => {
                handleChange(id, "_menu", [
                  ..._menu.filter((m) => m.id !== _._id),
                  { id: _._id, markup: v },
                ]);
              }}
              placeholder="Markup"
            />
          </div>
        </div>
      ))}
      <br />
      <span className="fs-14 d-block text-brown f-700">Concept Status</span>
      <br />
      <div className="d-flex align-center">
        <span style={{ marginRight: 10 }}>Rollout: </span>
        <CheckBox
          value={active}
          onChange={(v) => handleChange(id, "active", v)}
        />
      </div>
      <br />
      {/* {menu.map()} */}
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
    </div>
    <br />
  </Collapsable>
);

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add City"
    fontSize="fs-12"
    width={80}
    borderRadius={3}
    height={30}
  />
);

function Activation({ addDataToConcept, concept, loading, onFinished, id }) {
  const [regions, setRegions] = useState(
    concept
      ? concept?.concept?.regions?.map((_, idx) => ({
          id: idx + 1,
          city: _.city._id,
          activationCost: _.activationCost,
        }))
      : []
  );
  const { cities } = useContext(AppContext);

  const handleActivation = async () => {
    const response = await addDataToConcept(
      id,
      {
        regions: regions.map((_) => ({
          ...excludeFromObject(["_menu", "id"], _),
          menu: _._menu,
        })),
      },
      urls.business.concepts.regions
    );
    if (response) onFinished(response);
  };

  return (
    <div>
      <Group
        menu={concept.menu}
        cities={cities}
        AddElement={AddButton}
        items={regions}
        setItems={setRegions}
        Element={Region}
        title="Available Cities"
      />
      <br />
      <Button
        loading={loading}
        onClick={handleActivation}
        title="Active Concept"
        disabled={!regions.length}
      />
    </div>
  );
}

export default Activation;
