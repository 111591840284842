import * as Yup from "yup";

export const countrySchema = Yup.object().shape({
  name: Yup.string().required(),
  currency: Yup.string().required(),
  symbol: Yup.string().required(),
  code: Yup.string().required(),
  icartPurchaseAmount: Yup.number().required(),
});

export const citySchema = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.string().required(),
  country: Yup.string().required(),
});

export const locationSchema = Yup.object().shape({
  label: Yup.string().required(),
  lat: Yup.number().required(),
  lng: Yup.number().required(),
  city: Yup.string().required(),
  type: Yup.string().required(),
});

export const outletSchema = Yup.object().shape({
  name: Yup.string().required(),
  location: Yup.string().required(),
  type: Yup.string().required(),
});
