import { useEffect, useState } from "react";
import NotFound from "../../../components/NotFound";
import useQuery from "../../../hooks/useQuery";
import useAccounts from "../../../hooks/api/useAccounts";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import Image from "../../../components/Image";
import Status from "../../../components/Status";
import { accountStatuses, baseStatuses } from "../../../utils/statuses";
import StarsReview from "../../../components/StarsReview";
import Button from "../../../components/Button";
import MessagesIcon from "../../../assets/icons/MessagesIcon";
import Tab from "../../../components/Tab";
import { formatDateTime } from "../../../utils/date";
import AccountBasicDetails from "../../../components/account/AccountBasicDetails";
import AccountWallet from "../../../components/account/AccountWallet";
import AccountKYC from "../../../components/account/AccountKYC";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/Modal";
import { Form, FormInput, Submit } from "../../../components/form";

const tabs = [
  {
    title: "Account Details",
    Element: AccountBasicDetails,
  },
  {
    title: "Account Wallet",
    Element: AccountWallet,
  },
  {
    title: "Account KYC",
    Element: AccountKYC,
  },
];

function AccountDetails(props) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const { getAccount, updateUser, loading, account } = useAccounts();
  const { openModal, closeModal, isOpened } = useModal();
  const { Element } = currentTab;
  const q = useQuery();
  const id = q.get("id");
  const { sendNotification, loading: notificationLoading } = useAccounts();

  useEffect(() => {
    if (id) getAccount(id);
  }, []);

  if (!id) return <NotFound />;
  if (loading)
    return (
      <div className="full-loader d-flex align-center justify-center">
        <Loader loading />
      </div>
    );
  if (account)
    return (
      <div>
        <Modal isVisible={isOpened}>
          <h2 className="fs-24 raleway f-700 text-primary">
            Send Push Notification
          </h2>
          <br />
          <Form
            onSubmit={(values) => {
              sendNotification({
                ...values,
                to: account.devicePushToken,
                sound: "alarm.wav",
                channel: "new_orders",
                trigger: {
                  seconds: 10,
                },
                data: {
                  order: {
                    customerInformation: {
                      name: "Ismail Dalhatu",
                      email: "ismaildalhatu442@gmail.com",
                      phoneNumber: "09022314973",
                    },
                    deliveryTo: {
                      address: "Abuja",
                      lat: 9.00115461270783,
                      lng: 7.463951300201953,
                    },
                    _id: "67499f9ccca8f46fa64ff9fa",
                    items: [
                      {
                        _id: "67499f9ccca8f46fa64ff9fc",
                        order: "67499f9ccca8f46fa64ff9fa",
                        kioskOrder: "671d3035b66427422463f0a8",
                        item: {
                          _id: "66f438dbafd845fa43fcba1b",
                          name: "Chicken Stir Fried Rice",
                          description: "Chicken Stir Fried Rice",
                        },
                        quantity: 1,
                        priceStamp: 3366.85,
                        createdAt: "2024-11-29T11:03:56.534Z",
                        updatedAt: "2024-11-29T11:03:56.534Z",
                        __v: 0,
                      },
                    ],
                    status: "pending",
                    transaction: null,
                    generatedTransactions: [
                      {
                        currencyConversion: {
                          from: "NGN",
                          to: "NGN",
                          rate: 1,
                        },
                        _id: "67499f9ccca8f46fa64ffa01",
                        user: null,
                        wallet: null,
                        amount: 3366.85,
                        netAmount: 3366.85,
                        title: "Order Payemnt",
                        description: "Payment for online orders",
                        fee: 0,
                        vat: 0,
                        type: "credit",
                        amountPaid: 0,
                        reference: "unhwf8uixf",
                        payMethod: "gatway",
                        accessCode: "8jtldg47stjot3d",
                        status: "pending",
                        paidAt: null,
                        charge: 0,
                        gateway: "paystack",
                        action: "validateOrder",
                        order: "67499f9ccca8f46fa64ff9fa",
                        subscription: null,
                        invoice: null,
                        createdAt: "2024-11-29T11:03:56.827Z",
                        updatedAt: "2024-11-29T11:03:58.042Z",
                        __v: 0,
                      },
                    ],
                    totalPrice: 3366.85,
                    vat: 0,
                    totalDeliveryFee: 0,
                    id: "60PHHY",
                    checkoutId: "K1LGJ1_1732878235405",
                    type: "pickup",
                    paymentMethod: "online",
                    paymentCompleted: false,
                    kioskOrders: [
                      {
                        _id: "67499f9ccca8f46fa64ff9fb",
                        kiosk: {
                          _id: "671d3035b66427422463f0a8",
                          name: "iCRT10003NGABJ",
                          id: "671d3035b66427422463f0a8",
                        },
                        order: "67499f9ccca8f46fa64ff9fa",
                        items: ["67499f9ccca8f46fa64ff9fc"],
                        deliveryFee: 0,
                        acceptedBy: null,
                        completedBy: null,
                        acceptedAt: null,
                        completedAt: null,
                        processingTime: 10,
                        waitingTime: 0,
                        dispatchedAt: null,
                        status: "pending",
                        createdAt: "2024-11-29T11:03:56.624Z",
                        updatedAt: "2024-11-29T11:03:56.624Z",
                        __v: 0,
                      },
                    ],
                    rider: null,
                    estimatedQueueTime: "2024-11-29T11:03:56.531Z",
                    estimatedDeliveryTime: "2024-11-29T17:37:56.531Z",
                    estimatedProcessingTime: "2024-11-29T11:13:56.531Z",
                    deliveryTime: 384.47,
                    acceptedAt: null,
                    completedAt: null,
                    dispatchedAt: null,
                    arrivedAt: null,
                    deliveredAt: null,
                    createdAt: "2024-11-29T11:03:56.692Z",
                    updatedAt: "2024-11-29T11:03:58.110Z",
                    __v: 1,
                  },
                },
              });
            }}
            initialValues={{ title: "", body: "" }}
          >
            <FormInput name="title" placeholder="Message Title" />
            <br />
            <FormInput name="body" textarea placeholder="Message Body" />
            <br />
            <div className="d-flex gap-10">
              <Button onClick={closeModal} width={100} title="Cancel" outline />
              <Submit loading={notificationLoading} title="Continue" />
            </div>
          </Form>
        </Modal>
        <div className="d-flex v-user-info">
          <div className="info-1">
            <Image className="profile-img" src={account.profileImage} />
            <br />
            <br />
            <span className="fs-11 text-grey d-block f-400">
              Date Registered
            </span>
            <h3 className="text-brown">
              {formatDateTime(account.createdAt).dateTime}
            </h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">Last Updated</span>
            <h3 className="text-brown">
              {formatDateTime(account.updatedAt).dateTime}
            </h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">
              Account Status
            </span>
            <h3 className="text-brown b-text">{account.status}</h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">Push Token</span>
            <h3 className="text-brown b-text">
              -{/* {account.devicePushToken || "-"} */}
            </h3>
          </div>
          <div className="info-2 full-flex">
            <h2 className="text-brown fs-32">
              {account.firstName} {account.lastName}
            </h2>
            <Status
              light
              text={account.role}
              className={baseStatuses[account.role]}
            />
            <br />
            <br />
            <span className="fs-14 d-block text-brown f-700">Rating</span>
            <br />
            <div className="d-flex justify-between align-center">
              <div className="d-flex gap-10 align-center">
                <h2 className="text-brown">4.5</h2>
                <StarsReview showNumber={false} defaultRating={4} />
              </div>
              <div>
                <span className="fs-14 d-block text-brown f-700">
                  Change Account Status
                </span>
                <div
                  style={{ marginTop: 5 }}
                  className="d-flex gap-10 align-center"
                >
                  {Object.keys(accountStatuses).map((_) => (
                    <Status
                      onClick={() => updateUser(account._id, { status: _ })}
                      style={{
                        borderWidth: 1,
                        borderRadius: 0,
                        borderBottomWidth: 1,
                      }}
                      text={_}
                      className={accountStatuses[_]}
                      light={account.status !== _}
                    />
                  ))}
                </div>
              </div>
            </div>
            <br />
            <div>
              <Button
                disabled={!account.devicePushToken}
                onClick={openModal}
                outline
                width={200}
                lSvg={<MessagesIcon color="var(--main-yellow)" />}
                title="Send notification"
              />
            </div>
            <br />
            <Tab
              autoSelect
              className="tabs-noborder"
              tabs={tabs}
              onSwitch={setCurrentTab}
            />
            <br />
            <br />
            {<Element account={account} />}
          </div>
        </div>
      </div>
    );
}

export default AccountDetails;
