import NotificationIcon from "../assets/icons/NotificationIcon";
import BackArrowIcon from "../assets/icons/BackArrowIcon";
import "../css/components/header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import LocationIcon from "../assets/icons/LocationIcon";
import { capitalizeFirstLetter } from "../utils/string";
import Image from "./Image";
import AppContext from "../contexts/AppContext";
import { path } from "../utils/path";
import useModal from "../hooks/useModal";

function Header(props) {
  const [title, setTitle] = useState(null);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const { cities, currentCity, setCurrentCity } = useContext(AppContext);
  const { isOpened, toggleIsOpened } = useModal();

  const navigateProfile = () => navigate(path.accounts.users.me.url);

  useEffect(() => {
    let p = pathname
      .split("/")
      .slice(1, 3)
      .join(" -> ")
      .replace("icart", " iCart");
    while (p.includes("-")) p = p.replace("-", " ");
    setTitle(p);
  }, [pathname]);
  return (
    <header className="d-flex align-center justify-between">
      <button onClick={goBack} className="d-flex navigate-back align-center">
        {<BackArrowIcon color="var(--main-yellow)" />}{" "}
        <h2 className="f-600 fs-16 raleway">
          {capitalizeFirstLetter(title) || "Dashboard"}
        </h2>
      </button>
      {isOpened ? (
        <div className="country-options">
          {cities.map((_, idx) => (
            <button
              onClick={() => {
                setCurrentCity(_);
                toggleIsOpened();
              }}
              className={`d-flex align-center ${
                currentCity?._id === _._id ? "active-country" : ""
              }`}
              key={idx}
            >
              <div className="city-flag">
                <img
                  src={`https://countries-pudh.onrender.com/flags/64x64/${_.country.code.toLowerCase()}.png`}
                  alt={_.name}
                />
              </div>
              <span>{_.name}</span>
            </button>
          ))}
        </div>
      ) : null}

      <div
        style={{ marginLeft: "auto", marginRight: 10 }}
        className="card user-info d-flex align-center"
      >
        <div className="icon-notification d-flex align-center justify-center">
          <NotificationIcon />
        </div>
        <div
          onClick={navigateProfile}
          className="user-details d-flex flex-column align-end raleway"
        >
          <h2 className="text-right d-block fs-14 f-400">
            {user.firstName} {user.lastName}
          </h2>
          <span className="text-right d-block fs-10 f-500 text-primary">
            {user.staff?.position || user.role}
          </span>
        </div>
        <div className="img d-flex justify-center round align-center">
          <Image style={{ borderRadius: "50%" }} src={user.profileImage} />
        </div>
      </div>
      <div className="d-flex align-center">
        <button onClick={toggleIsOpened} className="city-flag">
          <img
            src={`https://countries-pudh.onrender.com/flags/64x64/${currentCity?.country?.code?.toLowerCase()}.png`}
            alt={currentCity?.country?.name}
          />
        </button>
        <span className="fs-12 text-brown">{currentCity?.name}</span>
      </div>
      {/* {user.staff ? (
        <div className="d-flex show-tab align-center justify-center">
          <LocationIcon color="var(--main-yellow)" />
          <select
            onChange={(e) =>
              setCurrentCity(cities.find((_) => _._id === e.target.value))
            }
            value={currentCity?._id}
            className="f600 plain-select"
          >
            <option>Global</option>
            {cities.map((_) => (
              <option key={_._id} value={_._id}>
                {_.name}
              </option>
            ))}
          </select>
        </div>
      ) : null} */}
    </header>
  );
}

export default Header;
