import { Route, Routes } from "react-router-dom";
import Home from "../pages/app/settings/Home";
import { path } from "../utils/path";

function Settings(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Home />} />
    </Routes>
  );
}

export default Settings;
