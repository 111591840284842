import { Link } from "react-router-dom";
import Hammer from "../../../assets/icons/Hammer";
import Inbox from "../../../assets/icons/Inbox";
import SearchIcon from "../../../assets/icons/SearchIcon";
import Video from "../../../assets/icons/Video";
import Warn from "../../../assets/icons/Warn";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import { path } from "../../../utils/path";

const options = [
  {
    title: "Inbox",
    text: "View all open chats relating to your complains",
    svg: <Inbox />,
  },
  {
    title: "Watch Tutorials",
    text: "View iCart menus and watch tutorials on meal preparations",
    svg: <Video />,
    to: path.support.videos.url,
  },
  {
    title: "Make New Complains",
    text: "Report for maintenance or problems on the iCart",
    svg: <Warn />,
    to: path.support.complaints.url,
  },
  {
    title: "Rules and Regulations",
    text: "View Guidelines, Rules, and Regulations on the iCart",
    svg: <Hammer />,
    to: path.support.compliance.url,
  },
];

function Dashboard(props) {
  return (
    <div>
      <div className="d-flex flex-wrap support-header justify-between align-center">
        <div className="text-info">
          <h2 className="fs-24 raleway f-700 text-primary">
            Welcome to iCart Support
          </h2>
          <br />
          <p className="fs-14 text-blue f-400">
            Make complains, chat with support, watch tutorials and see more
            information about the iCart here.
          </p>
          <br />
        </div>
        <div className="d-flex support-search">
          <div style={{ flex: 1 }}>
            <Input
              borderColor="#7B4A0E"
              placeholder="Search"
              rIcon={
                <Icon>
                  <SearchIcon />
                </Icon>
              }
            />
          </div>
          <Button title="See FAQs" width={113} />
        </div>
      </div>
      <br />
      <div className="d-flex flex-wrap suppor-cards">
        {options.map(({ title, svg, text, to }, idx) => (
          <Link
            to={to}
            className="d-flex flex-column justify-center align-center support-card"
            key={idx}
          >
            {svg}
            <h2 className="fs-16 raleway f-600">{title}</h2>
            <p className="fs-14 f-400">{text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
