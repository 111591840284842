import { useEffect } from "react";
import useAccounts from "../../hooks/api/useAccounts";
import Button from "../Button";
import Image from "../Image";
import Loader from "../Loader";

export const UserProfile = ({
  onSelect,
  firstName,
  lastName,
  profileImage,
  email,
  selected,
  _id,
  onRemove,
  ...props
}) => (
  <div className="search-profile d-flex align-center">
    <Image src={profileImage} />
    <div className="full-flex">
      <h3 className="fs-14 f-700 text-brown">
        {firstName} {lastName}
      </h3>
      <span className="fs-10 f-400 text-brown">{email}</span>
    </div>
    <div>
      {selected === _id ? (
        <Button
          onClick={onRemove}
          style={{ marginBottom: 4 }}
          title="Unselect"
          width={70}
          height={25}
          fontSize="fs-10"
          className="bg-danger"
        />
      ) : (
        <Button
          onClick={() => {
            if (typeof onSelect === "function")
              onSelect({
                firstName,
                _id,
                lastName,
                profileImage,
                email,
                ...props,
              });
          }}
          style={{ marginBottom: 4 }}
          title="Select"
          width={70}
          height={25}
          fontSize="fs-10"
          className="bg-brown"
        />
      )}
      <Button
        textClassName="text-brown"
        borderColor="var(--yellow-tints-5)"
        title="Profile"
        width={70}
        outline
        height={25}
        fontSize="fs-10"
      />
    </div>
  </div>
);

function SearchUser({ search, onSelect }) {
  const { allAccounts, searchAccoount, loading } = useAccounts();

  useEffect(() => {
    searchAccoount(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="search-rusults">
      <Loader loading={loading} />
      {allAccounts.map((_) => (
        <UserProfile {..._} onSelect={onSelect} key={_} />
      ))}
    </div>
  );
}

export default SearchUser;
