import { Form, FormInput, Submit } from "../../../components/form";
import useSettings from "../../../hooks/api/useSettings";
import CheckBox from "../../../components/CheckBox";
import Loader from "../../../components/Loader";
import { useEffect, useState } from "react";

function Home(props) {
  const { settings, getSettings, loading: getLoading } = useSettings();
  const [onlineOrder, setOnlineOrder] = useState(false);
  const { updateSettings, loading } = useSettings();

  useEffect(() => {
    getSettings();
  }, []);

  if (getLoading)
    return (
      <div className="page d-flex align-center justify-center">
        <Loader loading />
      </div>
    );

  return (
    <div>
      <h2 className="text-brown">System Settings</h2>
      <br />
      <Form
        onSubmit={(values) => updateSettings({ ...values, onlineOrder })}
        initialValues={{
          deliveryPerKm: settings.deliveryPerKm,
          orderRadius: settings.orderRadius,
          maxWaitingTime: settings.maxWaitingTime,
          locationRenewalPeriod: settings.locationRenewalPeriod,
          conceptRenewalPeriod: settings.conceptRenewalPeriod,
        }}
      >
        <br />
        <span className="fs-14 d-block text-brown f-700">
          Delivery Settings
        </span>
        <br />
        <div className="inputs">
          <FormInput placeholder="Delivery cost per km" name="deliveryPerKm" />
          <FormInput placeholder="Order Radius per kiosk" name="orderRadius" />
        </div>
        <br />
        <div className="d-flex align-center">
          <span style={{ marginRight: 10 }}>Orders Open: </span>
          <CheckBox value={settings.onlineOrders} onChange={setOnlineOrder} />
        </div>
        <br />
        <span className="fs-14 d-block text-brown f-700">Concept Settings</span>
        <br />
        <div className="inputs">
          <FormInput
            placeholder="Location Renewal"
            name="locationRenewalPeriod"
          />
          <FormInput
            placeholder="Concept Renewal"
            name="conceptRenewalPeriod"
          />
        </div>
        <br />
        <Submit title="Save" width={150} loading={loading} />
      </Form>
    </div>
  );
}

export default Home;

const a = {
  createdAt: "2024-10-06T12:24:26.076Z",
  updatedAt: "2024-11-28T13:02:50.419Z",
};
