import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import { toast } from "react-toastify";

function useRoles(props) {
  const [permissions, setPermissions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [roles, setRoles] = useState([]);
  const { loading, post, get, put } = useAPI();

  const getRoles = async () => {
    const { data, error } = await get(urls.auth.roles.baseUrl);
    if (error) return;
    setTotalCount(data.pagination.total);
    setRoles(data.roles);
  };

  const getPermissions = async () => {
    const { data, error } = await get(urls.auth.roles.permissions);
    if (error) return;
    setPermissions(data.permissions);
  };

  const createRole = async (fdata, cb) => {
    const { error, message } = await post(urls.auth.roles.baseUrl, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const updateRole = async (id, fdata, cb) => {
    const { error, message } = await put(
      urls.auth.roles.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    roles,
    loading,
    getRoles,
    totalCount,
    createRole,
    updateRole,
    permissions,
    getPermissions,
  };
}
export default useRoles;
