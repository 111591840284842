import { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import useSuppliers from "../../../hooks/api/useSuppliers";
import usePagination from "../../../hooks/usePagination";
import WithLink from "../../../components/table/WithLink";
import { formatDateTime } from "../../../utils/date";
import WithImage from "../../../components/table/WithImage";
import Status from "../../../components/Status";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Store Name",
    target: "storeName",
  },
  {
    title: "City",
    target: "city.name",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Active",
    target: "active",
    render: (v) => (
      <Status text={v?.toString()} light className={v ? "success" : "danger"} />
    ),
  },
  {
    title: "Registered By",
    target: "account",
    render: (values) => (
      <WithImage
        text={`${values.firstName} ${values.lastName}`}
        imageUrl={values.profileImage}
      />
    ),
  },
  {
    title: "Date Registered",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function Suppliers(props) {
  const { getSuppliers, suppliers, loading, total, updateStoreStatus } =
    useSuppliers();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const refresh = () => {
    getSuppliers(pageNumber, limit);
    setSelectedSupplier(null);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <div>
      <Modal
        closeModal={() => setSelectedSupplier(null)}
        isVisible={selectedSupplier}
      >
        <h2 className="fs-24 raleway f-700 text-primary">
          {selectedSupplier?.active ? "Deactivate" : "Activate"} Supplier
        </h2>
        <br />
        <p>
          {selectedSupplier?.active ? "Deactivate" : "Activate"}{" "}
          <strong>{selectedSupplier?.storeName}</strong> in{" "}
          {selectedSupplier?.city?.name}
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            outline
            width={120}
            title="Cancel"
            onClick={() => setSelectedSupplier(null)}
          />
          <Button
            loading={loading}
            onClick={() => {
              updateStoreStatus(selectedSupplier?._id, refresh);
            }}
            title="Continue"
          />
        </div>
      </Modal>
      <Table
        limit={limit}
        totalCount={total}
        head={[
          ...tableHead,
          {
            title: "Action",
            target: "*",
            render: (v) => (
              <Button
                onClick={() => setSelectedSupplier(v)}
                width={150}
                height={40}
                fontSize="fs-12"
                className={!v.active ? "bg-info" : "bg-danger"}
                title={`${!v.active ? "Activate" : "Deactivate"} Supplier`}
              />
            ),
          },
        ]}
        data={suppliers}
        onPaginate={setPageNumber}
        loading={loading}
        title="Suppliers"
      />
    </div>
  );
}

export default Suppliers;
