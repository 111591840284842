export const otpOptions = {
  sms: "sms",
  email: "email",
};

export const requestTypes = {
  prep: "prep",
  material: "material",
  supply: "supply",
};

export const outletTypes = {
  office: "office",
  prepRoom: "prep-room",
  store: "store",
  kiosk: "kiosk",
};

export const units = [
  {
    name: "Grams",
    value: "g",
    conversions: [
      {
        name: "Kilograms",
        value: "kg",
        ratio: 1000,
      },
      {
        name: "25kg Bag",
        value: "25kgb",
        ratio: 25000,
      },
      {
        name: "50kg Bag",
        value: "50kgb",
        ratio: 50000,
      },
    ],
  },
  {
    name: "Milliliter",
    value: "ml",
    conversions: [
      {
        name: "Liter",
        value: "l",
        ratio: 1000,
      },
      {
        name: "12 Liter Galon",
        value: "12lg",
        ratio: 12000,
      },
      {
        name: "25 Liter Galon",
        value: "25lg",
        ratio: 25000,
      },
    ],
  },
  {
    name: "Unit",
    value: "unit",
    conversions: [],
  },
];
