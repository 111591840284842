import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useDepartments(props) {
  const [departments, setDepartments] = useState([]);
  const { loading, post, get, put } = useAPI();

  const getAllDepartments = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.departments.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setDepartments(data.departments);
  };

  const createDepartment = async (fdata, cb) => {
    const { error, message } = await post(urls.departments.baseUrl, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const updateDepartment = async (id, fdata, cb) => {
    const { error, message } = await put(
      urls.departments.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    departments,
    createDepartment,
    updateDepartment,
    getAllDepartments,
  };
}
export default useDepartments;
