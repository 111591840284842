import InfoText from "../../../components/TextInfo";
import Status from "../../../components/Status";
import statuses from "../../../utils/statuses";
import Image from "../../../components/Image";
import { formatNumber } from "../../../utils/numbers";
import WithImage from "../../../components/table/WithImage";
import Button from "../../../components/Button";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import useInventory from "../../../hooks/api/useInventory";
import Modal from "../../../components/Modal";
import useModal from "../../../hooks/useModal";
import Input from "../../../components/Input";
import useQuery from "../../../hooks/useQuery";
import Loader from "../../../components/Loader";
import NotFound from "../../../components/NotFound";
import { path } from "../../../utils/path";

function ViewMaterial(props) {
  const [price, setPrice] = useState("");
  const { loading: getLoading, getMaterialDetals, data } = useInventory();
  const { currentCity, formatCurrency } = useContext(AppContext);
  const status = data?.approved ? statuses.active : statuses.danger;
  const { getMaterialPrices, updateMaterialPrice, materialPrices, loading } =
    useInventory();
  const { closeModal, openModal, isOpened } = useModal();
  const query = useQuery();
  const getPrice = () => getMaterialPrices(data._id, currentCity?._id);

  const handleUpdatePrice = () =>
    updateMaterialPrice(
      data._id,
      {
        cost: price,
        region: currentCity._id,
        id: materialPrices.length ? materialPrices[0]._id : null,
      },
      () => {
        getPrice();
        closeModal();
      }
    );

  useEffect(() => {
    if (currentCity && data) getPrice();

    // eslint-disable-next-line
  }, [currentCity]);

  useEffect(() => {
    if (query.get("id")) getMaterialDetals(query.get("id"));
    // eslint-disable-next-line
  }, []);

  if (getLoading)
    return (
      <div
        style={{ height: "100%" }}
        className="d-flex align-center justify-center"
      >
        <Loader loading />
      </div>
    );
  if (!data) return <NotFound />;

  return (
    <div>
      <Modal isVisible={isOpened} closeModal={closeModal}>
        <h2 className="fs-24 raleway f-700 text-primary">Update Price</h2>
        <br />
        <Input value={price} onChange={setPrice} placeholder="Price" />
        <br />
        <Button
          loading={loading}
          disabled={!price}
          onClick={handleUpdatePrice}
          title="Save"
          width={100}
        />
      </Modal>
      <h2 className="text-primary fs-24">View Item</h2>
      <br />
      <div className="d-flex justify-center flex-wrap material-info">
        <div className="details full-flex">
          <div className="d-flex justify-between">
            <InfoText title="Name:" text={data.name} />
            <InfoText title="Category:" text={data.category.name} />
          </div>
          <br />
          <div className="d-flex justify-between">
            <InfoText
              title="Inventory:"
              text={`${data.quantity || "-"} ${data.unitOfMeasure}`}
            />
            <InfoText title="Status:">
              <Status
                className={`${status}-text ${status}-light`}
                text={data.approved ? "approved" : "not approved"}
              />
            </InfoText>
          </div>
          <br />
          <div className="d-flex justify-between">
            <InfoText title="Description:" text={data.description} />
            <InfoText title="Life Span:" text={`${data.lifeSpanInDays} days`} />
          </div>
        </div>
        <div className="item-img full-flex">
          <Image src={data.imageUrl} />
        </div>
      </div>
      <br />
      <div className="d-flex flex-wrap justify-between fields-list">
        <InfoText
          className="full-flex"
          title="Power Consumption:"
          text={`${data.electricInfo?.powerConsumption?.voltage}V - ${
            data.electricInfo?.powerConsumption?.current
          } amp (${formatNumber(
            (
              data.electricInfo?.powerConsumption?.voltage *
              data.electricInfo?.powerConsumption?.current
            ).toFixed(0),
            ""
          )} Watts)`}
        />
        <InfoText
          className="full-flex"
          title="Dimention:"
          text={`${data.dimentionInft.width}ft x ${data.dimentionInft.height}ft`}
        />
        <InfoText
          className="full-flex"
          title="Measure:"
          text={data.unitOfMeasure}
        />
        <InfoText
          className="full-flex"
          title="Price:"
          loading={loading}
          text={formatCurrency(materialPrices[0]?.cost?.toFixed(2) || 0)}
        />
        <InfoText className="full-flex" title="Model:" text={data.model} />
      </div>
      <br />
      <div className="d-flex flex-wrap justify-between fields-list">
        <InfoText className="full-flex" title="Tags">
          <div className="d-flex flex-wrap gap-10 align-center">
            {data.tags.map((_, idx) => (
              <Status
                style={{ marginTop: !idx ? 5 : 0 }}
                text={_}
                className="status-tint5"
                key={idx}
              />
            ))}
          </div>
        </InfoText>
        <InfoText className="full-flex" title="Ingredients/Mixture">
          <div className="d-flex flex-wrap gap-10 align-center">
            {data.mixture.map((_, idx) => (
              <WithImage
                key={idx}
                imageUrl={_.item.imageUrl}
                text={`${_.item.name} (${_.quantity} ${_.item.unitOfMeasure})`}
              />
            ))}
          </div>
        </InfoText>
      </div>
      <br />
      <div
        style={{ maxWidth: 400, marginLeft: "auto" }}
        className="d-flex gap-10"
      >
        <Button
          link={path.materials.edit.url}
          state={data}
          style={{ borderRadius: 4 }}
          outline
          title="Edit Details"
        />
        {/* <Button
          style={{ borderRadius: 4 }}
          className={`bg-${state.approved ? "danger" : "info"}`}
          title={state.approved ? "Unapprove Item" : "Approve Item"}
        /> */}
        <Button
          disabled={!currentCity}
          style={{ borderRadius: 4 }}
          onClick={openModal}
          title="Change Price"
        />
      </div>
    </div>
  );
}

export default ViewMaterial;
