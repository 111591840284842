import Dashboard from "../pages/app/sales/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import "../css/pages/sales.css";

function Sales(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
    </Routes>
  );
}

export default Sales;
