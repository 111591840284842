import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useStaffs(props) {
  const [allStaffs, setallStaffs] = useState([]);
  const { loading, post, get, patch } = useAPI();

  const getAllStaffs = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.staffs.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setallStaffs(data.staffs);
  };

  const createStaff = async (fdata, cb) => {
    const { error, message } = await post(urls.staffs.baseUrl, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };
  const updateStaff = async (id, fdata, cb) => {
    const { error, message } = await patch(
      urls.staffs.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    allStaffs,
    createStaff,
    updateStaff,
    getAllStaffs,
  };
}
export default useStaffs;
