import { useEffect, useState } from "react";
import Modal from "../Modal";
import Input from "../Input";
import SearchUser, { UserProfile } from "./SearchUser";
import useDepartments from "../../hooks/api/useDepartments";
import useRoles from "../../hooks/api/useRoles";
import { Form, FormInput, Submit } from "../form";
import { staffSchema } from "../../utils/validators/accounts";
import CheckBox from "../CheckBox";
import useOutlets from "../../hooks/api/useOutlets";
import Button from "../Button";

function StaffProfile({
  isOpened,
  selectedStaff,
  loading,
  handleSubmit,
  closeModal,
}) {
  const [isActive, setIsActive] = useState(!!selectedStaff?.active);
  const [selectedUser, setSelectedUser] = useState(selectedStaff);
  const [search, setSearch] = useState("");
  const {
    loading: dloading,
    departments,
    getAllDepartments,
  } = useDepartments();
  const { roles, getRoles, loading: rloading } = useRoles();
  const { outlets, loading: oloading, getOutlets } = useOutlets();

  const toggleActive = () => setIsActive(!isActive);

  useEffect(() => {
    getRoles();
    getAllDepartments();
    getOutlets(1, 100);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedStaff) {
      setSelectedUser(selectedStaff.account);
      setIsActive(!!selectedStaff?.active);
    }
  }, [selectedStaff]);

  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selectedStaff ? "Update Staff" : "Register New Staff"}
      </h2>
      <br />
      <Input value={search} onChange={setSearch} placeholder="Search user" />
      <br />
      <SearchUser
        onSelect={(v) => {
          setSelectedUser(v);
          setSearch("");
        }}
        search={search}
      />
      <br />
      {selectedUser && (
        <UserProfile
          onRemove={() => setSelectedUser(null)}
          selected={selectedUser?._id}
          {...selectedUser}
        />
      )}
      <br />
      <Form
        onSubmit={(v) =>
          handleSubmit({
            ...v,
            role: v.role || null,
            account: selectedUser?._id,
          })
        }
        validationSchema={staffSchema}
        initialValues={{
          department: selectedStaff?.department?._id || "",
          position: selectedStaff?.position || "",
          outlet: selectedStaff?.outlet?._id || "",
          status: selectedStaff?.status || "",
          role: selectedStaff?.role || "",
        }}
      >
        <div className="inputs">
          <FormInput
            name="department"
            placeholder="Department"
            select
            options={departments.map((_) => ({ label: _.name, value: _._id }))}
          />
          <FormInput name="position" placeholder="Position" />
        </div>
        <br />
        <div className="inputs">
          <FormInput
            name="outlet"
            select
            placeholder="Workplace"
            options={outlets.map((o) => ({ label: o.name, value: o._id }))}
          />
          <FormInput
            name="role"
            select
            placeholder="Role"
            options={roles.map((_) => ({ label: _.title, value: _._id }))}
          />
        </div>

        <br />
        <FormInput
          name="status"
          select
          placeholder="Status"
          options={[
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Suspended",
              value: "suspended",
            },
            {
              label: "Fired",
              value: "fired",
            },
          ]}
        />
        {/* <div className="d-flex gap-10">
              <span className="d-flex f-700 fs-14 text-brown">Active</span>
              <CheckBox value={isActive} onChange={toggleActive} />
            </div> */}
        <br />
        <div className="d-flex gap-10">
          <Button onClick={closeModal} title="Cancel" outline width={100} />
          <Submit
            disabled={!selectedUser}
            loading={dloading || oloading || rloading || loading}
            onClick={closeModal}
            title="Save"
          />
        </div>
      </Form>
    </Modal>
  );
}

export default StaffProfile;
