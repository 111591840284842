import { useContext, useState } from "react";
import FilePicker from "../../../components/FilePicker";
import AuthContext from "../../../contexts/AuthContext";
import { Form, FormInput, Submit } from "../../../components/form";
import useProfile from "../../../hooks/api/useProfile";
import { selectFromObject } from "../../../utils/array";

function Profile(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { user } = useContext(AuthContext);
  const { updateMyInfo, loading, changeImage } = useProfile();
  return (
    <div>
      <FilePicker onSelect={setSelectedImage} initial={user.profileImage} />
      <br />
      <Form
        onSubmit={(v) => {
          if (selectedImage) changeImage(selectedImage);
          updateMyInfo(v);
        }}
        initialValues={selectFromObject(
          ["firstName", "lastName", "email", "phoneNumber"],
          user
        )}
      >
        <div className="inputs">
          <FormInput name="firstName" placeholder="First Name" />
          <FormInput name="lastName" placeholder="Last Name" />
        </div>
        <br />
        <div className="inputs">
          <FormInput name="email" placeholder="Email" />
          <FormInput name="phoneNumber" placeholder="Phone Number" />
        </div>
        <br />
        <Submit loading={loading} width={150} title="Save" />
      </Form>
    </div>
  );
}

export default Profile;
